.pf-c-label {
  --pf-c-label--PaddingTop: var(--pf-global--spacer--xs);
  --pf-c-label--PaddingRight: var(--pf-global--spacer--sm);
  --pf-c-label--PaddingBottom: var(--pf-global--spacer--xs);
  --pf-c-label--PaddingLeft: var(--pf-global--spacer--sm);
  --pf-c-label--BorderRadius: var(--pf-global--BorderRadius--lg);
  --pf-c-label--BackgroundColor: var(--pf-global--palette--black-150);
  --pf-c-label--Color: var(--pf-global--Color--100);
  --pf-c-label--FontSize: var(--pf-global--FontSize--sm);
  --pf-c-label__content--before--BorderWidth: var(--pf-global--BorderWidth--sm);
  --pf-c-label__content--before--BorderColor: var(--pf-global--BorderColor--100);
  --pf-c-label--m-outline--BackgroundColor: var(--pf-global--BackgroundColor--100);
  --pf-c-label--m-outline__content--before--BorderWidth: var(--pf-global--BorderWidth--sm);
  --pf-c-label--m-outline__content--before--BorderColor: var(--pf-global--BorderColor--100);
  --pf-c-label__content--link--hover--before--BorderWidth: var(--pf-global--BorderWidth--md);
  --pf-c-label__content--link--focus--before--BorderWidth: var(--pf-global--BorderWidth--md);
  --pf-c-label__content--link--hover--before--BorderColor: var(--pf-global--BorderColor--200);
  --pf-c-label__content--link--focus--before--BorderColor: var(--pf-global--BorderColor--200);
  --pf-c-label--m-outline__content--link--hover--before--BorderWidth: var(--pf-global--BorderWidth--md);
  --pf-c-label--m-outline__content--link--focus--before--BorderWidth: var(--pf-global--BorderWidth--md);
  --pf-c-label--m-outline__content--link--hover--before--BorderColor: var(--pf-global--BorderColor--100);
  --pf-c-label--m-outline__content--link--focus--before--BorderColor: var(--pf-global--BorderColor--100);
  --pf-c-label--m-blue--BackgroundColor: var(--pf-global--palette--blue-50);
  --pf-c-label--m-blue__icon--Color: var(--pf-global--primary-color--100);
  --pf-c-label--m-blue__content--Color: var(--pf-global--info-color--200);
  --pf-c-label--m-blue__content--before--BorderColor: var(--pf-global--palette--blue-100);
  --pf-c-label--m-blue__content--link--hover--before--BorderColor: var(--pf-global--primary-color--100);
  --pf-c-label--m-blue__content--link--focus--before--BorderColor: var(--pf-global--primary-color--100);
  --pf-c-label--m-outline--m-blue__content--Color: var(--pf-global--primary-color--100);
  --pf-c-label--m-outline--m-blue__content--before--BorderColor: var(--pf-global--BorderColor--100);
  --pf-c-label--m-outline--m-blue__content--link--hover--before--BorderColor: var(--pf-global--BorderColor--100);
  --pf-c-label--m-outline--m-blue__content--link--focus--before--BorderColor: var(--pf-global--BorderColor--100);
  --pf-c-label--m-green--BackgroundColor: var(--pf-global--palette--green-50);
  --pf-c-label--m-green__icon--Color: var(--pf-global--success-color--100);
  --pf-c-label--m-green__content--Color: var(--pf-global--success-color--200);
  --pf-c-label--m-green__content--before--BorderColor: var(--pf-global--palette--green-100);
  --pf-c-label--m-green__content--link--hover--before--BorderColor: var(--pf-global--success-color--100);
  --pf-c-label--m-green__content--link--focus--before--BorderColor: var(--pf-global--success-color--100);
  --pf-c-label--m-outline--m-green__content--Color: var(--pf-global--success-color--100);
  --pf-c-label--m-outline--m-green__content--before--BorderColor: var(--pf-global--BorderColor--100);
  --pf-c-label--m-outline--m-green__content--link--hover--before--BorderColor: var(--pf-global--BorderColor--100);
  --pf-c-label--m-outline--m-green__content--link--focus--before--BorderColor: var(--pf-global--BorderColor--100);
  --pf-c-label--m-orange--BackgroundColor: var(--pf-global--palette--orange-50);
  --pf-c-label--m-orange__icon--Color: var(--pf-global--palette--orange-300);
  --pf-c-label--m-orange__content--Color: var(--pf-global--palette--orange-700);
  --pf-c-label--m-orange__content--before--BorderColor: var(--pf-global--palette--orange-100);
  --pf-c-label--m-orange__content--link--hover--before--BorderColor: var(--pf-global--palette--orange-300);
  --pf-c-label--m-orange__content--link--focus--before--BorderColor: var(--pf-global--palette--orange-300);
  --pf-c-label--m-outline--m-orange__content--Color: var(--pf-global--palette--orange-500);
  --pf-c-label--m-outline--m-orange__content--before--BorderColor: var(--pf-global--BorderColor--100);
  --pf-c-label--m-outline--m-orange__content--link--hover--before--BorderColor: var(--pf-global--BorderColor--100);
  --pf-c-label--m-outline--m-orange__content--link--focus--before--BorderColor: var(--pf-global--BorderColor--100);
  --pf-c-label--m-red--BackgroundColor: var(--pf-global--palette--red-50);
  --pf-c-label--m-red__icon--Color: var(--pf-global--danger-color--100);
  --pf-c-label--m-red__content--Color: var(--pf-global--palette--red-300);
  --pf-c-label--m-red__content--before--BorderColor: var(--pf-global--palette--red-100);
  --pf-c-label--m-red__content--link--hover--before--BorderColor: var(--pf-global--danger-color--100);
  --pf-c-label--m-red__content--link--focus--before--BorderColor: var(--pf-global--danger-color--100);
  --pf-c-label--m-outline--m-red__content--Color: var(--pf-global--danger-color--100);
  --pf-c-label--m-outline--m-red__content--before--BorderColor: var(--pf-global--BorderColor--100);
  --pf-c-label--m-outline--m-red__content--link--hover--before--BorderColor: var(--pf-global--BorderColor--100);
  --pf-c-label--m-outline--m-red__content--link--focus--before--BorderColor: var(--pf-global--BorderColor--100);
  --pf-c-label--m-purple--BackgroundColor: var(--pf-global--palette--purple-50);
  --pf-c-label--m-purple__icon--Color: var(--pf-global--palette--purple-500);
  --pf-c-label--m-purple__content--Color: var(--pf-global--palette--purple-700);
  --pf-c-label--m-purple__content--before--BorderColor: var(--pf-global--palette--purple-100);
  --pf-c-label--m-purple__content--link--hover--before--BorderColor: var(--pf-global--palette--purple-500);
  --pf-c-label--m-purple__content--link--focus--before--BorderColor: var(--pf-global--palette--purple-500);
  --pf-c-label--m-outline--m-purple__content--Color: var(--pf-global--palette--purple-500);
  --pf-c-label--m-outline--m-purple__content--before--BorderColor: var(--pf-global--BorderColor--100);
  --pf-c-label--m-outline--m-purple__content--link--hover--before--BorderColor: var(--pf-global--BorderColor--100);
  --pf-c-label--m-outline--m-purple__content--link--focus--before--BorderColor: var(--pf-global--BorderColor--100);
  --pf-c-label--m-cyan--BackgroundColor: var(--pf-global--palette--cyan-50);
  --pf-c-label--m-cyan__icon--Color: var(--pf-global--default-color--200);
  --pf-c-label--m-cyan__content--Color: var(--pf-global--default-color--300);
  --pf-c-label--m-cyan__content--before--BorderColor: var(--pf-global--palette--cyan-100);
  --pf-c-label--m-cyan__content--link--hover--before--BorderColor: var(--pf-global--default-color--200);
  --pf-c-label--m-cyan__content--link--focus--before--BorderColor: var(--pf-global--default-color--200);
  --pf-c-label--m-outline--m-cyan__content--Color: var(--pf-global--palette--cyan-400);
  --pf-c-label--m-outline--m-cyan__content--before--BorderColor: var(--pf-global--BorderColor--100);
  --pf-c-label--m-outline--m-cyan__content--link--hover--before--BorderColor: var(--pf-global--BorderColor--100);
  --pf-c-label--m-outline--m-cyan__content--link--focus--before--BorderColor: var(--pf-global--BorderColor--100);
  --pf-c-label--m-gold--BackgroundColor: var(--pf-global--palette--gold-50);
  --pf-c-label--m-gold__icon--Color: var(--pf-global--palette--gold-400);
  --pf-c-label--m-gold__content--Color: var(--pf-global--palette--gold-700);
  --pf-c-label--m-gold__content--before--BorderColor: var(--pf-global--palette--gold-100);
  --pf-c-label--m-gold__content--link--hover--before--BorderColor: var(--pf-global--palette--gold-300);
  --pf-c-label--m-gold__content--link--focus--before--BorderColor: var(--pf-global--palette--gold-300);
  --pf-c-label--m-outline--m-gold__content--Color: var(--pf-global--palette--gold-600);
  --pf-c-label--m-outline--m-gold__content--before--BorderColor: var(--pf-global--BorderColor--100);
  --pf-c-label--m-outline--m-gold__content--link--hover--before--BorderColor: var(--pf-global--BorderColor--100);
  --pf-c-label--m-outline--m-gold__content--link--focus--before--BorderColor: var(--pf-global--BorderColor--100);
  --pf-c-label--m-overflow__content--Color: var(--pf-global--link--Color);
  --pf-c-label--m-overflow__content--BackgroundColor: var(--pf-global--BackgroundColor--100);
  --pf-c-label--m-overflow__content--before--BorderWidth: var(--pf-global--BorderWidth--sm);
  --pf-c-label--m-overflow__content--before--BorderColor: var(--pf-global--BorderColor--300);
  --pf-c-label--m-overflow__content--link--hover--before--BorderWidth: var(--pf-global--BorderWidth--md);
  --pf-c-label--m-overflow__content--link--hover--before--BorderColor: var(--pf-global--BorderColor--300);
  --pf-c-label--m-overflow__content--link--focus--before--BorderWidth: var(--pf-global--BorderWidth--md);
  --pf-c-label--m-overflow__content--link--focus--before--BorderColor: var(--pf-global--BorderColor--300);
  --pf-c-label--m-compact--PaddingTop: 0;
  --pf-c-label--m-compact--PaddingRight: var(--pf-global--spacer--sm);
  --pf-c-label--m-compact--PaddingBottom: 0;
  --pf-c-label--m-compact--PaddingLeft: var(--pf-global--spacer--sm);
  --pf-c-label--m-compact--FontSize: var(--pf-global--FontSize--xs);
  --pf-c-label--m-compact--m-editable--TextDecorationOffset: 0.0625rem;
  --pf-c-label__content--Color: var(--pf-global--Color--100);
  --pf-c-label__content--MaxWidth: 100%;
  --pf-c-label--m-outline__content--Color: var(--pf-global--Color--100);
  --pf-c-label--m-editable__content--MaxWidth: 16ch;
  --pf-c-label__text--MaxWidth: 16ch;
  --pf-c-label__icon--Color: var(--pf-global--Color--100);
  --pf-c-label__icon--MarginRight: var(--pf-global--spacer--xs);
  --pf-c-label__c-button--FontSize: var(--pf-global--FontSize--xs);
  --pf-c-label__c-button--MarginTop: calc(var(--pf-global--spacer--form-element) * -1);
  --pf-c-label__c-button--MarginRight: calc(var(--pf-global--spacer--form-element) * -1);
  --pf-c-label__c-button--MarginBottom: calc(var(--pf-global--spacer--form-element) * -1);
  --pf-c-label__c-button--MarginLeft: var(--pf-global--spacer--xs);
  --pf-c-label__c-button--PaddingTop: var(--pf-global--spacer--xs);
  --pf-c-label__c-button--PaddingRight: var(--pf-global--spacer--sm);
  --pf-c-label__c-button--PaddingBottom: var(--pf-global--spacer--xs);
  --pf-c-label__c-button--PaddingLeft: var(--pf-global--spacer--sm);
  --pf-c-label--m-editable--Cursor: pointer;
  --pf-c-label--m-editable--TextDecoration: underline;
  --pf-c-label--m-editable--TextDecorationStyle: dashed;
  --pf-c-label--m-editable--TextDecorationThickness: var(--pf-global--BorderWidth--sm);
  --pf-c-label--m-editable--TextDecorationOffset: 0.25rem;
  --pf-c-label--m-editable--TextDecorationColor: var(--pf-global--BorderColor--200);
  --pf-c-label--m-editable--hover--TextDecorationColor: var(--pf-global--Color--100);
  --pf-c-label--m-editable--focus--TextDecorationColor: var(--pf-global--Color--100);
  --pf-c-label--m-editable__content--before--BorderWidth: var(--pf-global--BorderWidth--sm);
  --pf-c-label--m-editable__content--before--BorderColor: var(--pf-global--BorderColor--100);
  --pf-c-label--m-editable__content--hover--before--BorderWidth: var(--pf-global--BorderWidth--sm);
  --pf-c-label--m-editable__content--hover--before--BorderColor: var(--pf-global--BorderColor--100);
  --pf-c-label--m-editable__content--focus--before--BorderWidth: var(--pf-global--BorderWidth--sm);
  --pf-c-label--m-editable__content--focus--before--BorderColor: var(--pf-global--BorderColor--100);
  --pf-c-label--m-editable--m-editable-active--Cursor: auto;
  --pf-c-label--m-editable--m-editable-active--TextDecoration: none;
  --pf-c-label--m-editable--m-editable-active--BackgroundColor: transparent;
  --pf-c-label--m-editable--m-editable-active__content--before--BorderWidth: 0;
  --pf-c-label--m-editable--m-editable-active__content--before--BorderColor: transparent;
  position: relative;
  padding: var(--pf-c-label--PaddingTop) var(--pf-c-label--PaddingRight) var(--pf-c-label--PaddingBottom) var(--pf-c-label--PaddingLeft);
  font-size: var(--pf-c-label--FontSize);
  color: var(--pf-c-label--Color);
  white-space: nowrap;
  background-color: var(--pf-c-label--BackgroundColor);
  border: 0;
  border-radius: var(--pf-c-label--BorderRadius);
}
.pf-c-label.pf-m-compact {
  --pf-c-label--PaddingTop: var(--pf-c-label--m-compact--PaddingTop);
  --pf-c-label--PaddingRight: var(--pf-c-label--m-compact--PaddingRight);
  --pf-c-label--PaddingBottom: var(--pf-c-label--m-compact--PaddingBottom);
  --pf-c-label--PaddingLeft: var(--pf-c-label--m-compact--PaddingLeft);
  --pf-c-label--FontSize: var(--pf-c-label--m-compact--FontSize);
  --pf-c-label--m-editable--TextDecorationOffset: var(--pf-c-label--m-compact--m-editable--TextDecorationOffset);
}
.pf-c-label.pf-m-blue {
  --pf-c-label--BackgroundColor: var(--pf-c-label--m-blue--BackgroundColor);
  --pf-c-label__icon--Color: var(--pf-c-label--m-blue__icon--Color);
  --pf-c-label__content--Color: var(--pf-c-label--m-blue__content--Color);
  --pf-c-label__content--before--BorderColor: var(--pf-c-label--m-blue__content--before--BorderColor);
  --pf-c-label__content--link--hover--before--BorderColor: var(--pf-c-label--m-blue__content--link--hover--before--BorderColor);
  --pf-c-label__content--link--focus--before--BorderColor: var(--pf-c-label--m-blue__content--link--focus--before--BorderColor);
  --pf-c-label--m-outline__content--Color: var(--pf-c-label--m-outline--m-blue__content--Color);
  --pf-c-label--m-outline__content--before--BorderColor: var(--pf-c-label--m-outline--m-blue__content--before--BorderColor);
  --pf-c-label--m-outline__content--link--hover--before--BorderColor: var(--pf-c-label--m-outline--m-blue__content--link--hover--before--BorderColor);
  --pf-c-label--m-outline__content--link--focus--before--BorderColor: var(--pf-c-label--m-outline--m-blue__content--link--focus--before--BorderColor);
  --pf-c-label--m-editable__content--before--BorderColor: var(--pf-c-label--m-blue__content--before--BorderColor);
  --pf-c-label--m-editable__content--hover--before--BorderColor: var(--pf-c-label--m-blue__content--before--BorderColor);
  --pf-c-label--m-editable__content--focus--before--BorderColor: var(--pf-c-label--m-blue__content--before--BorderColor);
}
.pf-c-label.pf-m-green {
  --pf-c-label--BackgroundColor: var(--pf-c-label--m-green--BackgroundColor);
  --pf-c-label__icon--Color: var(--pf-c-label--m-green__icon--Color);
  --pf-c-label__content--Color: var(--pf-c-label--m-green__content--Color);
  --pf-c-label__content--before--BorderColor: var(--pf-c-label--m-green__content--before--BorderColor);
  --pf-c-label__content--link--hover--before--BorderColor: var(--pf-c-label--m-green__content--link--hover--before--BorderColor);
  --pf-c-label__content--link--focus--before--BorderColor: var(--pf-c-label--m-green__content--link--focus--before--BorderColor);
  --pf-c-label--m-outline__content--Color: var(--pf-c-label--m-outline--m-green__content--Color);
  --pf-c-label--m-outline__content--before--BorderColor: var(--pf-c-label--m-outline--m-green__content--before--BorderColor);
  --pf-c-label--m-outline__content--link--hover--before--BorderColor: var(--pf-c-label--m-outline--m-green__content--link--hover--before--BorderColor);
  --pf-c-label--m-outline__content--link--focus--before--BorderColor: var(--pf-c-label--m-outline--m-green__content--link--focus--before--BorderColor);
  --pf-c-label--m-editable__content--before--BorderColor: var(--pf-c-label--m-green__content--before--BorderColor);
  --pf-c-label--m-editable__content--hover--before--BorderColor: var(--pf-c-label--m-green__content--before--BorderColor);
  --pf-c-label--m-editable__content--focus--before--BorderColor: var(--pf-c-label--m-green__content--before--BorderColor);
}
.pf-c-label.pf-m-orange {
  --pf-c-label--BackgroundColor: var(--pf-c-label--m-orange--BackgroundColor);
  --pf-c-label__icon--Color: var(--pf-c-label--m-orange__icon--Color);
  --pf-c-label__content--Color: var(--pf-c-label--m-orange__content--Color);
  --pf-c-label__content--before--BorderColor: var(--pf-c-label--m-orange__content--before--BorderColor);
  --pf-c-label__content--link--hover--before--BorderColor: var(--pf-c-label--m-orange__content--link--hover--before--BorderColor);
  --pf-c-label__content--link--focus--before--BorderColor: var(--pf-c-label--m-orange__content--link--focus--before--BorderColor);
  --pf-c-label--m-outline__content--Color: var(--pf-c-label--m-outline--m-orange__content--Color);
  --pf-c-label--m-outline__content--before--BorderColor: var(--pf-c-label--m-outline--m-orange__content--before--BorderColor);
  --pf-c-label--m-outline__content--link--hover--before--BorderColor: var(--pf-c-label--m-outline--m-orange__content--link--hover--before--BorderColor);
  --pf-c-label--m-outline__content--link--focus--before--BorderColor: var(--pf-c-label--m-outline--m-orange__content--link--focus--before--BorderColor);
  --pf-c-label--m-editable__content--before--BorderColor: var(--pf-c-label--m-orange__content--before--BorderColor);
  --pf-c-label--m-editable__content--hover--before--BorderColor: var(--pf-c-label--m-orange__content--before--BorderColor);
  --pf-c-label--m-editable__content--focus--before--BorderColor: var(--pf-c-label--m-orange__content--before--BorderColor);
}
.pf-c-label.pf-m-red {
  --pf-c-label--BackgroundColor: var(--pf-c-label--m-red--BackgroundColor);
  --pf-c-label__icon--Color: var(--pf-c-label--m-red__icon--Color);
  --pf-c-label__content--Color: var(--pf-c-label--m-red__content--Color);
  --pf-c-label__content--before--BorderColor: var(--pf-c-label--m-red__content--before--BorderColor);
  --pf-c-label__content--link--hover--before--BorderColor: var(--pf-c-label--m-red__content--link--hover--before--BorderColor);
  --pf-c-label__content--link--focus--before--BorderColor: var(--pf-c-label--m-red__content--link--focus--before--BorderColor);
  --pf-c-label--m-outline__content--Color: var(--pf-c-label--m-outline--m-red__content--Color);
  --pf-c-label--m-outline__content--before--BorderColor: var(--pf-c-label--m-outline--m-red__content--before--BorderColor);
  --pf-c-label--m-outline__content--link--hover--before--BorderColor: var(--pf-c-label--m-outline--m-red__content--link--hover--before--BorderColor);
  --pf-c-label--m-outline__content--link--focus--before--BorderColor: var(--pf-c-label--m-outline--m-red__content--link--focus--before--BorderColor);
  --pf-c-label--m-editable__content--before--BorderColor: var(--pf-c-label--m-red__content--before--BorderColor);
  --pf-c-label--m-editable__content--hover--before--BorderColor: var(--pf-c-label--m-red__content--before--BorderColor);
  --pf-c-label--m-editable__content--focus--before--BorderColor: var(--pf-c-label--m-red__content--before--BorderColor);
}
.pf-c-label.pf-m-purple {
  --pf-c-label--BackgroundColor: var(--pf-c-label--m-purple--BackgroundColor);
  --pf-c-label__icon--Color: var(--pf-c-label--m-purple__icon--Color);
  --pf-c-label__content--Color: var(--pf-c-label--m-purple__content--Color);
  --pf-c-label__content--before--BorderColor: var(--pf-c-label--m-purple__content--before--BorderColor);
  --pf-c-label__content--link--hover--before--BorderColor: var(--pf-c-label--m-purple__content--link--hover--before--BorderColor);
  --pf-c-label__content--link--focus--before--BorderColor: var(--pf-c-label--m-purple__content--link--focus--before--BorderColor);
  --pf-c-label--m-outline__content--Color: var(--pf-c-label--m-outline--m-purple__content--Color);
  --pf-c-label--m-outline__content--before--BorderColor: var(--pf-c-label--m-outline--m-purple__content--before--BorderColor);
  --pf-c-label--m-outline__content--link--hover--before--BorderColor: var(--pf-c-label--m-outline--m-purple__content--link--hover--before--BorderColor);
  --pf-c-label--m-outline__content--link--focus--before--BorderColor: var(--pf-c-label--m-outline--m-purple__content--link--focus--before--BorderColor);
  --pf-c-label--m-editable__content--before--BorderColor: var(--pf-c-label--m-purple__content--before--BorderColor);
  --pf-c-label--m-editable__content--hover--before--BorderColor: var(--pf-c-label--m-purple__content--before--BorderColor);
  --pf-c-label--m-editable__content--focus--before--BorderColor: var(--pf-c-label--m-purple__content--before--BorderColor);
}
.pf-c-label.pf-m-cyan {
  --pf-c-label--BackgroundColor: var(--pf-c-label--m-cyan--BackgroundColor);
  --pf-c-label__icon--Color: var(--pf-c-label--m-cyan__icon--Color);
  --pf-c-label__content--Color: var(--pf-c-label--m-cyan__content--Color);
  --pf-c-label__content--before--BorderColor: var(--pf-c-label--m-cyan__content--before--BorderColor);
  --pf-c-label__content--link--hover--before--BorderColor: var(--pf-c-label--m-cyan__content--link--hover--before--BorderColor);
  --pf-c-label__content--link--focus--before--BorderColor: var(--pf-c-label--m-cyan__content--link--focus--before--BorderColor);
  --pf-c-label--m-outline__content--Color: var(--pf-c-label--m-outline--m-cyan__content--Color);
  --pf-c-label--m-outline__content--before--BorderColor: var(--pf-c-label--m-outline--m-cyan__content--before--BorderColor);
  --pf-c-label--m-outline__content--link--hover--before--BorderColor: var(--pf-c-label--m-outline--m-cyan__content--link--hover--before--BorderColor);
  --pf-c-label--m-outline__content--link--focus--before--BorderColor: var(--pf-c-label--m-outline--m-cyan__content--link--focus--before--BorderColor);
  --pf-c-label--m-editable__content--before--BorderColor: var(--pf-c-label--m-cyan__content--before--BorderColor);
  --pf-c-label--m-editable__content--hover--before--BorderColor: var(--pf-c-label--m-cyan__content--before--BorderColor);
  --pf-c-label--m-editable__content--focus--before--BorderColor: var(--pf-c-label--m-cyan__content--before--BorderColor);
}
.pf-c-label.pf-m-gold {
  --pf-c-label--BackgroundColor: var(--pf-c-label--m-gold--BackgroundColor);
  --pf-c-label__icon--Color: var(--pf-c-label--m-gold__icon--Color);
  --pf-c-label__content--Color: var(--pf-c-label--m-gold__content--Color);
  --pf-c-label__content--before--BorderColor: var(--pf-c-label--m-gold__content--before--BorderColor);
  --pf-c-label__content--link--hover--before--BorderColor: var(--pf-c-label--m-gold__content--link--hover--before--BorderColor);
  --pf-c-label__content--link--focus--before--BorderColor: var(--pf-c-label--m-gold__content--link--focus--before--BorderColor);
  --pf-c-label--m-outline__content--Color: var(--pf-c-label--m-outline--m-gold__content--Color);
  --pf-c-label--m-outline__content--before--BorderColor: var(--pf-c-label--m-outline--m-gold__content--before--BorderColor);
  --pf-c-label--m-outline__content--link--hover--before--BorderColor: var(--pf-c-label--m-outline--m-gold__content--link--hover--before--BorderColor);
  --pf-c-label--m-outline__content--link--focus--before--BorderColor: var(--pf-c-label--m-outline--m-gold__content--link--focus--before--BorderColor);
  --pf-c-label--m-editable__content--before--BorderColor: var(--pf-c-label--m-gold__content--before--BorderColor);
  --pf-c-label--m-editable__content--hover--before--BorderColor: var(--pf-c-label--m-gold__content--before--BorderColor);
  --pf-c-label--m-editable__content--focus--before--BorderColor: var(--pf-c-label--m-gold__content--before--BorderColor);
}
.pf-c-label.pf-m-outline {
  --pf-c-label__content--Color: var(--pf-c-label--m-outline__content--Color);
  --pf-c-label__content--before--BorderWidth: var(--pf-c-label--m-outline__content--before--BorderWidth);
  --pf-c-label__content--before--BorderColor: var(--pf-c-label--m-outline__content--before--BorderColor);
  --pf-c-label--BackgroundColor: var(--pf-c-label--m-outline--BackgroundColor);
  --pf-c-label--m-editable__content--before--BorderColor: var(--pf-c-label--m-outline__content--before--BorderColor);
  --pf-c-label--m-editable__content--hover--before--BorderColor: var(--pf-c-label--m-outline__content--before--BorderColor);
  --pf-c-label--m-editable__content--focus--before--BorderColor: var(--pf-c-label--m-outline__content--before--BorderColor);
}
.pf-c-label.pf-m-overflow:hover,
.pf-c-label.pf-m-add:hover, .pf-c-label.pf-m-outline a.pf-c-label__content:hover,
.pf-c-label.pf-m-outline button.pf-c-label__content:hover {
  --pf-c-label__content--before--BorderWidth: var(--pf-c-label--m-outline__content--link--hover--before--BorderWidth);
  --pf-c-label__content--before--BorderColor: var(--pf-c-label--m-outline__content--link--hover--before--BorderColor);
}
.pf-c-label.pf-m-overflow:focus,
.pf-c-label.pf-m-add:focus, .pf-c-label.pf-m-outline a.pf-c-label__content:focus,
.pf-c-label.pf-m-outline button.pf-c-label__content:focus {
  --pf-c-label__content--before--BorderWidth: var(--pf-c-label--m-outline__content--link--focus--before--BorderWidth);
  --pf-c-label__content--before--BorderColor: var(--pf-c-label--m-outline__content--link--focus--before--BorderColor);
}

.pf-c-label.pf-m-editable {
  text-decoration: var(--pf-c-label--m-editable--TextDecoration);
  cursor: var(--pf-c-label--m-editable--Cursor);
  text-decoration-style: var(--pf-c-label--m-editable--TextDecorationStyle);
  text-decoration-thickness: var(--pf-c-label--m-editable--TextDecorationThickness);
  text-underline-offset: var(--pf-c-label--m-editable--TextDecorationOffset);
  text-decoration-color: var(--pf-c-label--m-editable--TextDecorationColor);
  --pf-c-label__content--MaxWidth: var(--pf-c-label--m-editable__content--MaxWidth);
  --pf-c-label__content--before--BorderWidth: var(--pf-c-label--m-editable__content--before--BorderWidth);
  --pf-c-label__content--before--BorderColor: var(--pf-c-label--m-editable__content--before--BorderColor);
  --pf-c-label__content--link--hover--before--BorderWidth: var(--pf-c-label--m-editable__content--hover--before--BorderWidth);
  --pf-c-label__content--link--hover--before--BorderColor: var(--pf-c-label--m-editable__content--hover--before--BorderColor);
  --pf-c-label__content--link--focus--before--BorderWidth: var(--pf-c-label--m-editable__content--focus--before--BorderWidth);
  --pf-c-label__content--link--focus--before--BorderColor: var(--pf-c-label--m-editable__content--focus--before--BorderColor);
  --pf-c-label--m-outline__content--link--hover--before--BorderWidth: var(--pf-c-label--m-editable__content--before--BorderWidth);
  --pf-c-label--m-outline__content--link--focus--before--BorderWidth: var(--pf-c-label--m-editable__content--before--BorderWidth);
}
.pf-c-label.pf-m-editable:hover {
  --pf-c-label--m-editable--TextDecorationColor: var(--pf-c-label--m-editable--hover--TextDecorationColor);
}
.pf-c-label.pf-m-editable:focus {
  --pf-c-label--m-editable--TextDecorationColor: var(--pf-c-label--m-editable--focus--TextDecorationColor);
}
.pf-c-label.pf-m-editable-active {
  --pf-c-label--m-editable--Cursor: var(--pf-c-label--m-editable--m-editable-active--Cursor);
  --pf-c-label--m-editable--TextDecoration: var(--pf-c-label--m-editable--m-editable-active--TextDecoration);
  --pf-c-label--BackgroundColor: var(--pf-c-label--m-editable--m-editable-active--BackgroundColor);
}
.pf-c-label.pf-m-editable-active .pf-c-button {
  visibility: hidden;
}
.pf-c-label .pf-c-button {
  --pf-c-button--FontSize: var(--pf-c-label__c-button--FontSize);
  --pf-c-button--PaddingTop: var(--pf-c-label__c-button--PaddingTop);
  --pf-c-button--PaddingRight: var(--pf-c-label__c-button--PaddingRight);
  --pf-c-button--PaddingBottom: var(--pf-c-label__c-button--PaddingBottom);
  --pf-c-button--PaddingLeft: var(--pf-c-label__c-button--PaddingLeft);
  margin-top: var(--pf-c-label__c-button--MarginTop);
  margin-right: var(--pf-c-label__c-button--MarginRight);
  margin-bottom: var(--pf-c-label__c-button--MarginBottom);
  margin-left: var(--pf-c-label__c-button--MarginLeft);
}
.pf-c-label.pf-m-overflow, .pf-c-label.pf-m-add {
  --pf-c-label__content--Color: var(--pf-c-label--m-overflow__content--Color);
  --pf-c-label--BackgroundColor: var(--pf-c-label--m-overflow__content--BackgroundColor);
  --pf-c-label__content--before--BorderWidth: var(--pf-c-label--m-overflow__content--before--BorderWidth);
  --pf-c-label__content--before--BorderColor: var(--pf-c-label--m-overflow__content--before--BorderColor);
  --pf-c-label__content--link--hover--before--BorderWidth: var(--pf-c-label--m-overflow__content--link--hover--before--BorderWidth);
  --pf-c-label__content--link--hover--before--BorderColor: var(--pf-c-label--m-overflow__content--link--hover--before--BorderColor);
  --pf-c-label__content--link--focus--before--BorderWidth: var(--pf-c-label--m-overflow__content--link--focus--before--BorderWidth);
  --pf-c-label__content--link--focus--before--BorderColor: var(--pf-c-label--m-overflow__content--link--focus--before--BorderColor);
}

.pf-c-label,
.pf-c-label__content {
  display: inline-flex;
  align-items: center;
}

.pf-c-label__text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: var(--pf-c-label__text--MaxWidth);
}

.pf-c-label__content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: var(--pf-c-label__content--MaxWidth);
  color: var(--pf-c-label__content--Color);
  border-width: 0;
}
.pf-c-label__content::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  content: "";
  border: var(--pf-c-label__content--before--BorderWidth) solid var(--pf-c-label__content--before--BorderColor);
  border-radius: var(--pf-c-label--BorderRadius);
}
a.pf-c-label__content,
button.pf-c-label__content {
  cursor: pointer;
  border: none;
}
a.pf-c-label__content, a.pf-c-label__content:hover, a.pf-c-label__content:focus,
button.pf-c-label__content,
button.pf-c-label__content:hover,
button.pf-c-label__content:focus {
  text-decoration: none;
}
a.pf-c-label__content:hover,
button.pf-c-label__content:hover {
  --pf-c-label__content--before--BorderWidth: var(--pf-c-label__content--link--hover--before--BorderWidth);
  --pf-c-label__content--before--BorderColor: var(--pf-c-label__content--link--hover--before--BorderColor);
}
a.pf-c-label__content:focus,
button.pf-c-label__content:focus {
  --pf-c-label__content--before--BorderWidth: var(--pf-c-label__content--link--focus--before--BorderWidth);
  --pf-c-label__content--before--BorderColor: var(--pf-c-label__content--link--focus--before--BorderColor);
}

.pf-c-label__icon {
  margin-right: var(--pf-c-label__icon--MarginRight);
  color: var(--pf-c-label__icon--Color);
}

:where(.pf-theme-dark) .pf-c-label {
  --pf-c-label--BackgroundColor: var(--pf-global--BackgroundColor--300);
  --pf-c-label--m-blue--BackgroundColor: var(--pf-global--BackgroundColor--300);
  --pf-c-label--m-green--BackgroundColor: var(--pf-global--BackgroundColor--300);
  --pf-c-label--m-orange--BackgroundColor: var(--pf-global--BackgroundColor--300);
  --pf-c-label--m-red--BackgroundColor: var(--pf-global--BackgroundColor--300);
  --pf-c-label--m-purple--BackgroundColor: var(--pf-global--BackgroundColor--300);
  --pf-c-label--m-cyan--BackgroundColor: var(--pf-global--BackgroundColor--300);
  --pf-c-label__content--before--BorderWidth: 0;
  --pf-c-label__content--before--BorderColor: transparent;
  --pf-c-label__content--link--hover--before--BorderWidth: var(--pf-global--BorderWidth--sm);
  --pf-c-label__content--link--focus--before--BorderWidth: var(--pf-global--BorderWidth--sm);
  --pf-c-label--m-blue__content--Color: var(--pf-global--primary-color--100);
  --pf-c-label--m-outline--m-blue__content--before--BorderColor: var(--pf-global--active-color--200);
  --pf-c-label--m-outline--m-blue__content--link--hover--before--BorderColor: var(--pf-global--active-color--200);
  --pf-c-label--m-outline--m-blue__content--link--focus--before--BorderColor: var(--pf-global--active-color--200);
  --pf-c-label--m-green__content--Color: var(--pf-global--success-color--100);
  --pf-c-label--m-outline--m-green__content--before--BorderColor: var(--pf-global--palette--green-100);
  --pf-c-label--m-outline--m-green__content--link--hover--before--BorderColor: var(--pf-global--palette--green-100);
  --pf-c-label--m-outline--m-green__content--link--focus--before--BorderColor: var(--pf-global--palette--green-100);
  --pf-c-label--m-orange__content--Color: var(--pf-global--palette--orange-300);
  --pf-c-label--m-outline--m-orange__content--Color: var(--pf-global--palette--orange-300);
  --pf-c-label--m-outline--m-orange__content--before--BorderColor: var(--pf-global--palette--gold-100);
  --pf-c-label--m-outline--m-orange__content--link--hover--before--BorderColor: var(--pf-global--palette--gold-100);
  --pf-c-label--m-outline--m-orange__content--link--focus--before--BorderColor: var(--pf-global--palette--gold-100);
  --pf-c-label--m-red__content--Color: var(--pf-global--danger-color--100);
  --pf-c-label--m-outline--m-red__content--before--BorderColor: var(--pf-global--danger-color--100);
  --pf-c-label--m-outline--m-red__content--link--hover--before--BorderColor: var(--pf-global--danger-color--100);
  --pf-c-label--m-outline--m-red__content--link--focus--before--BorderColor: var(--pf-global--danger-color--100);
  --pf-c-label--m-purple__content--Color: var(--pf-global--palette--purple-300);
  --pf-c-label--m-purple__icon--Color: var(--pf-global--palette--purple-300);
  --pf-c-label--m-purple__content--link--hover--before--BorderColor: var(--pf-global--palette--purple-300);
  --pf-c-label--m-purple__content--link--focus--before--BorderColor: var(--pf-global--palette--purple-300);
  --pf-c-label--m-outline--m-purple__content--before--BorderColor: var(--pf-global--palette--purple-100);
  --pf-c-label--m-outline--m-purple__content--link--hover--before--BorderColor: var(--pf-global--palette--purple-100);
  --pf-c-label--m-outline--m-purple__content--link--focus--before--BorderColor: var(--pf-global--palette--purple-100);
  --pf-c-label--m-outline--m-purple__content--Color: var(--pf-global--palette--purple-300);
  --pf-c-label--m-cyan__content--Color: var(--pf-global--default-color--200);
  --pf-c-label--m-outline--m-cyan__content--Color: var(--pf-global--default-color--200);
  --pf-c-label--m-outline--m-cyan__content--before--BorderColor: var(--pf-global--palette--cyan-100);
  --pf-c-label--m-outline--m-cyan__content--link--hover--before--BorderColor: var(--pf-global--palette--cyan-100);
  --pf-c-label--m-outline--m-cyan__content--link--focus--before--BorderColor: var(--pf-global--palette--cyan-100);
}