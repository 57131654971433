.pf-c-text-input-group {
  --pf-c-text-input-group--BackgroundColor: var(--pf-global--BackgroundColor--100);
  --pf-c-text-input-group__text--before--BorderWidth: var(--pf-global--BorderWidth--sm);
  --pf-c-text-input-group__text--before--BorderColor: var(--pf-global--BorderColor--300);
  --pf-c-text-input-group__text--after--BorderBottomWidth: var(--pf-global--BorderWidth--sm);
  --pf-c-text-input-group__text--after--BorderBottomColor: var(--pf-global--BorderColor--200);
  --pf-c-text-input-group--hover__text--after--BorderBottomColor: var(--pf-global--primary-color--100);
  --pf-c-text-input-group__text--focus-within--after--BorderBottomWidth: var(--pf-global--BorderWidth--md);
  --pf-c-text-input-group__text--focus-within--after--BorderBottomColor: var(--pf-global--primary-color--100);
  --pf-c-text-input-group__main--first-child--not--text-input--MarginLeft: var(--pf-global--spacer--sm);
  --pf-c-text-input-group__main--m-icon__text-input--PaddingLeft: var(--pf-global--spacer--xl);
  --pf-c-text-input-group__main--RowGap: var(--pf-global--spacer--xs);
  --pf-c-text-input-group__main--ColumnGap: var(--pf-global--spacer--sm);
  --pf-c-text-input-group--c-chip-group__main--PaddingTop: var(--pf-global--spacer--xs);
  --pf-c-text-input-group--c-chip-group__main--PaddingRight: var(--pf-global--spacer--xs);
  --pf-c-text-input-group--c-chip-group__main--PaddingBottom: var(--pf-global--spacer--xs);
  --pf-c-text-input-group__text-input--PaddingTop: var(--pf-global--spacer--form-element);
  --pf-c-text-input-group__text-input--PaddingRight: var(--pf-global--spacer--sm);
  --pf-c-text-input-group__text-input--PaddingBottom: var(--pf-global--spacer--form-element);
  --pf-c-text-input-group__text-input--PaddingLeft: var(--pf-global--spacer--sm);
  --pf-c-text-input-group__text-input--MinWidth: 12ch;
  --pf-c-text-input-group__text-input--m-hint--Color: var(--pf-global--Color--dark-200);
  --pf-c-text-input-group--placeholder--Color: var(--pf-global--Color--dark-200);
  --pf-c-text-input-group__icon--Left: var(--pf-global--spacer--sm);
  --pf-c-text-input-group__icon--Color: var(--pf-global--Color--200);
  --pf-c-text-input-group__text--hover__icon--Color: var(--pf-global--Color--100);
  --pf-c-text-input-group__icon--TranslateY: -50%;
  --pf-c-text-input-group__utilities--MarginRight: var(--pf-global--spacer--sm);
  --pf-c-text-input-group__utilities--MarginLeft: var(--pf-global--spacer--xs);
  --pf-c-text-input-group__utilities--child--MarginLeft: var(--pf-global--spacer--xs);
  --pf-c-text-input-group__utilities--c-button--PaddingRight: var(--pf-global--spacer--xs);
  --pf-c-text-input-group__utilities--c-button--PaddingLeft: var(--pf-global--spacer--xs);
  --pf-c-text-input-group--m-disabled--Color: var(--pf-global--disabled-color--100);
  --pf-c-text-input-group--m-disabled--BackgroundColor: var(--pf-global--disabled-color--300);
  position: relative;
  display: flex;
  width: 100%;
  color: var(--pf-c-text-input-group--Color, inherit);
  background-color: var(--pf-c-text-input-group--BackgroundColor);
}
.pf-c-text-input-group:hover {
  --pf-c-text-input-group__text--after--BorderBottomColor: var(--pf-c-text-input-group--hover__text--after--BorderBottomColor);
}
.pf-c-text-input-group.pf-m-disabled {
  --pf-c-text-input-group__text--before--BorderWidth: 0;
  --pf-c-text-input-group__text--after--BorderBottomWidth: 0;
  --pf-c-text-input-group--Color: var(--pf-c-text-input-group--m-disabled--Color);
  --pf-c-text-input-group__icon--Color: var(--pf-c-text-input-group--m-disabled--Color);
  --pf-c-text-input-group--BackgroundColor: var(--pf-c-text-input-group--m-disabled--BackgroundColor);
  pointer-events: none;
}
.pf-c-text-input-group.pf-m-plain {
  --pf-c-text-input-group--BackgroundColor: transparent;
}
.pf-c-text-input-group.pf-m-plain .pf-c-text-input-group__text::before, .pf-c-text-input-group.pf-m-plain .pf-c-text-input-group__text::after {
  border: 0;
}

.pf-c-text-input-group__main {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  gap: var(--pf-c-text-input-group__main--RowGap) var(--pf-c-text-input-group__main--ColumnGap);
  min-width: 0;
}
.pf-c-text-input-group__main.pf-m-icon {
  --pf-c-text-input-group__text-input--PaddingLeft: var(--pf-c-text-input-group__main--m-icon__text-input--PaddingLeft);
}
.pf-c-text-input-group__main > :first-child:not(.pf-c-text-input-group__text) {
  margin-left: var(--pf-c-text-input-group__main--first-child--not--text-input--MarginLeft);
}
.pf-c-text-input-group__main .pf-c-chip-group__main {
  padding-top: var(--pf-c-text-input-group--c-chip-group__main--PaddingTop);
  padding-right: var(--pf-c-text-input-group--c-chip-group__main--PaddingRight);
  padding-bottom: var(--pf-c-text-input-group--c-chip-group__main--PaddingBottom);
}

.pf-c-text-input-group__text {
  display: inline-grid;
  grid-template-columns: 1fr;
  grid-template-areas: "text-input";
  flex: 1;
}
.pf-c-text-input-group__text::before, .pf-c-text-input-group__text::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  content: "";
}
.pf-c-text-input-group__text::before {
  border: var(--pf-c-text-input-group__text--before--BorderWidth) solid var(--pf-c-text-input-group__text--before--BorderColor);
}
.pf-c-text-input-group__text::after {
  border-bottom: var(--pf-c-text-input-group__text--after--BorderBottomWidth) solid var(--pf-c-text-input-group__text--after--BorderBottomColor);
}
.pf-c-text-input-group__text:hover, .pf-c-text-input-group__text:focus-within {
  --pf-c-text-input-group__icon--Color: var(--pf-c-text-input-group__text--hover__icon--Color);
}
.pf-c-text-input-group__text:focus-within {
  --pf-c-text-input-group__text--after--BorderBottomWidth: var(--pf-c-text-input-group__text--focus-within--after--BorderBottomWidth);
  --pf-c-text-input-group__text--after--BorderBottomColor: var(--pf-c-text-input-group__text--focus-within--after--BorderBottomColor);
}

.pf-c-text-input-group__icon {
  position: absolute;
  top: 50%;
  left: var(--pf-c-text-input-group__icon--Left);
  color: var(--pf-c-text-input-group__icon--Color);
  transform: translateY(var(--pf-c-text-input-group__icon--TranslateY));
}

.pf-c-text-input-group__text-input {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  width: 100%;
  min-width: var(--pf-c-text-input-group__text-input--MinWidth);
  padding: var(--pf-c-text-input-group__text-input--PaddingTop) var(--pf-c-text-input-group__text-input--PaddingRight) var(--pf-c-text-input-group__text-input--PaddingBottom) var(--pf-c-text-input-group__text-input--PaddingLeft);
  border: 0;
}
.pf-c-text-input-group__text-input, .pf-c-text-input-group__text-input.pf-m-hint {
  grid-area: text-input;
}
.pf-c-text-input-group__text-input.pf-m-hint {
  color: var(--pf-c-text-input-group__text-input--m-hint--Color);
}
.pf-c-text-input-group__text-input::placeholder {
  color: var(--pf-c-text-input-group--placeholder--Color);
}

.pf-c-text-input-group__utilities {
  display: flex;
  align-items: center;
  margin-right: var(--pf-c-text-input-group__utilities--MarginRight);
  margin-left: var(--pf-c-text-input-group__utilities--MarginLeft);
}
.pf-c-text-input-group__utilities > * + * {
  margin-left: var(--pf-c-text-input-group__utilities--child--MarginLeft);
}
.pf-c-text-input-group__utilities .pf-c-button {
  --pf-c-button--PaddingRight: var(--pf-c-text-input-group__utilities--c-button--PaddingRight);
  --pf-c-button--PaddingLeft: var(--pf-c-text-input-group__utilities--c-button--PaddingLeft);
}

.pf-c-text-input-group__group {
  display: flex;
}