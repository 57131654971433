.pf-c-chip {
  --pf-global--Color--100: var(--pf-global--Color--dark-100);
  --pf-global--Color--200: var(--pf-global--Color--dark-200);
  --pf-global--BorderColor--100: var(--pf-global--BorderColor--dark-100);
  --pf-global--primary-color--100: var(--pf-global--primary-color--dark-100);
  --pf-global--link--Color: var(--pf-global--link--Color--dark);
  --pf-global--link--Color--hover: var(--pf-global--link--Color--dark--hover);
  --pf-global--BackgroundColor--100: var(--pf-global--BackgroundColor--light-100);
}

.pf-c-chip {
  --pf-c-chip--PaddingTop: var(--pf-global--spacer--xs);
  --pf-c-chip--PaddingRight: var(--pf-global--spacer--sm);
  --pf-c-chip--PaddingBottom: var(--pf-global--spacer--xs);
  --pf-c-chip--PaddingLeft: var(--pf-global--spacer--sm);
  --pf-c-chip--BackgroundColor: var(--pf-global--Color--light-100);
  --pf-c-chip--BorderRadius: var(--pf-global--BorderRadius--sm);
  --pf-c-chip--before--BorderColor: var(--pf-global--BorderColor--300);
  --pf-c-chip--before--BorderWidth: var(--pf-global--BorderWidth--sm);
  --pf-c-chip--before--BorderRadius: var(--pf-c-chip--BorderRadius);
  --pf-c-chip--m-overflow__text--Color: var(--pf-global--primary-color--100);
  --pf-c-chip--m-draggable--BackgroundColor: var(--pf-global--BackgroundColor--200);
  --pf-c-chip--m-draggable--BoxShadow: var(--pf-global--BoxShadow--sm);
  --pf-c-chip--m-draggable__icon--FontSize: var(--pf-global--icon--FontSize--sm);
  --pf-c-chip__text--FontSize: var(--pf-global--FontSize--xs);
  --pf-c-chip__text--Color: var(--pf-global--Color--100);
  --pf-c-chip__text--MaxWidth: 16ch;
  --pf-c-chip__c-button--PaddingTop: var(--pf-global--spacer--xs);
  --pf-c-chip__c-button--PaddingRight: var(--pf-global--spacer--sm);
  --pf-c-chip__c-button--PaddingBottom: var(--pf-global--spacer--xs);
  --pf-c-chip__c-button--PaddingLeft: var(--pf-global--spacer--sm);
  --pf-c-chip__c-button--MarginTop: calc(var(--pf-c-chip--PaddingTop) * -1);
  --pf-c-chip__c-button--MarginRight: calc(var(--pf-c-chip--PaddingRight) / 2 * -1);
  --pf-c-chip__c-button--MarginBottom: calc(var(--pf-c-chip--PaddingBottom) * -1);
  --pf-c-chip__c-button--FontSize: var(--pf-global--FontSize--xs);
  --pf-c-chip__c-badge--MarginLeft: var(--pf-global--spacer--xs);
  --pf-c-chip__icon--MarginLeft: var(--pf-global--spacer--sm);
  color: var(--pf-global--Color--100);
  position: relative;
  display: inline-flex;
  align-items: center;
  min-width: 0;
  padding: var(--pf-c-chip--PaddingTop) var(--pf-c-chip--PaddingRight) var(--pf-c-chip--PaddingBottom) var(--pf-c-chip--PaddingLeft);
  list-style: none;
  background-color: var(--pf-c-chip--BackgroundColor);
  border-radius: var(--pf-c-chip--BorderRadius);
}
.pf-c-chip::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  border: var(--pf-c-chip--before--BorderWidth) solid var(--pf-c-chip--before--BorderColor);
  border-radius: var(--pf-c-chip--before--BorderRadius);
}
.pf-c-chip.pf-m-overflow {
  border: 0;
}
.pf-c-chip.pf-m-overflow .pf-c-chip__text {
  color: var(--pf-c-chip--m-overflow__text--Color);
}
.pf-c-chip.pf-m-draggable {
  --pf-c-chip--BackgroundColor: var(--pf-c-chip--m-draggable--BackgroundColor);
  box-shadow: var(--pf-c-chip--m-draggable--BoxShadow);
}
.pf-c-chip.pf-m-draggable .pf-c-chip__icon {
  font-size: var(--pf-c-chip--m-draggable__icon--FontSize);
}
.pf-c-chip .pf-c-button {
  --pf-c-button--PaddingTop: var(--pf-c-chip__c-button--PaddingTop);
  --pf-c-button--PaddingRight: var(--pf-c-chip__c-button--PaddingRight);
  --pf-c-button--PaddingBottom: var(--pf-c-chip__c-button--PaddingBottom);
  --pf-c-button--PaddingLeft: var(--pf-c-chip__c-button--PaddingLeft);
  --pf-c-button--FontSize: var(--pf-c-chip__c-button--FontSize);
  margin-top: var(--pf-c-chip__c-button--MarginTop);
  margin-right: var(--pf-c-chip__c-button--MarginRight);
  margin-bottom: var(--pf-c-chip__c-button--MarginBottom);
}
.pf-c-chip .pf-c-badge {
  margin-left: var(--pf-c-chip__c-badge--MarginLeft);
}

.pf-c-chip__text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  max-width: var(--pf-c-chip__text--MaxWidth);
  font-size: var(--pf-c-chip__text--FontSize);
  color: var(--pf-c-chip__text--Color);
}

.pf-c-chip__icon + .pf-c-chip__text,
.pf-c-chip__text + .pf-c-chip__icon {
  margin-left: var(--pf-c-chip__icon--MarginLeft);
}

:where(.pf-theme-dark) .pf-c-chip {
  --pf-c-chip--BackgroundColor: var(--pf-global--BackgroundColor--100);
  --pf-c-chip--before--BorderColor: var(--pf-global--BorderColor--100);
  --pf-c-chip--m-draggable--BackgroundColor: var(--pf-global--palette--black-600);
}