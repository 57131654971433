.pf-c-banner.pf-m-warning, .pf-c-banner.pf-m-gold, .pf-c-banner.pf-m-info, .pf-c-banner.pf-m-blue {
  --pf-global--Color--100: var(--pf-global--Color--dark-100);
  --pf-global--Color--200: var(--pf-global--Color--dark-200);
  --pf-global--BorderColor--100: var(--pf-global--BorderColor--dark-100);
  --pf-global--primary-color--100: var(--pf-global--primary-color--dark-100);
  --pf-global--link--Color: var(--pf-global--link--Color--dark);
  --pf-global--link--Color--hover: var(--pf-global--link--Color--dark--hover);
  --pf-global--BackgroundColor--100: var(--pf-global--BackgroundColor--light-100);
}

.pf-c-banner {
  --pf-global--Color--100: var(--pf-global--Color--light-100);
  --pf-global--Color--200: var(--pf-global--Color--light-200);
  --pf-global--BorderColor--100: var(--pf-global--BorderColor--light-100);
  --pf-global--primary-color--100: var(--pf-global--primary-color--light-100);
  --pf-global--link--Color: var(--pf-global--link--Color--light);
  --pf-global--link--Color--hover: var(--pf-global--link--Color--light);
  --pf-global--BackgroundColor--100: var(--pf-global--BackgroundColor--dark-100);
}
.pf-c-banner .pf-c-card {
  --pf-c-card--BackgroundColor: var(--pf-global--BackgroundColor--dark-transparent-200);
}
.pf-c-banner .pf-c-button {
  --pf-c-button--m-primary--Color: var(--pf-global--primary-color--dark-100);
  --pf-c-button--m-primary--hover--Color: var(--pf-global--primary-color--dark-100);
  --pf-c-button--m-primary--focus--Color: var(--pf-global--primary-color--dark-100);
  --pf-c-button--m-primary--active--Color: var(--pf-global--primary-color--dark-100);
  --pf-c-button--m-primary--BackgroundColor: var(--pf-global--BackgroundColor--light-100);
  --pf-c-button--m-primary--hover--BackgroundColor: var(--pf-global--BackgroundColor--light-300);
  --pf-c-button--m-primary--focus--BackgroundColor: var(--pf-global--BackgroundColor--light-300);
  --pf-c-button--m-primary--active--BackgroundColor: var(--pf-global--BackgroundColor--light-300);
  --pf-c-button--m-secondary--Color: var(--pf-global--Color--light-100);
  --pf-c-button--m-secondary--hover--Color: var(--pf-global--Color--light-100);
  --pf-c-button--m-secondary--focus--Color: var(--pf-global--Color--light-100);
  --pf-c-button--m-secondary--active--Color: var(--pf-global--Color--light-100);
  --pf-c-button--m-secondary--BorderColor: var(--pf-global--Color--light-100);
  --pf-c-button--m-secondary--hover--BorderColor: var(--pf-global--Color--light-100);
  --pf-c-button--m-secondary--focus--BorderColor: var(--pf-global--Color--light-100);
  --pf-c-button--m-secondary--active--BorderColor: var(--pf-global--Color--light-100);
}

.pf-c-banner {
  --pf-c-banner--PaddingTop: var(--pf-global--spacer--xs);
  --pf-c-banner--PaddingRight: var(--pf-global--spacer--md);
  --pf-c-banner--md--PaddingRight: var(--pf-global--spacer--lg);
  --pf-c-banner--PaddingBottom: var(--pf-global--spacer--xs);
  --pf-c-banner--PaddingLeft: var(--pf-global--spacer--md);
  --pf-c-banner--md--PaddingLeft: var(--pf-global--spacer--lg);
  --pf-c-banner--FontSize: var(--pf-global--FontSize--sm);
  --pf-c-banner--Color: var(--pf-global--Color--100);
  --pf-c-banner--BackgroundColor: var(--pf-global--BackgroundColor--dark-400);
  --pf-c-banner--link--Color: var(--pf-c-banner--Color);
  --pf-c-banner--link--TextDecoration: underline;
  --pf-c-banner--link--hover--Color: var(--pf-c-banner--Color);
  --pf-c-banner--link--hover--FontWeight: var(--pf-global--FontWeight--semi-bold);
  --pf-c-banner--link--disabled--Color: var(--pf-c-banner--Color);
  --pf-c-banner--link--disabled--TextDecoration: none;
  --pf-c-banner--m-info--BackgroundColor: var(--pf-global--palette--blue-200);
  --pf-c-banner--m-danger--BackgroundColor: var(--pf-global--danger-color--100);
  --pf-c-banner--m-success--BackgroundColor: var(--pf-global--success-color--100);
  --pf-c-banner--m-warning--BackgroundColor: var(--pf-global--warning-color--100);
  --pf-c-banner--m-sticky--ZIndex: var(--pf-global--ZIndex--md);
  --pf-c-banner--m-sticky--BoxShadow: var(--pf-global--BoxShadow--md-bottom);
  color: var(--pf-global--Color--100);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: var(--pf-c-banner--PaddingTop) var(--pf-c-banner--PaddingRight) var(--pf-c-banner--PaddingBottom) var(--pf-c-banner--PaddingLeft);
  flex-shrink: 0;
  font-size: var(--pf-c-banner--FontSize);
  color: var(--pf-c-banner--Color);
  white-space: nowrap;
  background-color: var(--pf-c-banner--BackgroundColor);
}
@media (min-width: 768px) {
  .pf-c-banner {
    --pf-c-banner--PaddingRight: var(--pf-c-banner--md--PaddingRight);
    --pf-c-banner--PaddingLeft: var(--pf-c-banner--md--PaddingLeft);
  }
}
.pf-c-banner.pf-m-info, .pf-c-banner.pf-m-blue {
  color: var(--pf-global--Color--100);
  --pf-c-banner--BackgroundColor: var(--pf-c-banner--m-info--BackgroundColor);
}
.pf-c-banner.pf-m-danger, .pf-c-banner.pf-m-red {
  --pf-c-banner--BackgroundColor: var(--pf-c-banner--m-danger--BackgroundColor);
}
.pf-c-banner.pf-m-success, .pf-c-banner.pf-m-green {
  --pf-c-banner--BackgroundColor: var(--pf-c-banner--m-success--BackgroundColor);
}
.pf-c-banner.pf-m-warning, .pf-c-banner.pf-m-gold {
  color: var(--pf-global--Color--100);
  --pf-c-banner--BackgroundColor: var(--pf-c-banner--m-warning--BackgroundColor);
}
.pf-c-banner.pf-m-sticky {
  position: sticky;
  top: 0;
  z-index: var(--pf-c-banner--m-sticky--ZIndex);
  box-shadow: var(--pf-c-banner--m-sticky--BoxShadow);
}
.pf-c-banner a {
  color: var(--pf-c-banner--link--Color);
  text-decoration: var(--pf-c-banner--link--TextDecoration);
}
.pf-c-banner a:hover:not(.pf-m-disabled) {
  --pf-c-banner--link--Color: var(--pf-c-banner--link--hover--Color);
  font-weight: var(--pf-c-banner--link--hover--FontWeight);
}
.pf-c-banner a.pf-m-disabled {
  --pf-c-banner--link--Color: var(--pf-c-banner--link--disabled--Color);
  --pf-c-banner--link--TextDecoration: var(--pf-c-banner--link--disabled--TextDecoration);
  cursor: not-allowed;
}
.pf-c-banner .pf-c-button.pf-m-inline {
  --pf-c-button--m-link--Color: var(--pf-c-banner--link--Color);
  --pf-c-button--m-link--m-inline--hover--Color: var(--pf-c-banner--link--hover--Color);
  --pf-c-button--disabled--Color: var(--pf-c-banner--link--disabled--Color);
  text-decoration: var(--pf-c-banner--link--TextDecoration);
}
.pf-c-banner .pf-c-button.pf-m-inline:hover {
  --pf-c-banner--link--Color: var(--pf-c-banner--link--hover--Color);
  font-weight: var(--pf-c-banner--link--hover--FontWeight);
}
.pf-c-banner .pf-c-button.pf-m-inline:disabled, .pf-c-banner .pf-c-button.pf-m-inline.pf-m-disabled {
  --pf-c-banner--link--TextDecoration: var(--pf-c-banner--link--disabled--TextDecoration);
  cursor: not-allowed;
}

:where(.pf-theme-dark) .pf-c-banner {
  --pf-global--Color--100: #e0e0e0;
  --pf-global--Color--200: #aaabac;
  --pf-global--BorderColor--100: #444548;
  --pf-global--primary-color--100: #1fa7f8;
  --pf-global--link--Color: #1fa7f8;
  --pf-global--link--Color--hover: #73bcf7;
  --pf-global--BackgroundColor--100: #1b1d21;
}
:where(.pf-theme-dark) .pf-c-banner .pf-c-card {
  --pf-c-card--BackgroundColor: var(--pf-global--BackgroundColor--100);
}
:where(.pf-theme-dark) .pf-c-banner .pf-c-button {
  --pf-c-button--m-primary--Color: var(--pf-global--Color--light-100);
  --pf-c-button--m-primary--hover--Color: var(--pf-global--Color--light-100);
  --pf-c-button--m-primary--focus--Color: var(--pf-global--Color--light-100);
  --pf-c-button--m-primary--active--Color: var(--pf-global--Color--light-100);
  --pf-c-button--m-primary--BackgroundColor: var(--pf-global--primary-color--300);
  --pf-c-button--m-primary--hover--BackgroundColor: var(--pf-global--primary-color--200);
  --pf-c-button--m-primary--focus--BackgroundColor: var(--pf-global--primary-color--200);
  --pf-c-button--m-primary--active--BackgroundColor: var(--pf-global--primary-color--200);
  --pf-c-button--m-secondary--Color: var(--pf-global--primary-color--100);
  --pf-c-button--m-secondary--hover--Color: var(--pf-global--primary-color--100);
  --pf-c-button--m-secondary--focus--Color: var(--pf-global--primary-color--100);
  --pf-c-button--m-secondary--active--Color: var(--pf-global--primary-color--100);
}

:where(.pf-theme-dark) .pf-c-banner {
  color: var(--pf-global--Color--100);
}
:where(.pf-theme-dark) .pf-c-banner.pf-m-info, :where(.pf-theme-dark) .pf-c-banner.pf-m-blue, :where(.pf-theme-dark) .pf-c-banner.pf-m-danger, :where(.pf-theme-dark) .pf-c-banner.pf-m-red, :where(.pf-theme-dark) .pf-c-banner.pf-m-success, :where(.pf-theme-dark) .pf-c-banner.pf-m-green, :where(.pf-theme-dark) .pf-c-banner.pf-m-warning, :where(.pf-theme-dark) .pf-c-banner.pf-m-gold {
  color: var(--pf-global--palette--black-900);
}