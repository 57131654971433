.pf-c-card {
  --pf-c-card--BackgroundColor: var(--pf-global--BackgroundColor--100);
  --pf-c-card--BoxShadow: var(--pf-global--BoxShadow--sm);
  --pf-c-card--first-child--PaddingTop: var(--pf-global--spacer--lg);
  --pf-c-card--child--PaddingRight: var(--pf-global--spacer--lg);
  --pf-c-card--child--PaddingBottom: var(--pf-global--spacer--lg);
  --pf-c-card--child--PaddingLeft: var(--pf-global--spacer--lg);
  --pf-c-card--c-divider--child--PaddingTop: var(--pf-global--spacer--lg);
  --pf-c-card__title--FontFamily: var(--pf-global--FontFamily--heading--sans-serif);
  --pf-c-card__title--FontSize: var(--pf-global--FontSize--md);
  --pf-c-card__title--FontWeight: var(--pf-global--FontWeight--bold);
  --pf-c-card__title--not--last-child--PaddingBottom: var(--pf-global--spacer--md);
  --pf-c-card__body--FontSize: var(--pf-global--FontSize--md);
  --pf-c-card__footer--FontSize: var(--pf-global--FontSize--md);
  --pf-c-card__actions--PaddingLeft: var(--pf-global--spacer--md);
  --pf-c-card__actions--child--MarginLeft: var(--pf-global--spacer--sm);
  --pf-c-card__header-toggle--MarginTop: calc(var(--pf-global--spacer--form-element) * -1);
  --pf-c-card__header-toggle--MarginRight: var(--pf-global--spacer--xs);
  --pf-c-card__header-toggle--MarginBottom: calc(var(--pf-global--spacer--form-element) * -1);
  --pf-c-card__header-toggle--MarginLeft: calc(var(--pf-global--spacer--md) * -1);
  --pf-c-card__header-toggle-icon--Transition: var(--pf-global--Transition);
  --pf-c-card--m-expanded__header-toggle-icon--Rotate: 90deg;
  --pf-c-card--m-hoverable--hover--BoxShadow: var(--pf-global--BoxShadow--lg);
  --pf-c-card--m-selectable--hover--BoxShadow: var(--pf-global--BoxShadow--lg);
  --pf-c-card--m-selectable--focus--BoxShadow: var(--pf-global--BoxShadow--lg);
  --pf-c-card--m-selectable--active--BoxShadow: var(--pf-global--BoxShadow--lg);
  --pf-c-card--m-selectable--m-selected--BoxShadow: var(--pf-global--BoxShadow--lg);
  --pf-c-card--m-selectable--m-selected--before--Height: var(--pf-global--BorderWidth--lg);
  --pf-c-card--m-selectable--m-selected--before--BackgroundColor: var(--pf-global--active-color--100);
  --pf-c-card--m-hoverable-raised--hover--BoxShadow: var(--pf-global--BoxShadow--md);
  --pf-c-card--m-hoverable-raised--hover--before--BackgroundColor: var(--pf-global--active-color--400);
  --pf-c-card--m-selectable-raised--before--Right: 0;
  --pf-c-card--m-selectable-raised--before--Bottom: 0;
  --pf-c-card--m-selectable-raised--before--Left: 0;
  --pf-c-card--m-flat--m-selectable-raised--before--Right: calc(-1 * var(--pf-c-card--m-flat--BorderWidth));
  --pf-c-card--m-flat--m-selectable-raised--before--Bottom: calc(-1 * var(--pf-c-card--m-flat--BorderWidth));
  --pf-c-card--m-flat--m-selectable-raised--before--Left: calc(-1 * var(--pf-c-card--m-flat--BorderWidth));
  --pf-c-card--m-selectable-raised--before--Height: var(--pf-global--BorderWidth--xl);
  --pf-c-card--m-selectable-raised--before--BackgroundColor: transparent;
  --pf-c-card--m-selectable-raised--before--Transition: none;
  --pf-c-card--m-selectable-raised--before--ScaleY: 1;
  --pf-c-card--m-selectable-raised--before--TranslateY: 0;
  --pf-c-card--m-selectable-raised--hover--BoxShadow: var(--pf-global--BoxShadow--md);
  --pf-c-card--m-selectable-raised--hover--before--BackgroundColor: var(--pf-global--active-color--400);
  --pf-c-card--m-selectable-raised--focus--BoxShadow: var(--pf-global--BoxShadow--md);
  --pf-c-card--m-selectable-raised--focus--before--BackgroundColor: var(--pf-global--active-color--400);
  --pf-c-card--m-selectable-raised--active--BoxShadow: var(--pf-global--BoxShadow--md);
  --pf-c-card--m-selectable-raised--active--before--BackgroundColor: var(--pf-global--active-color--400);
  --pf-c-card--m-selectable-raised--m-selected-raised--before--BackgroundColor: var(--pf-global--active-color--100);
  --pf-c-card--m-selectable-raised--m-selected-raised--BoxShadow: var(--pf-global--BoxShadow--lg);
  --pf-c-card--m-selectable-raised--m-selected-raised--TranslateY--base: -0.5rem;
  --pf-c-card--m-selectable-raised--m-selected-raised--TranslateY: var(--pf-c-card--m-selectable-raised--m-selected-raised--TranslateY--base);
  --pf-c-card--m-flat--m-selectable-raised--m-selected-raised--TranslateY: calc(var(--pf-c-card--m-selectable-raised--m-selected-raised--TranslateY--base) + var(--pf-c-card--m-flat--BorderWidth));
  --pf-c-card--m-rounded--m-selectable-raised--m-selected-raised--TranslateY: calc(var(--pf-c-card--m-selectable-raised--m-selected-raised--TranslateY--base) + var(--pf-c-card--m-rounded--BorderRadius));
  --pf-c-card--m-selectable-raised--m-selected-raised--ZIndex: var(--pf-global--ZIndex--xs);
  --pf-c-card--m-selectable-raised--m-selected-raised--Transition: transform .25s linear, box-shadow .25s linear;
  --pf-c-card--m-selectable-raised--m-selected-raised--before--Transition: transform .25s linear;
  --pf-c-card--m-selectable-raised--m-selected-raised--before--TranslateY: calc(var(--pf-c-card--m-selectable-raised--m-selected-raised--TranslateY) * -1);
  --pf-c-card--m-selectable-raised--m-selected-raised--before--ScaleY: 2;
  --pf-c-card--m-non-selectable-raised--BackgroundColor: var(--pf-global--BackgroundColor--light-200);
  --pf-c-card--m-non-selectable-raised--before--BackgroundColor: var(--pf-global--disabled-color--200);
  --pf-c-card--m-non-selectable-raised--before--ScaleY: 2;
  --pf-c-card--m-flat--m-non-selectable-raised--before--BorderColor: var(--pf-global--disabled-color--200);
  --pf-c-card--m-compact__body--FontSize: var(--pf-global--FontSize--sm);
  --pf-c-card--m-compact__footer--FontSize: var(--pf-global--FontSize--sm);
  --pf-c-card--m-compact--first-child--PaddingTop: var(--pf-global--spacer--md);
  --pf-c-card--m-compact--child--PaddingRight: var(--pf-global--spacer--md);
  --pf-c-card--m-compact--child--PaddingBottom: var(--pf-global--spacer--md);
  --pf-c-card--m-compact--child--PaddingLeft: var(--pf-global--spacer--md);
  --pf-c-card--m-compact--c-divider--child--PaddingTop: var(--pf-global--spacer--md);
  --pf-c-card--m-compact__title--not--last-child--PaddingBottom: var(--pf-global--spacer--sm);
  --pf-c-card--m-display-lg__title--FontSize: var(--pf-global--FontSize--xl);
  --pf-c-card--m-display-lg--first-child--PaddingTop: var(--pf-global--spacer--xl);
  --pf-c-card--m-display-lg--child--PaddingRight: var(--pf-global--spacer--xl);
  --pf-c-card--m-display-lg--child--PaddingBottom: var(--pf-global--spacer--xl);
  --pf-c-card--m-display-lg--child--PaddingLeft: var(--pf-global--spacer--xl);
  --pf-c-card--m-display-lg--c-divider--child--PaddingTop: var(--pf-global--spacer--xl);
  --pf-c-card--m-display-lg__title--not--last-child--PaddingBottom: var(--pf-global--spacer--lg);
  --pf-c-card--m-flat--BorderWidth: var(--pf-global--BorderWidth--sm);
  --pf-c-card--m-flat--BorderColor: var(--pf-global--BorderColor--100);
  --pf-c-card--m-rounded--BorderRadius: var(--pf-global--BorderRadius--sm);
  --pf-c-card--m-full-height--Height: 100%;
  --pf-c-card--m-plain--BoxShadow: none;
  --pf-c-card--m-plain--BackgroundColor: transparent;
  --pf-c-card__header--m-toggle-right--toggle--MarginRight: calc(var(--pf-global--spacer--form-element) * -1);
  --pf-c-card__header--m-toggle-right--toggle--MarginLeft: var(--pf-global--spacer--xs);
  --pf-c-card__header--m-toggle-right--actions--MarginRight: 0;
  --pf-c-card__input--focus--BorderWidth: var(--pf-global--BorderWidth--md);
  --pf-c-card__input--focus--BorderColor: var(--pf-global--primary-color--100);
  display: flex;
  flex-direction: column;
  background-color: var(--pf-c-card--BackgroundColor);
  box-shadow: var(--pf-c-card--BoxShadow);
}
.pf-c-card.pf-m-hoverable:hover {
  box-shadow: var(--pf-c-card--m-hoverable--hover--BoxShadow);
}
.pf-c-card.pf-m-selectable {
  position: relative;
  cursor: pointer;
}
.pf-c-card.pf-m-selectable:hover {
  box-shadow: var(--pf-c-card--m-selectable--hover--BoxShadow);
}
.pf-c-card.pf-m-selectable:focus {
  box-shadow: var(--pf-c-card--m-selectable--focus--BoxShadow);
}
.pf-c-card.pf-m-selectable:active {
  box-shadow: var(--pf-c-card--m-selectable--active--BoxShadow);
}
.pf-c-card.pf-m-selectable.pf-m-selected {
  box-shadow: var(--pf-c-card--m-selectable--m-selected--BoxShadow);
}
.pf-c-card.pf-m-selectable.pf-m-selected::before {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: var(--pf-c-card--m-selectable--m-selected--before--Height);
  content: "";
  background-color: var(--pf-c-card--m-selectable--m-selected--before--BackgroundColor);
}
.pf-c-card.pf-m-hoverable-raised, .pf-c-card.pf-m-selectable-raised, .pf-c-card.pf-m-non-selectable-raised {
  position: relative;
}
.pf-c-card.pf-m-hoverable-raised::before, .pf-c-card.pf-m-selectable-raised::before, .pf-c-card.pf-m-non-selectable-raised::before {
  position: absolute;
  right: var(--pf-c-card--m-selectable-raised--before--Right);
  bottom: var(--pf-c-card--m-selectable-raised--before--Bottom);
  left: var(--pf-c-card--m-selectable-raised--before--Left);
  height: var(--pf-c-card--m-selectable-raised--before--Height);
  content: "";
  background-color: var(--pf-c-card--m-selectable-raised--before--BackgroundColor);
  transition: var(--pf-c-card--m-selectable-raised--before--Transition);
  transform: translateY(var(--pf-c-card--m-selectable-raised--before--TranslateY)) scaleY(var(--pf-c-card--m-selectable-raised--before--ScaleY));
  transform-origin: center bottom;
}
.pf-c-card.pf-m-hoverable-raised:hover {
  --pf-c-card--BoxShadow: var(--pf-c-card--m-hoverable-raised--hover--BoxShadow);
  --pf-c-card--m-selectable-raised--before--BackgroundColor: var(--pf-c-card--m-hoverable-raised--hover--before--BackgroundColor);
}
.pf-c-card.pf-m-selectable-raised {
  cursor: pointer;
}
.pf-c-card.pf-m-selectable-raised:hover {
  --pf-c-card--BoxShadow: var(--pf-c-card--m-selectable-raised--hover--BoxShadow);
  --pf-c-card--m-selectable-raised--before--BackgroundColor: var(--pf-c-card--m-selectable-raised--hover--before--BackgroundColor);
}
.pf-c-card.pf-m-selectable-raised:focus {
  --pf-c-card--BoxShadow: var(--pf-c-card--m-selectable-raised--focus--BoxShadow);
  --pf-c-card--m-selectable-raised--before--BackgroundColor: var(--pf-c-card--m-selectable-raised--focus--before--BackgroundColor);
}
.pf-c-card.pf-m-selectable-raised:active {
  --pf-c-card--BoxShadow: var(--pf-c-card--m-selectable-raised--active--BoxShadow);
  --pf-c-card--m-selectable-raised--before--BackgroundColor: var(--pf-c-card--m-selectable-raised--active--before--BackgroundColor);
}
.pf-c-card.pf-m-selectable-raised.pf-m-selected-raised {
  --pf-c-card--m-selectable-raised--before--BackgroundColor: var(--pf-c-card--m-selectable-raised--m-selected-raised--before--BackgroundColor);
  --pf-c-card--m-selectable-raised--before--Transition: var(--pf-c-card--m-selectable-raised--m-selected-raised--before--Transition);
  --pf-c-card--m-selectable-raised--before--TranslateY: var(--pf-c-card--m-selectable-raised--m-selected-raised--before--TranslateY);
  --pf-c-card--m-selectable-raised--before--ScaleY: var(--pf-c-card--m-selectable-raised--m-selected-raised--before--ScaleY);
  z-index: var(--pf-c-card--m-selectable-raised--m-selected-raised--ZIndex);
  box-shadow: var(--pf-c-card--m-selectable-raised--m-selected-raised--BoxShadow);
  transition: var(--pf-c-card--m-selectable-raised--m-selected-raised--Transition);
  transform: translateY(var(--pf-c-card--m-selectable-raised--m-selected-raised--TranslateY));
}
.pf-c-card.pf-m-non-selectable-raised {
  --pf-c-card--BackgroundColor: var(--pf-c-card--m-non-selectable-raised--BackgroundColor);
  --pf-c-card--BoxShadow: none;
  --pf-c-card--m-flat--BorderColor: var(--pf-c-card--m-flat--m-non-selectable-raised--before--BorderColor);
  --pf-c-card--m-selectable-raised--before--BackgroundColor: var(--pf-c-card--m-non-selectable-raised--before--BackgroundColor);
  --pf-c-card--m-selectable-raised--before--ScaleY: var(--pf-c-card--m-non-selectable-raised--before--ScaleY);
}
.pf-c-card.pf-m-compact {
  --pf-c-card__body--FontSize: var(--pf-c-card--m-compact__body--FontSize);
  --pf-c-card__footer--FontSize: var(--pf-c-card--m-compact__footer--FontSize);
  --pf-c-card--first-child--PaddingTop: var(--pf-c-card--m-compact--first-child--PaddingTop);
  --pf-c-card--child--PaddingRight: var(--pf-c-card--m-compact--child--PaddingRight);
  --pf-c-card--child--PaddingBottom: var(--pf-c-card--m-compact--child--PaddingBottom);
  --pf-c-card--child--PaddingLeft: var(--pf-c-card--m-compact--child--PaddingLeft);
  --pf-c-card--c-divider--child--PaddingTop: var(--pf-c-card--m-compact--c-divider--child--PaddingTop);
  --pf-c-card__title--not--last-child--PaddingBottom: var(--pf-c-card--m-compact__title--not--last-child--PaddingBottom);
}
.pf-c-card.pf-m-display-lg {
  --pf-c-card__title--FontSize: var(--pf-c-card--m-display-lg__title--FontSize);
  --pf-c-card--first-child--PaddingTop: var(--pf-c-card--m-display-lg--first-child--PaddingTop);
  --pf-c-card--child--PaddingRight: var(--pf-c-card--m-display-lg--child--PaddingRight);
  --pf-c-card--child--PaddingBottom: var(--pf-c-card--m-display-lg--child--PaddingBottom);
  --pf-c-card--child--PaddingLeft: var(--pf-c-card--m-display-lg--child--PaddingLeft);
  --pf-c-card--c-divider--child--PaddingTop: var(--pf-c-card--m-display-lg--c-divider--child--PaddingTop);
  --pf-c-card__title--not--last-child--PaddingBottom: var(--pf-c-card--m-display-lg__title--not--last-child--PaddingBottom);
}
.pf-c-card.pf-m-flat {
  --pf-c-card--BoxShadow: none;
  --pf-c-card--m-selectable-raised--before--Right: var(--pf-c-card--m-flat--m-selectable-raised--before--Right);
  --pf-c-card--m-selectable-raised--before--Bottom: var(--pf-c-card--m-flat--m-selectable-raised--before--Bottom);
  --pf-c-card--m-selectable-raised--before--Left: var(--pf-c-card--m-flat--m-selectable-raised--before--Left);
  --pf-c-card--m-selectable-raised--m-selected-raised--TranslateY: var(--pf-c-card--m-flat--m-selectable-raised--m-selected-raised--TranslateY);
  border: var(--pf-c-card--m-flat--BorderWidth) solid var(--pf-c-card--m-flat--BorderColor);
}
.pf-c-card.pf-m-plain {
  --pf-c-card--BoxShadow: var(--pf-c-card--m-plain--BoxShadow);
  --pf-c-card--BackgroundColor: var(--pf-c-card--m-plain--BackgroundColor);
}
.pf-c-card.pf-m-rounded {
  --pf-c-card--m-selectable-raised--m-selected-raised--TranslateY: var(--pf-c-card--m-rounded--m-selectable-raised--m-selected-raised--TranslateY);
  border-radius: var(--pf-c-card--m-rounded--BorderRadius);
}
.pf-c-card.pf-m-expanded .pf-c-card__header-toggle-icon {
  transform: rotate(var(--pf-c-card--m-expanded__header-toggle-icon--Rotate));
}
.pf-c-card.pf-m-full-height {
  height: var(--pf-c-card--m-full-height--Height);
}
.pf-c-card > .pf-c-divider + .pf-c-card__header, .pf-c-card > .pf-c-divider + .pf-c-card__title, .pf-c-card > .pf-c-divider + .pf-c-card__body, .pf-c-card > .pf-c-divider + .pf-c-card__footer {
  padding-top: var(--pf-c-card--c-divider--child--PaddingTop);
}

.pf-c-card__header {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.pf-c-card__header .pf-c-card__title {
  --pf-c-card--first-child--PaddingTop: 0;
  --pf-c-card__title--not--last-child--PaddingBottom: 0;
  padding: 0;
}
.pf-c-card__header.pf-m-toggle-right {
  --pf-c-card__header-toggle--MarginRight: var(--pf-c-card__header--m-toggle-right--toggle--MarginRight);
  --pf-c-card__header-toggle--MarginLeft: var(--pf-c-card__header--m-toggle-right--toggle--MarginLeft);
}
.pf-c-card__header.pf-m-toggle-right .pf-c-card__actions {
  --pf-c-card__header-toggle--MarginRight: var(--pf-c-card__header--m-toggle-right--actions--MarginRight);
}
.pf-c-card__header.pf-m-toggle-right .pf-c-card__header-toggle {
  order: 2;
}
.pf-c-card__header.pf-m-toggle-right .pf-c-card__title {
  flex: 1;
}

.pf-c-card__header-toggle {
  align-self: flex-start;
  margin: var(--pf-c-card__header-toggle--MarginTop) var(--pf-c-card__header-toggle--MarginRight) var(--pf-c-card__header-toggle--MarginBottom) var(--pf-c-card__header-toggle--MarginLeft);
}

.pf-c-card__header-toggle-icon {
  display: inline-block;
  transition: var(--pf-c-card__header-toggle-icon--Transition);
}

.pf-c-card__title {
  font-family: var(--pf-c-card__title--FontFamily);
  font-size: var(--pf-c-card__title--FontSize);
  font-weight: var(--pf-c-card__title--FontWeight);
}

.pf-c-card__actions {
  display: flex;
  align-items: center;
  align-self: flex-start;
  order: 1;
  padding-left: var(--pf-c-card__actions--PaddingLeft);
  margin: var(--pf-c-card__header-toggle--MarginTop) var(--pf-c-card__header-toggle--MarginRight) var(--pf-c-card__header-toggle--MarginBottom) auto;
}
.pf-c-card__actions > * + * {
  margin-left: var(--pf-c-card__actions--child--MarginLeft);
}
.pf-c-card__actions + .pf-c-card__title,
.pf-c-card__actions + .pf-c-card__body,
.pf-c-card__actions + .pf-c-card__footer {
  padding: 0;
}
.pf-c-card__actions.pf-m-no-offset {
  --pf-c-card__header-toggle--MarginTop: 0;
  --pf-c-card__header-toggle--MarginBottom: 0;
}

.pf-c-card__header,
.pf-c-card__title,
.pf-c-card__body,
.pf-c-card__footer {
  padding-right: var(--pf-c-card--child--PaddingRight);
  padding-bottom: var(--pf-c-card--child--PaddingBottom);
  padding-left: var(--pf-c-card--child--PaddingLeft);
}
.pf-c-card__header:first-child,
.pf-c-card__title:first-child,
.pf-c-card__body:first-child,
.pf-c-card__footer:first-child {
  padding-top: var(--pf-c-card--first-child--PaddingTop);
}

.pf-c-card__header:not(:last-child),
.pf-c-card__title:not(:last-child) {
  padding-bottom: var(--pf-c-card__title--not--last-child--PaddingBottom);
}

.pf-c-card__expandable-content {
  --pf-c-card--first-child--PaddingTop: 0;
}

.pf-c-card__body:not(.pf-m-no-fill) {
  flex: 1 1 auto;
}

.pf-c-card__body {
  font-size: var(--pf-c-card__body--FontSize);
}

.pf-c-card__footer {
  font-size: var(--pf-c-card__footer--FontSize);
}

.pf-c-card__sr-input:focus + .pf-c-card::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  border: var(--pf-c-card__input--focus--BorderWidth) solid var(--pf-c-card__input--focus--BorderColor);
}

.pf-m-overpass-font .pf-c-card .pf-c-card__title {
  font-weight: var(--pf-global--FontWeight--normal);
}

:where(.pf-theme-dark) .pf-c-card,
:where(.pf-theme-dark) .pf-c-card.pf-m-non-selectable-raised {
  --pf-c-card--BoxShadow: var(--pf-global--BoxShadow--md);
  --pf-c-card--m-hoverable-raised--hover--BoxShadow: var(--pf-global--BoxShadow--lg);
  --pf-c-card--m-selectable-raised--hover--BoxShadow: var(--pf-global--BoxShadow--lg);
  --pf-c-card--m-selectable-raised--focus--BoxShadow: var(--pf-global--BoxShadow--lg);
  --pf-c-card--m-selectable-raised--active--BoxShadow: var(--pf-global--BoxShadow--lg);
}