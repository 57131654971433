.pf-c-alert {
  --pf-global--Color--100: var(--pf-global--Color--dark-100);
  --pf-global--Color--200: var(--pf-global--Color--dark-200);
  --pf-global--BorderColor--100: var(--pf-global--BorderColor--dark-100);
  --pf-global--primary-color--100: var(--pf-global--primary-color--dark-100);
  --pf-global--link--Color: var(--pf-global--link--Color--dark);
  --pf-global--link--Color--hover: var(--pf-global--link--Color--dark--hover);
  --pf-global--BackgroundColor--100: var(--pf-global--BackgroundColor--light-100);
}

.pf-c-alert {
  --pf-c-alert--BoxShadow: var(--pf-global--BoxShadow--lg);
  --pf-c-alert--BackgroundColor: var(--pf-global--BackgroundColor--100);
  --pf-c-alert--GridTemplateColumns: max-content 1fr max-content;
  --pf-c-alert--GridTemplateAreas:
    "icon title action"
    ". description description"
    ". actiongroup actiongroup";
  --pf-c-alert--BorderTopWidth: var(--pf-global--BorderWidth--md);
  --pf-c-alert--BorderTopColor: var(--pf-global--default-color--200);
  --pf-c-alert--PaddingTop: var(--pf-global--spacer--md);
  --pf-c-alert--PaddingRight: var(--pf-global--spacer--md);
  --pf-c-alert--PaddingBottom: var(--pf-global--spacer--md);
  --pf-c-alert--PaddingLeft: var(--pf-global--spacer--md);
  --pf-c-alert__FontSize: var(--pf-global--FontSize--sm);
  --pf-c-alert__toggle--MarginTop: calc(-1 * var(--pf-global--spacer--form-element) - 0.0625rem);
  --pf-c-alert__toggle--MarginBottom: calc(-1 * var(--pf-global--spacer--form-element));
  --pf-c-alert__toggle--MarginLeft: calc(-1 * var(--pf-global--spacer--md));
  --pf-c-alert__toggle-icon--Rotate: 0;
  --pf-c-alert__toggle-icon--Transition: var(--pf-global--Transition);
  --pf-c-alert__icon--Color: var(--pf-global--default-color--200);
  --pf-c-alert__icon--MarginTop: 0.0625rem;
  --pf-c-alert__icon--MarginRight: var(--pf-global--spacer--sm);
  --pf-c-alert__icon--FontSize: var(--pf-global--icon--FontSize--md);
  --pf-c-alert__title--FontWeight: var(--pf-global--FontWeight--bold);
  --pf-c-alert__title--Color: var(--pf-global--default-color--300);
  --pf-c-alert__title--max-lines: 1;
  --pf-c-alert__action--MarginTop: calc(var(--pf-global--spacer--form-element) * -1);
  --pf-c-alert__action--MarginBottom: calc(var(--pf-global--spacer--form-element) * -1);
  --pf-c-alert__action--TranslateY: 0.125rem;
  --pf-c-alert__action--MarginRight: calc(var(--pf-global--spacer--sm) * -1);
  --pf-c-alert__description--PaddingTop: var(--pf-global--spacer--xs);
  --pf-c-alert__action-group--PaddingTop-base: var(--pf-global--spacer--xs);
  --pf-c-alert__action-group--PaddingTop: var(--pf-c-alert__action-group--PaddingTop-base);
  --pf-c-alert__description--action-group--PaddingTop-base: var(--pf-global--spacer--md);
  --pf-c-alert__description--action-group--PaddingTop: var(--pf-c-alert__description--action-group--PaddingTop-base);
  --pf-c-alert__action-group__c-button--not-last-child--MarginRight: var(--pf-global--spacer--lg);
  --pf-c-alert--m-success--BorderTopColor: var(--pf-global--success-color--100);
  --pf-c-alert--m-success__icon--Color: var(--pf-global--success-color--100);
  --pf-c-alert--m-success__title--Color: var(--pf-global--success-color--200);
  --pf-c-alert--m-danger--BorderTopColor: var(--pf-global--danger-color--100);
  --pf-c-alert--m-danger__icon--Color: var(--pf-global--danger-color--100);
  --pf-c-alert--m-danger__title--Color: var(--pf-global--danger-color--200);
  --pf-c-alert--m-warning--BorderTopColor: var(--pf-global--warning-color--100);
  --pf-c-alert--m-warning__icon--Color: var(--pf-global--warning-color--100);
  --pf-c-alert--m-warning__title--Color: var(--pf-global--warning-color--200);
  --pf-c-alert--m-info--BorderTopColor: var(--pf-global--info-color--100);
  --pf-c-alert--m-info__icon--Color: var(--pf-global--info-color--100);
  --pf-c-alert--m-info__title--Color: var(--pf-global--info-color--200);
  --pf-c-alert--m-inline--BoxShadow: none;
  --pf-c-alert--m-inline--BackgroundColor: var(--pf-global--palette--cyan-50);
  --pf-c-alert--m-inline--m-success--BackgroundColor: var(--pf-global--palette--green-50);
  --pf-c-alert--m-inline--m-danger--BackgroundColor: var(--pf-global--palette--red-50);
  --pf-c-alert--m-inline--m-warning--BackgroundColor: var(--pf-global--palette--gold-50);
  --pf-c-alert--m-inline--m-info--BackgroundColor: var(--pf-global--palette--blue-50);
  --pf-c-alert--m-inline--m-plain--BorderTopWidth: 0;
  --pf-c-alert--m-inline--m-plain--BackgroundColor: transparent;
  --pf-c-alert--m-inline--m-plain--PaddingTop: 0;
  --pf-c-alert--m-inline--m-plain--PaddingRight: 0;
  --pf-c-alert--m-inline--m-plain--PaddingBottom: 0;
  --pf-c-alert--m-inline--m-plain--PaddingLeft: 0;
  --pf-c-alert--m-expandable--GridTemplateColumns: auto max-content 1fr max-content;
  --pf-c-alert--m-expandable--GridTemplateAreas:
    "toggle icon title action"
    ". . description description"
    ". . actiongroup actiongroup";
  --pf-c-alert--m-expandable__description--action-group--PaddingTop: var(--pf-c-alert__action-group--PaddingTop-base);
  --pf-c-alert--m-expanded__toggle-icon--Rotate: 90deg;
  --pf-c-alert--m-expanded__description--action-group--PaddingTop: var(--pf-c-alert__description--action-group--PaddingTop-base);
  color: var(--pf-global--Color--100);
  position: relative;
  display: grid;
  padding: var(--pf-c-alert--PaddingTop) var(--pf-c-alert--PaddingRight) var(--pf-c-alert--PaddingBottom) var(--pf-c-alert--PaddingLeft);
  font-size: var(--pf-c-alert__FontSize);
  background-color: var(--pf-c-alert--BackgroundColor);
  border-top: var(--pf-c-alert--BorderTopWidth) solid var(--pf-c-alert--BorderTopColor);
  box-shadow: var(--pf-c-alert--BoxShadow);
  grid-template-columns: var(--pf-c-alert--GridTemplateColumns);
  grid-template-areas: var(--pf-c-alert--GridTemplateAreas);
}
.pf-c-alert.pf-m-success {
  --pf-c-alert--BorderTopColor: var(--pf-c-alert--m-success--BorderTopColor);
  --pf-c-alert__icon--Color: var(--pf-c-alert--m-success__icon--Color);
  --pf-c-alert__title--Color: var(--pf-c-alert--m-success__title--Color);
  --pf-c-alert--m-inline--BackgroundColor: var(--pf-c-alert--m-inline--m-success--BackgroundColor);
}
.pf-c-alert.pf-m-danger {
  --pf-c-alert--BorderTopColor: var(--pf-c-alert--m-danger--BorderTopColor);
  --pf-c-alert__icon--Color: var(--pf-c-alert--m-danger__icon--Color);
  --pf-c-alert__title--Color: var(--pf-c-alert--m-danger__title--Color);
  --pf-c-alert--m-inline--BackgroundColor: var(--pf-c-alert--m-inline--m-danger--BackgroundColor);
}
.pf-c-alert.pf-m-warning {
  --pf-c-alert--BorderTopColor: var(--pf-c-alert--m-warning--BorderTopColor);
  --pf-c-alert__icon--Color: var(--pf-c-alert--m-warning__icon--Color);
  --pf-c-alert__title--Color: var(--pf-c-alert--m-warning__title--Color);
  --pf-c-alert--m-inline--BackgroundColor: var(--pf-c-alert--m-inline--m-warning--BackgroundColor);
}
.pf-c-alert.pf-m-info {
  --pf-c-alert--BorderTopColor: var(--pf-c-alert--m-info--BorderTopColor);
  --pf-c-alert__icon--Color: var(--pf-c-alert--m-info__icon--Color);
  --pf-c-alert__title--Color: var(--pf-c-alert--m-info__title--Color);
  --pf-c-alert--m-inline--BackgroundColor: var(--pf-c-alert--m-inline--m-info--BackgroundColor);
}
.pf-c-alert.pf-m-inline {
  --pf-c-alert--BoxShadow: var(--pf-c-alert--m-inline--BoxShadow);
  --pf-c-alert--BackgroundColor: var(--pf-c-alert--m-inline--BackgroundColor);
}
.pf-c-alert.pf-m-plain {
  --pf-c-alert--BorderTopWidth: var(--pf-c-alert--m-inline--m-plain--BorderTopWidth);
  --pf-c-alert--BackgroundColor: var(--pf-c-alert--m-inline--m-plain--BackgroundColor);
  --pf-c-alert--PaddingTop: var(--pf-c-alert--m-inline--m-plain--PaddingTop);
  --pf-c-alert--PaddingRight: var(--pf-c-alert--m-inline--m-plain--PaddingRight);
  --pf-c-alert--PaddingBottom: var(--pf-c-alert--m-inline--m-plain--PaddingBottom);
  --pf-c-alert--PaddingLeft: var(--pf-c-alert--m-inline--m-plain--PaddingLeft);
}
.pf-c-alert.pf-m-expandable {
  --pf-c-alert--GridTemplateColumns: var(--pf-c-alert--m-expandable--GridTemplateColumns);
  --pf-c-alert--GridTemplateAreas: var(--pf-c-alert--m-expandable--GridTemplateAreas);
  --pf-c-alert__description--action-group--PaddingTop: var(--pf-c-alert--m-expandable__description--action-group--PaddingTop);
}
.pf-c-alert.pf-m-expanded {
  --pf-c-alert__toggle-icon--Rotate: var(--pf-c-alert--m-expanded__toggle-icon--Rotate);
  --pf-c-alert__description--action-group--PaddingTop: var(--pf-c-alert--m-expanded__description--action-group--PaddingTop);
}

.pf-c-alert__toggle {
  margin-top: var(--pf-c-alert__toggle--MarginTop);
  margin-bottom: var(--pf-c-alert__toggle--MarginBottom);
  margin-left: var(--pf-c-alert__toggle--MarginLeft);
}

.pf-c-alert__toggle-icon {
  display: inline-block;
  transition: var(--pf-c-alert__toggle-icon--Transition);
  transform: rotate(var(--pf-c-alert__toggle-icon--Rotate));
}

.pf-c-alert__icon {
  grid-area: icon;
  display: flex;
  margin-top: var(--pf-c-alert__icon--MarginTop);
  margin-right: var(--pf-c-alert__icon--MarginRight);
  font-size: var(--pf-c-alert__icon--FontSize);
  color: var(--pf-c-alert__icon--Color);
}

.pf-c-alert__title {
  grid-area: title;
  font-weight: var(--pf-c-alert__title--FontWeight);
  color: var(--pf-c-alert__title--Color);
  word-break: break-word;
}
.pf-c-alert__title.pf-m-truncate {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: var(--pf-c-alert__title--max-lines);
  overflow: hidden;
}

.pf-c-alert__description {
  grid-area: description;
  padding-top: var(--pf-c-alert__description--PaddingTop);
  word-break: break-word;
}
.pf-c-alert__description + .pf-c-alert__action-group {
  --pf-c-alert__action-group--PaddingTop: var(--pf-c-alert__description--action-group--PaddingTop);
}

.pf-c-alert__action {
  grid-area: action;
  margin-top: var(--pf-c-alert__action--MarginTop);
  margin-right: var(--pf-c-alert__action--MarginRight);
  margin-bottom: var(--pf-c-alert__action--MarginBottom);
  transform: translateY(var(--pf-c-alert__action--TranslateY));
}
.pf-c-alert__action > .pf-c-button {
  --pf-c-button--LineHeight: 1;
}

.pf-c-alert__action-group {
  grid-area: actiongroup;
  padding-top: var(--pf-c-alert__action-group--PaddingTop);
}
.pf-c-alert__action-group > .pf-c-button {
  --pf-c-button--m-link--m-inline--hover--TextDecoration: none;
}
.pf-c-alert__action-group > .pf-c-button:not(:last-child) {
  margin-right: var(--pf-c-alert__action-group__c-button--not-last-child--MarginRight);
}

.pf-m-overpass-font .pf-c-alert__title {
  --pf-c-alert__title--FontWeight: var(--pf-global--FontWeight--normal);
}

:where(.pf-theme-dark) .pf-c-alert {
  --pf-c-alert--BackgroundColor: var(--pf-global--palette--black-600);
  --pf-c-alert__title--Color: var(--pf-global--default-color--200);
  --pf-c-alert--m-success__title--Color: var(--pf-global--success-color--100);
  --pf-c-alert--m-danger__title--Color: var(--pf-global--danger-color--100);
  --pf-c-alert--m-warning__title--Color: var(--pf-global--warning-color--100);
  --pf-c-alert--m-info__title--Color: var(--pf-global--info-color--100);
  --pf-c-alert--m-inline--BackgroundColor: var(--pf-global--palette--black-600);
  --pf-c-alert--m-inline--m-success--BackgroundColor: var(--pf-global--palette--black-600);
  --pf-c-alert--m-inline--m-danger--BackgroundColor: var(--pf-global--palette--black-600);
  --pf-c-alert--m-inline--m-warning--BackgroundColor: var(--pf-global--palette--black-600);
  --pf-c-alert--m-inline--m-info--BackgroundColor: var(--pf-global--palette--black-600);
}