.pf-c-options-menu {
  --pf-c-options-menu__toggle--BackgroundColor: transparent;
  --pf-c-options-menu__toggle--PaddingTop: var(--pf-global--spacer--form-element);
  --pf-c-options-menu__toggle--PaddingRight: var(--pf-global--spacer--sm);
  --pf-c-options-menu__toggle--PaddingBottom: var(--pf-global--spacer--form-element);
  --pf-c-options-menu__toggle--PaddingLeft: var(--pf-global--spacer--sm);
  --pf-c-options-menu__toggle--MinWidth: 0;
  --pf-c-options-menu__toggle--LineHeight: var(--pf-global--LineHeight--md);
  --pf-c-options-menu__toggle--BorderWidth: var(--pf-global--BorderWidth--sm);
  --pf-c-options-menu__toggle--BorderTopColor: var(--pf-global--BorderColor--300);
  --pf-c-options-menu__toggle--BorderRightColor: var(--pf-global--BorderColor--300);
  --pf-c-options-menu__toggle--BorderBottomColor: var(--pf-global--BorderColor--200);
  --pf-c-options-menu__toggle--BorderLeftColor: var(--pf-global--BorderColor--300);
  --pf-c-options-menu__toggle--Color: var(--pf-global--Color--100);
  --pf-c-options-menu__toggle--hover--BorderBottomColor: var(--pf-global--active-color--100);
  --pf-c-options-menu__toggle--active--BorderBottomWidth: var(--pf-global--BorderWidth--md);
  --pf-c-options-menu__toggle--active--BorderBottomColor: var(--pf-global--active-color--100);
  --pf-c-options-menu__toggle--focus--BorderBottomWidth: var(--pf-global--BorderWidth--md);
  --pf-c-options-menu__toggle--focus--BorderBottomColor: var(--pf-global--active-color--100);
  --pf-c-options-menu__toggle--expanded--BorderBottomWidth: var(--pf-global--BorderWidth--md);
  --pf-c-options-menu__toggle--expanded--BorderBottomColor: var(--pf-global--active-color--100);
  --pf-c-options-menu__toggle--disabled--BackgroundColor: var(--pf-global--disabled-color--300);
  --pf-c-options-menu__toggle--m-plain--Color: var(--pf-global--Color--200);
  --pf-c-options-menu__toggle--m-plain--hover--Color: var(--pf-global--Color--100);
  --pf-c-options-menu__toggle--m-plain--disabled--Color: var(--pf-global--disabled-color--200);
  --pf-c-options-menu__toggle--m-plain--PaddingRight: var(--pf-global--spacer--md);
  --pf-c-options-menu__toggle--m-plain--PaddingLeft: var(--pf-global--spacer--md);
  --pf-c-options-menu__toggle-icon--MarginRight: var(--pf-global--spacer--sm);
  --pf-c-options-menu__toggle-icon--MarginLeft: var(--pf-global--spacer--md);
  --pf-c-options-menu--m-top--m-expanded__toggle-icon--Rotate: 180deg;
  --pf-c-options-menu--m-plain__toggle-icon--Color: var(--pf-global--Color--200);
  --pf-c-options-menu--m-plain--hover__toggle-icon--Color: var(--pf-global--Color--100);
  --pf-c-options-menu__toggle-button--BackgroundColor: transparent;
  --pf-c-options-menu__toggle-button--PaddingTop: var(--pf-global--spacer--form-element);
  --pf-c-options-menu__toggle-button--PaddingRight: var(--pf-global--spacer--sm);
  --pf-c-options-menu__toggle-button--PaddingBottom: var(--pf-global--spacer--form-element);
  --pf-c-options-menu__toggle-button--PaddingLeft: var(--pf-global--spacer--sm);
  --pf-c-options-menu__menu--BackgroundColor: var(--pf-global--BackgroundColor--light-100);
  --pf-c-options-menu__menu--BoxShadow: var(--pf-global--BoxShadow--md);
  --pf-c-options-menu__menu--PaddingTop: var(--pf-global--spacer--sm);
  --pf-c-options-menu__menu--PaddingBottom: var(--pf-global--spacer--sm);
  --pf-c-options-menu__menu--Top: calc(100% + var(--pf-global--spacer--xs));
  --pf-c-options-menu__menu--ZIndex: var(--pf-global--ZIndex--sm);
  --pf-c-options-menu--m-top__menu--Top: 0;
  --pf-c-options-menu--m-top__menu--TranslateY: calc(-100% - var(--pf-global--spacer--xs));
  --pf-c-options-menu__menu-item--BackgroundColor: transparent;
  --pf-c-options-menu__menu-item--Color: var(--pf-global--Color--100);
  --pf-c-options-menu__menu-item--FontSize: var(--pf-global--FontSize--md);
  --pf-c-options-menu__menu-item--PaddingTop: var(--pf-global--spacer--sm);
  --pf-c-options-menu__menu-item--PaddingRight: var(--pf-global--spacer--md);
  --pf-c-options-menu__menu-item--PaddingBottom: var(--pf-global--spacer--sm);
  --pf-c-options-menu__menu-item--PaddingLeft: var(--pf-global--spacer--md);
  --pf-c-options-menu__menu-item--disabled--Color: var(--pf-global--Color--dark-200);
  --pf-c-options-menu__menu-item--hover--BackgroundColor: var(--pf-global--BackgroundColor--light-300);
  --pf-c-options-menu__menu-item--disabled--BackgroundColor: transparent;
  --pf-c-options-menu__menu-item-icon--Color: var(--pf-global--active-color--100);
  --pf-c-options-menu__menu-item-icon--FontSize: var(--pf-global--icon--FontSize--sm);
  --pf-c-options-menu__menu-item-icon--PaddingLeft: var(--pf-global--spacer--lg);
  --pf-c-options-menu__group--group--PaddingTop: var(--pf-global--spacer--sm);
  --pf-c-options-menu__group-title--PaddingTop: var(--pf-global--spacer--sm);
  --pf-c-options-menu__group-title--PaddingRight: var(--pf-c-options-menu__menu-item--PaddingRight);
  --pf-c-options-menu__group-title--PaddingBottom: var(--pf-c-options-menu__menu-item--PaddingBottom);
  --pf-c-options-menu__group-title--PaddingLeft: var(--pf-c-options-menu__menu-item--PaddingLeft);
  --pf-c-options-menu__group-title--FontSize: var(--pf-global--FontSize--xs);
  --pf-c-options-menu__group-title--FontWeight: var(--pf-global--FontWeight--normal);
  --pf-c-options-menu__group-title--Color: var(--pf-global--Color--dark-200);
  --pf-c-options-menu--c-divider--MarginTop: var(--pf-global--spacer--sm);
  --pf-c-options-menu--c-divider--MarginBottom: var(--pf-global--spacer--sm);
  position: relative;
  display: inline-block;
  max-width: 100%;
}
.pf-c-options-menu .pf-c-divider {
  margin-top: var(--pf-c-options-menu--c-divider--MarginTop);
  margin-bottom: var(--pf-c-options-menu--c-divider--MarginBottom);
}
.pf-c-options-menu .pf-c-divider:last-child {
  --pf-c-options-menu--c-divider--MarginBottom: 0;
}

.pf-c-options-menu__toggle:not(.pf-m-plain)::before,
.pf-c-options-menu.pf-m-text:not(.pf-m-plain) .pf-c-options-menu__toggle-button::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  border: var(--pf-c-options-menu__toggle--BorderWidth) solid;
  border-color: var(--pf-c-options-menu__toggle--BorderTopColor) var(--pf-c-options-menu__toggle--BorderRightColor) var(--pf-c-options-menu__toggle--BorderBottomColor) var(--pf-c-options-menu__toggle--BorderLeftColor);
}
.pf-c-options-menu__toggle:not(.pf-m-plain):hover::before,
.pf-c-options-menu.pf-m-text:not(.pf-m-plain) .pf-c-options-menu__toggle-button:hover::before {
  --pf-c-options-menu__toggle--BorderBottomColor: var(--pf-c-options-menu__toggle--hover--BorderBottomColor);
}
.pf-c-options-menu__toggle:not(.pf-m-plain):active::before, .pf-c-options-menu__toggle:not(.pf-m-plain).pf-m-active::before,
.pf-c-options-menu.pf-m-text:not(.pf-m-plain) .pf-c-options-menu__toggle-button:active::before,
.pf-c-options-menu.pf-m-text:not(.pf-m-plain) .pf-c-options-menu__toggle-button.pf-m-active::before {
  --pf-c-options-menu__toggle--BorderBottomColor: var(--pf-c-options-menu__toggle--active--BorderBottomColor);
  border-bottom-width: var(--pf-c-options-menu__toggle--active--BorderBottomWidth);
}
.pf-c-options-menu__toggle:not(.pf-m-plain):focus::before,
.pf-c-options-menu.pf-m-text:not(.pf-m-plain) .pf-c-options-menu__toggle-button:focus::before {
  --pf-c-options-menu__toggle--BorderBottomColor: var(--pf-c-options-menu__toggle--focus--BorderBottomColor);
  border-bottom-width: var(--pf-c-options-menu__toggle--focus--BorderBottomWidth);
}

.pf-c-options-menu__toggle {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: var(--pf-c-options-menu__toggle--MinWidth);
  max-width: 100%;
  padding: var(--pf-c-options-menu__toggle--PaddingTop) var(--pf-c-options-menu__toggle--PaddingRight) var(--pf-c-options-menu__toggle--PaddingBottom) var(--pf-c-options-menu__toggle--PaddingLeft);
  line-height: var(--pf-c-options-menu__toggle--LineHeight);
  color: var(--pf-c-options-menu__toggle--Color);
  background-color: var(--pf-c-options-menu__toggle--BackgroundColor);
  border: none;
}
.pf-c-options-menu.pf-m-expanded > .pf-c-options-menu__toggle::before {
  --pf-c-options-menu__toggle--BorderBottomColor: var(--pf-c-options-menu__toggle--expanded--BorderBottomColor);
  border-bottom-width: var(--pf-c-options-menu__toggle--expanded--BorderBottomWidth);
}
.pf-c-options-menu__toggle.pf-m-plain {
  --pf-c-options-menu__toggle-icon--Color: var(--pf-c-options-menu--m-plain__toggle-icon--Color);
}
.pf-c-options-menu__toggle.pf-m-plain:not(.pf-m-text) {
  --pf-c-options-menu__toggle--PaddingRight: var(--pf-c-options-menu__toggle--m-plain--PaddingRight);
  --pf-c-options-menu__toggle--PaddingLeft: var(--pf-c-options-menu__toggle--m-plain--PaddingLeft);
  display: inline-block;
  color: var(--pf-c-options-menu__toggle--m-plain--Color);
}
.pf-c-options-menu__toggle.pf-m-plain:not(.pf-m-text) .pf-c-options-menu__toggle-button-icon {
  line-height: var(--pf-c-options-menu__toggle--LineHeight);
}
.pf-c-options-menu__toggle.pf-m-plain:hover, .pf-c-options-menu__toggle.pf-m-plain:active, .pf-c-options-menu__toggle.pf-m-plain.pf-m-active, .pf-c-options-menu__toggle.pf-m-plain:focus, .pf-c-options-menu.pf-m-expanded > .pf-c-options-menu__toggle.pf-m-plain {
  --pf-c-options-menu__toggle--m-plain--Color: var(--pf-c-options-menu__toggle--m-plain--hover--Color);
  --pf-c-options-menu--m-plain__toggle-icon--Color: var(--pf-c-options-menu--m-plain--hover__toggle-icon--Color);
}
.pf-c-options-menu__toggle.pf-m-plain.pf-m-disabled, .pf-c-options-menu__toggle.pf-m-plain:disabled {
  --pf-c-options-menu__toggle--m-plain--Color: var(--pf-c-options-menu__toggle--m-plain--disabled--Color);
}
.pf-c-options-menu__toggle.pf-m-disabled, .pf-c-options-menu__toggle:disabled {
  pointer-events: none;
}
.pf-c-options-menu__toggle.pf-m-disabled:not(.pf-m-plain), .pf-c-options-menu__toggle.pf-m-disabled.pf-m-text, .pf-c-options-menu__toggle:disabled:not(.pf-m-plain), .pf-c-options-menu__toggle:disabled.pf-m-text {
  --pf-c-options-menu__toggle--BackgroundColor: var(--pf-c-options-menu__toggle--disabled--BackgroundColor);
}
.pf-c-options-menu__toggle.pf-m-disabled::before, .pf-c-options-menu__toggle:disabled::before {
  border: 0;
}

.pf-c-options-menu__toggle-button-icon {
  position: relative;
}

.pf-c-options-menu__toggle-button {
  padding: var(--pf-c-options-menu__toggle-button--PaddingTop) var(--pf-c-options-menu__toggle-button--PaddingRight) var(--pf-c-options-menu__toggle-button--PaddingBottom) var(--pf-c-options-menu__toggle-button--PaddingLeft);
  background-color: var(--pf-c-options-menu__toggle-button--BackgroundColor);
  border: 0;
}

.pf-c-options-menu__toggle-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pf-c-options-menu__toggle-icon,
.pf-c-options-menu__toggle-button-icon {
  color: var(--pf-c-options-menu__toggle-icon--Color, inherit);
}

.pf-c-options-menu__toggle-icon {
  margin-right: var(--pf-c-options-menu__toggle-icon--MarginRight);
  margin-left: var(--pf-c-options-menu__toggle-icon--MarginLeft);
}
.pf-c-options-menu.pf-m-top.pf-m-expanded .pf-c-options-menu__toggle-icon {
  transform: rotate(var(--pf-c-options-menu--m-top--m-expanded__toggle-icon--Rotate));
}

.pf-c-options-menu__menu {
  position: absolute;
  top: var(--pf-c-options-menu__menu--Top);
  z-index: var(--pf-c-options-menu__menu--ZIndex);
  min-width: 100%;
  padding-top: var(--pf-c-options-menu__menu--PaddingTop);
  padding-bottom: var(--pf-c-options-menu__menu--PaddingBottom);
  background-color: var(--pf-c-options-menu__menu--BackgroundColor);
  background-clip: padding-box;
  box-shadow: var(--pf-c-options-menu__menu--BoxShadow);
}
.pf-c-options-menu__menu.pf-m-align-right {
  right: 0;
}
.pf-c-options-menu.pf-m-top .pf-c-options-menu__menu {
  --pf-c-options-menu__menu--Top: var(--pf-c-options-menu--m-top__menu--Top);
  transform: translateY(var(--pf-c-options-menu--m-top__menu--TranslateY));
}
.pf-c-options-menu__menu.pf-m-static {
  --pf-c-options-menu--m-top__menu--TranslateY: 0;
  position: static;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  z-index: auto;
  min-width: min-content;
}

.pf-c-options-menu__menu-item {
  display: flex;
  align-items: baseline;
  width: 100%;
  padding: var(--pf-c-options-menu__menu-item--PaddingTop) var(--pf-c-options-menu__menu-item--PaddingRight) var(--pf-c-options-menu__menu-item--PaddingBottom) var(--pf-c-options-menu__menu-item--PaddingLeft);
  font-size: var(--pf-c-options-menu__menu-item--FontSize);
  color: var(--pf-c-options-menu__menu-item--Color);
  white-space: nowrap;
  background-color: var(--pf-c-options-menu__menu-item--BackgroundColor);
  border: none;
}
.pf-c-options-menu__menu-item:hover, .pf-c-options-menu__menu-item:focus {
  text-decoration: none;
  background-color: var(--pf-c-options-menu__menu-item--hover--BackgroundColor);
}
.pf-c-options-menu__menu-item:disabled, .pf-c-options-menu__menu-item.pf-m-disabled {
  color: var(--pf-c-options-menu__menu-item--disabled--Color);
  pointer-events: none;
  background-color: var(--pf-c-options-menu__menu-item--disabled--BackgroundColor);
}

.pf-c-options-menu__menu-item-icon {
  align-self: center;
  width: auto;
  padding-left: var(--pf-c-options-menu__menu-item-icon--PaddingLeft);
  margin-left: auto;
  font-size: var(--pf-c-options-menu__menu-item-icon--FontSize);
  color: var(--pf-c-options-menu__menu-item-icon--Color);
}

.pf-c-options-menu__group + .pf-c-options-menu__group {
  padding-top: var(--pf-c-options-menu__group--group--PaddingTop);
}

.pf-c-options-menu__group-title {
  padding-top: var(--pf-c-options-menu__group-title--PaddingTop);
  padding-right: var(--pf-c-options-menu__group-title--PaddingRight);
  padding-bottom: var(--pf-c-options-menu__group-title--PaddingBottom);
  padding-left: var(--pf-c-options-menu__group-title--PaddingLeft);
  font-size: var(--pf-c-options-menu__group-title--FontSize);
  font-weight: var(--pf-c-options-menu__group-title--FontWeight);
  color: var(--pf-c-options-menu__group-title--Color);
}

:where(.pf-theme-dark) .pf-c-options-menu {
  --pf-c-options-menu__toggle--BackgroundColor: var(--pf-global--BackgroundColor--400);
  --pf-c-options-menu__toggle--BorderTopColor: transparent;
  --pf-c-options-menu__toggle--BorderRightColor: transparent;
  --pf-c-options-menu__toggle--BorderBottomColor: var(--pf-global--BorderColor--400);
  --pf-c-options-menu__toggle--BorderLeftColor: transparent;
  --pf-c-options-menu__toggle--disabled--BackgroundColor: var(--pf-global--palette--black-500);
  --pf-c-options-menu__menu--BackgroundColor: var(--pf-global--BackgroundColor--300);
  --pf-c-options-menu__menu-item--hover--BackgroundColor: var(--pf-global--BackgroundColor--400);
  --pf-c-options-menu__menu--Top: 100%;
}
:where(.pf-theme-dark) .pf-c-options-menu__toggle.pf-m-plain {
  background: transparent;
}