.pf-c-table {
  --pf-c-table__tree-view-main--indent--base: calc(var(--pf-global--spacer--md) * 2 + var(--pf-c-table__tree-view-icon--MinWidth));
  --pf-c-table__tree-view-main--nested-indent--base: calc(var(--pf-c-table__tree-view-main--indent--base) - var(--pf-global--spacer--md));
  --pf-c-table__tree-view-main--PaddingLeft: var(--pf-c-table__tree-view-main--indent--base);
  --pf-c-table__tree-view-main--MarginLeft: calc(var(--pf-c-table--cell--PaddingLeft) * -1);
  --pf-c-table__tree-view-main--c-table__check--PaddingRight: var(--pf-global--spacer--sm);
  --pf-c-table__tree-view-main--c-table__check--PaddingLeft: var(--pf-global--spacer--sm);
  --pf-c-table__tree-view-main--c-table__check--MarginRight: var(--pf-global--spacer--sm);
  --pf-c-table__tree-view-icon--MinWidth: var(--pf-global--FontSize--md);
  --pf-c-table__tree-view-icon--MarginRight: var(--pf-global--spacer--sm);
  --pf-c-table--m-tree-view__toggle--Position: absolute;
  --pf-c-table--m-tree-view__toggle--Left: var(--pf-c-table__tree-view-main--PaddingLeft);
  --pf-c-table--m-tree-view__toggle--TranslateX: -100%;
  --pf-c-table--m-tree-view__toggle__toggle-icon--MinWidth: var(--pf-global--FontSize--md);
}
.pf-c-table.pf-m-tree-view > tbody > tr {
  --pf-c-table--m-tree-view__toggle--Left: var(--pf-c-table__tree-view-main--PaddingLeft);
}
.pf-c-table.pf-m-tree-view tr[aria-level="2"] {
  --pf-c-table__tree-view-main--PaddingLeft: calc(var(--pf-c-table__tree-view-main--nested-indent--base) * 1 + var(--pf-c-table__tree-view-main--indent--base));
}
.pf-c-table.pf-m-tree-view tr[aria-level="3"] {
  --pf-c-table__tree-view-main--PaddingLeft: calc(var(--pf-c-table__tree-view-main--nested-indent--base) * 2 + var(--pf-c-table__tree-view-main--indent--base));
}
.pf-c-table.pf-m-tree-view tr[aria-level="4"] {
  --pf-c-table__tree-view-main--PaddingLeft: calc(var(--pf-c-table__tree-view-main--nested-indent--base) * 3 + var(--pf-c-table__tree-view-main--indent--base));
}
.pf-c-table.pf-m-tree-view tr[aria-level="5"] {
  --pf-c-table__tree-view-main--PaddingLeft: calc(var(--pf-c-table__tree-view-main--nested-indent--base) * 4 + var(--pf-c-table__tree-view-main--indent--base));
}
.pf-c-table.pf-m-tree-view tr[aria-level="6"] {
  --pf-c-table__tree-view-main--PaddingLeft: calc(var(--pf-c-table__tree-view-main--nested-indent--base) * 5 + var(--pf-c-table__tree-view-main--indent--base));
}
.pf-c-table.pf-m-tree-view tr[aria-level="7"] {
  --pf-c-table__tree-view-main--PaddingLeft: calc(var(--pf-c-table__tree-view-main--nested-indent--base) * 6 + var(--pf-c-table__tree-view-main--indent--base));
}
.pf-c-table.pf-m-tree-view tr[aria-level="8"] {
  --pf-c-table__tree-view-main--PaddingLeft: calc(var(--pf-c-table__tree-view-main--nested-indent--base) * 7 + var(--pf-c-table__tree-view-main--indent--base));
}
.pf-c-table.pf-m-tree-view tr[aria-level="9"] {
  --pf-c-table__tree-view-main--PaddingLeft: calc(var(--pf-c-table__tree-view-main--nested-indent--base) * 8 + var(--pf-c-table__tree-view-main--indent--base));
}
.pf-c-table.pf-m-tree-view tr[aria-level="10"] {
  --pf-c-table__tree-view-main--PaddingLeft: calc(var(--pf-c-table__tree-view-main--nested-indent--base) * 9 + var(--pf-c-table__tree-view-main--indent--base));
}

.pf-c-table__tree-view-main {
  position: relative;
  display: flex;
  align-items: baseline;
  min-width: 0;
  padding-left: var(--pf-c-table__tree-view-main--PaddingLeft);
  margin-left: var(--pf-c-table__tree-view-main--MarginLeft);
  text-align: left;
  cursor: pointer;
}
.pf-c-table__tree-view-main > .pf-c-table__toggle {
  position: var(--pf-c-table--m-tree-view__toggle--Position);
  left: var(--pf-c-table--m-tree-view__toggle--Left);
  transform: translateX(var(--pf-c-table--m-tree-view__toggle--TranslateX));
}
.pf-c-table__tree-view-main > .pf-c-table__toggle .pf-c-table__toggle-icon {
  min-width: var(--pf-c-table--m-tree-view__toggle__toggle-icon--MinWidth);
}
.pf-c-table__tree-view-main > .pf-c-table__check {
  margin-right: var(--pf-c-table__tree-view-main--c-table__check--MarginRight);
}
.pf-c-table__tree-view-main > .pf-c-table__check label {
  padding-right: var(--pf-c-table__tree-view-main--c-table__check--PaddingRight);
  padding-left: var(--pf-c-table__tree-view-main--c-table__check--PaddingLeft);
  margin: 0 calc(var(--pf-c-table__tree-view-main--c-table__check--MarginRight) * -1) 0 0;
}

.pf-c-table__tree-view-text {
  display: flex;
}

.pf-c-table__tree-view-icon {
  min-width: var(--pf-c-table__tree-view-icon--MinWidth);
  margin-right: var(--pf-c-table__tree-view-icon--MarginRight);
}

.pf-c-table__tree-view-details-toggle {
  display: none;
  visibility: hidden;
}

.pf-m-tree-view-grid.pf-c-table {
  --pf-c-table--m-tree-view-grid--tr--OutlineOffset: calc(-1 * var(--pf-global--spacer--xs));
  --pf-c-table--m-tree-view-grid--tbody--cell--PaddingTop: var(--pf-global--spacer--md);
  --pf-c-table--m-tree-view-grid--tbody--cell--PaddingBottom: var(--pf-global--spacer--md);
  --pf-c-table--m-tree-view-grid--tbody--cell--PaddingLeft: var(--pf-c-table__tree-view-main--indent--base);
  --pf-c-table--m-tree-view-grid--tbody--cell--GridColumnGap: var(--pf-global--spacer--sm);
  --pf-c-table--m-tree-view-grid--c-table__action--PaddingTop: var(--pf-global--spacer--md);
  --pf-c-table--m-tree-view-grid--c-table__action--PaddingBottom: var(--pf-global--spacer--md);
  --pf-c-table--m-tree-view-grid--c-table__action--PaddingLeft: 0;
  --pf-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--action--PaddingTop: var(--pf-global--spacer--xl);
  --pf-c-table--m-tree-view-grid--m-tree-view-details-expanded--PaddingBottom: var(--pf-global--spacer--xl);
  --pf-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--PaddingTop: var(--pf-global--spacer--xl);
  --pf-c-table--m-tree-view-grid--td--data-label--GridTemplateColumns: repeat(auto-fit, minmax(150px, 1fr));
  --pf-c-table--m-tree-view-grid--td--not--c-table__tree-view-title-cell--PaddingTop: var(--pf-global--spacer--xs);
  --pf-c-table--m-tree-view-grid--td--not--c-table__tree-view-title-cell--PaddingBottom: var(--pf-global--spacer--xs);
  --pf-c-table--m-tree-view-mobile__tree-view-main--c-table__check--MarginRight: 0;
  --pf-c-table--m-tree-view-mobile__tree-view-main--c-table__check--Order: 4;
  --pf-c-table__tree-view-text--PaddingRight: var(--pf-global--spacer--sm);
  --pf-c-table--tbody--cell--PaddingTop: var(--pf-c-table--m-tree-view-grid--tbody--cell--PaddingTop);
  --pf-c-table--tbody--cell--PaddingBottom: var(--pf-c-table--m-tree-view-grid--tbody--cell--PaddingBottom);
  --pf-c-table__tree-view-details-toggle--MarginTop: calc(0.375rem * -1);
  --pf-c-table__tree-view-details-toggle--MarginBottom: calc(0.375rem * -1);
  --pf-c-table--m-tree-view-grid--c-dropdown--MarginTop: calc(0.375rem * -1);
  --pf-c-table--m-tree-view-grid--c-dropdown--MarginBottom: calc(0.375rem * -1);
}
.pf-m-tree-view-grid.pf-c-table tbody tr {
  position: relative;
  outline-offset: var(--pf-c-table--m-tree-view-grid--tr--OutlineOffset);
  border: none;
}
.pf-m-tree-view-grid.pf-c-table tbody tr::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: var(--pf-c-table--m-tree-view-grid--tbody--cell--PaddingLeft);
  border-bottom: var(--pf-c-table--border-width--base) solid var(--pf-c-table--BorderColor);
  content: "";
}
.pf-m-tree-view-grid.pf-c-table tr:not([hidden]) {
  display: grid;
  grid-template-columns: 1fr max-content;
}
.pf-m-tree-view-grid.pf-c-table tr[aria-expanded] .pf-c-table__tree-view-title-cell {
  --pf-c-table--cell--PaddingTop: var(--pf-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--PaddingTop);
}
.pf-m-tree-view-grid.pf-c-table tr[aria-expanded] .pf-c-table__tree-view-title-cell ~ .pf-c-table__action {
  --pf-c-table--cell--PaddingTop: var(--pf-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--action--PaddingTop);
}
.pf-m-tree-view-grid.pf-c-table td:not(.pf-c-table__tree-view-title-cell) {
  --pf-c-table--cell--PaddingTop: var(--pf-c-table--m-tree-view-grid--td--not--c-table__tree-view-title-cell--PaddingTop);
  --pf-c-table--cell--PaddingBottom: var(--pf-c-table--m-tree-view-grid--td--not--c-table__tree-view-title-cell--PaddingBottom);
  padding-left: var(--pf-c-table--m-tree-view-grid--tbody--cell--PaddingLeft);
}
.pf-m-tree-view-grid.pf-c-table .pf-c-table__tree-view-text {
  padding-right: var(--pf-c-table__tree-view-text--PaddingRight);
}
.pf-m-tree-view-grid.pf-c-table thead th {
  display: none;
  visibility: none;
}
.pf-m-tree-view-grid.pf-c-table td {
  display: none;
}
.pf-m-tree-view-grid.pf-c-table td[data-label] {
  grid-column: 1;
  grid-column-gap: var(--pf-c-table--m-tree-view-grid--tbody--cell--GridColumnGap);
  grid-template-columns: var(--pf-c-table--m-tree-view-grid--td--data-label--GridTemplateColumns);
  align-items: start;
}
.pf-m-tree-view-grid.pf-c-table td[data-label] > * {
  grid-column: 2;
}
.pf-m-tree-view-grid.pf-c-table td[data-label]::before {
  font-weight: bold;
  text-align: left;
  content: attr(data-label);
}
.pf-m-tree-view-grid.pf-c-table tr.pf-m-tree-view-details-expanded {
  padding-bottom: var(--pf-c-table--m-tree-view-grid--m-tree-view-details-expanded--PaddingBottom);
}
.pf-m-tree-view-grid.pf-c-table tr.pf-m-tree-view-details-expanded td[data-label] {
  display: grid;
}
.pf-m-tree-view-grid.pf-c-table .pf-c-table__tree-view-title-header-cell {
  display: block;
  visibility: visible;
}
.pf-m-tree-view-grid.pf-c-table .pf-c-table__tree-view-details-toggle,
.pf-m-tree-view-grid.pf-c-table .pf-c-table__action {
  display: inline-block;
  visibility: visible;
}
.pf-m-tree-view-grid.pf-c-table .pf-c-table__action {
  --pf-c-table--cell--Width: auto;
  --pf-c-table--m-tree-view-grid--tbody--cell--PaddingLeft: var(--pf-c-table--m-tree-view-grid--c-table__action--PaddingLeft);
  --pf-c-table--m-tree-view-grid--td--not--c-table__tree-view-title-cell--PaddingTop: var(--pf-c-table--m-tree-view-grid--c-table__action--PaddingTop);
  --pf-c-table--m-tree-view-grid--td--not--c-table__tree-view-title-cell--PaddingBottom: var(--pf-c-table--m-tree-view-grid--c-table__action--PaddingBottom);
  grid-column: 2;
  grid-row: 1;
}
.pf-m-tree-view-grid.pf-c-table .pf-c-table__action > .pf-c-dropdown {
  margin-top: var(--pf-c-table--m-tree-view-grid--c-dropdown--MarginTop);
  margin-bottom: var(--pf-c-table--m-tree-view-grid--c-dropdown--MarginBottom);
}
.pf-m-tree-view-grid.pf-c-table .pf-c-table__tree-view-main > .pf-c-table__check {
  margin-right: var(--pf-c-table--m-tree-view-mobile__tree-view-main--c-table__check--MarginRight);
  margin-left: auto;
  order: var(--pf-c-table--m-tree-view-mobile__tree-view-main--c-table__check--Order);
}
.pf-m-tree-view-grid.pf-c-table .pf-c-table__tree-view-details-toggle {
  margin-top: var(--pf-c-table__tree-view-details-toggle--MarginTop);
  margin-bottom: var(--pf-c-table__tree-view-details-toggle--MarginBottom);
}
.pf-m-tree-view-grid.pf-c-table tr[aria-level="2"] {
  --pf-c-table--m-tree-view-grid--tbody--cell--PaddingLeft: calc(var(--pf-c-table__tree-view-main--nested-indent--base) * 1 + var(--pf-c-table__tree-view-main--indent--base));
}
.pf-m-tree-view-grid.pf-c-table tr[aria-level="3"] {
  --pf-c-table--m-tree-view-grid--tbody--cell--PaddingLeft: calc(var(--pf-c-table__tree-view-main--nested-indent--base) * 2 + var(--pf-c-table__tree-view-main--indent--base));
}
.pf-m-tree-view-grid.pf-c-table tr[aria-level="4"] {
  --pf-c-table--m-tree-view-grid--tbody--cell--PaddingLeft: calc(var(--pf-c-table__tree-view-main--nested-indent--base) * 3 + var(--pf-c-table__tree-view-main--indent--base));
}
.pf-m-tree-view-grid.pf-c-table tr[aria-level="5"] {
  --pf-c-table--m-tree-view-grid--tbody--cell--PaddingLeft: calc(var(--pf-c-table__tree-view-main--nested-indent--base) * 4 + var(--pf-c-table__tree-view-main--indent--base));
}
.pf-m-tree-view-grid.pf-c-table tr[aria-level="6"] {
  --pf-c-table--m-tree-view-grid--tbody--cell--PaddingLeft: calc(var(--pf-c-table__tree-view-main--nested-indent--base) * 5 + var(--pf-c-table__tree-view-main--indent--base));
}
.pf-m-tree-view-grid.pf-c-table tr[aria-level="7"] {
  --pf-c-table--m-tree-view-grid--tbody--cell--PaddingLeft: calc(var(--pf-c-table__tree-view-main--nested-indent--base) * 6 + var(--pf-c-table__tree-view-main--indent--base));
}
.pf-m-tree-view-grid.pf-c-table tr[aria-level="8"] {
  --pf-c-table--m-tree-view-grid--tbody--cell--PaddingLeft: calc(var(--pf-c-table__tree-view-main--nested-indent--base) * 7 + var(--pf-c-table__tree-view-main--indent--base));
}
.pf-m-tree-view-grid.pf-c-table tr[aria-level="9"] {
  --pf-c-table--m-tree-view-grid--tbody--cell--PaddingLeft: calc(var(--pf-c-table__tree-view-main--nested-indent--base) * 8 + var(--pf-c-table__tree-view-main--indent--base));
}
.pf-m-tree-view-grid.pf-c-table tr[aria-level="10"] {
  --pf-c-table--m-tree-view-grid--tbody--cell--PaddingLeft: calc(var(--pf-c-table__tree-view-main--nested-indent--base) * 9 + var(--pf-c-table__tree-view-main--indent--base));
}

@media screen and (max-width: 768px) {
  .pf-m-tree-view-grid-md.pf-c-table {
    --pf-c-table--m-tree-view-grid--tr--OutlineOffset: calc(-1 * var(--pf-global--spacer--xs));
    --pf-c-table--m-tree-view-grid--tbody--cell--PaddingTop: var(--pf-global--spacer--md);
    --pf-c-table--m-tree-view-grid--tbody--cell--PaddingBottom: var(--pf-global--spacer--md);
    --pf-c-table--m-tree-view-grid--tbody--cell--PaddingLeft: var(--pf-c-table__tree-view-main--indent--base);
    --pf-c-table--m-tree-view-grid--tbody--cell--GridColumnGap: var(--pf-global--spacer--sm);
    --pf-c-table--m-tree-view-grid--c-table__action--PaddingTop: var(--pf-global--spacer--md);
    --pf-c-table--m-tree-view-grid--c-table__action--PaddingBottom: var(--pf-global--spacer--md);
    --pf-c-table--m-tree-view-grid--c-table__action--PaddingLeft: 0;
    --pf-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--action--PaddingTop: var(--pf-global--spacer--xl);
    --pf-c-table--m-tree-view-grid--m-tree-view-details-expanded--PaddingBottom: var(--pf-global--spacer--xl);
    --pf-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--PaddingTop: var(--pf-global--spacer--xl);
    --pf-c-table--m-tree-view-grid--td--data-label--GridTemplateColumns: repeat(auto-fit, minmax(150px, 1fr));
    --pf-c-table--m-tree-view-grid--td--not--c-table__tree-view-title-cell--PaddingTop: var(--pf-global--spacer--xs);
    --pf-c-table--m-tree-view-grid--td--not--c-table__tree-view-title-cell--PaddingBottom: var(--pf-global--spacer--xs);
    --pf-c-table--m-tree-view-mobile__tree-view-main--c-table__check--MarginRight: 0;
    --pf-c-table--m-tree-view-mobile__tree-view-main--c-table__check--Order: 4;
    --pf-c-table__tree-view-text--PaddingRight: var(--pf-global--spacer--sm);
    --pf-c-table--tbody--cell--PaddingTop: var(--pf-c-table--m-tree-view-grid--tbody--cell--PaddingTop);
    --pf-c-table--tbody--cell--PaddingBottom: var(--pf-c-table--m-tree-view-grid--tbody--cell--PaddingBottom);
    --pf-c-table__tree-view-details-toggle--MarginTop: calc(0.375rem * -1);
    --pf-c-table__tree-view-details-toggle--MarginBottom: calc(0.375rem * -1);
    --pf-c-table--m-tree-view-grid--c-dropdown--MarginTop: calc(0.375rem * -1);
    --pf-c-table--m-tree-view-grid--c-dropdown--MarginBottom: calc(0.375rem * -1);
  }
  .pf-m-tree-view-grid-md.pf-c-table tbody tr {
    position: relative;
    outline-offset: var(--pf-c-table--m-tree-view-grid--tr--OutlineOffset);
    border: none;
  }
  .pf-m-tree-view-grid-md.pf-c-table tbody tr::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: var(--pf-c-table--m-tree-view-grid--tbody--cell--PaddingLeft);
    border-bottom: var(--pf-c-table--border-width--base) solid var(--pf-c-table--BorderColor);
    content: "";
  }
  .pf-m-tree-view-grid-md.pf-c-table tr:not([hidden]) {
    display: grid;
    grid-template-columns: 1fr max-content;
  }
  .pf-m-tree-view-grid-md.pf-c-table tr[aria-expanded] .pf-c-table__tree-view-title-cell {
    --pf-c-table--cell--PaddingTop: var(--pf-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--PaddingTop);
  }
  .pf-m-tree-view-grid-md.pf-c-table tr[aria-expanded] .pf-c-table__tree-view-title-cell ~ .pf-c-table__action {
    --pf-c-table--cell--PaddingTop: var(--pf-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--action--PaddingTop);
  }
  .pf-m-tree-view-grid-md.pf-c-table td:not(.pf-c-table__tree-view-title-cell) {
    --pf-c-table--cell--PaddingTop: var(--pf-c-table--m-tree-view-grid--td--not--c-table__tree-view-title-cell--PaddingTop);
    --pf-c-table--cell--PaddingBottom: var(--pf-c-table--m-tree-view-grid--td--not--c-table__tree-view-title-cell--PaddingBottom);
    padding-left: var(--pf-c-table--m-tree-view-grid--tbody--cell--PaddingLeft);
  }
  .pf-m-tree-view-grid-md.pf-c-table .pf-c-table__tree-view-text {
    padding-right: var(--pf-c-table__tree-view-text--PaddingRight);
  }
  .pf-m-tree-view-grid-md.pf-c-table thead th {
    display: none;
    visibility: none;
  }
  .pf-m-tree-view-grid-md.pf-c-table td {
    display: none;
  }
  .pf-m-tree-view-grid-md.pf-c-table td[data-label] {
    grid-column: 1;
    grid-column-gap: var(--pf-c-table--m-tree-view-grid--tbody--cell--GridColumnGap);
    grid-template-columns: var(--pf-c-table--m-tree-view-grid--td--data-label--GridTemplateColumns);
    align-items: start;
  }
  .pf-m-tree-view-grid-md.pf-c-table td[data-label] > * {
    grid-column: 2;
  }
  .pf-m-tree-view-grid-md.pf-c-table td[data-label]::before {
    font-weight: bold;
    text-align: left;
    content: attr(data-label);
  }
  .pf-m-tree-view-grid-md.pf-c-table tr.pf-m-tree-view-details-expanded {
    padding-bottom: var(--pf-c-table--m-tree-view-grid--m-tree-view-details-expanded--PaddingBottom);
  }
  .pf-m-tree-view-grid-md.pf-c-table tr.pf-m-tree-view-details-expanded td[data-label] {
    display: grid;
  }
  .pf-m-tree-view-grid-md.pf-c-table .pf-c-table__tree-view-title-header-cell {
    display: block;
    visibility: visible;
  }
  .pf-m-tree-view-grid-md.pf-c-table .pf-c-table__tree-view-details-toggle,
.pf-m-tree-view-grid-md.pf-c-table .pf-c-table__action {
    display: inline-block;
    visibility: visible;
  }
  .pf-m-tree-view-grid-md.pf-c-table .pf-c-table__action {
    --pf-c-table--cell--Width: auto;
    --pf-c-table--m-tree-view-grid--tbody--cell--PaddingLeft: var(--pf-c-table--m-tree-view-grid--c-table__action--PaddingLeft);
    --pf-c-table--m-tree-view-grid--td--not--c-table__tree-view-title-cell--PaddingTop: var(--pf-c-table--m-tree-view-grid--c-table__action--PaddingTop);
    --pf-c-table--m-tree-view-grid--td--not--c-table__tree-view-title-cell--PaddingBottom: var(--pf-c-table--m-tree-view-grid--c-table__action--PaddingBottom);
    grid-column: 2;
    grid-row: 1;
  }
  .pf-m-tree-view-grid-md.pf-c-table .pf-c-table__action > .pf-c-dropdown {
    margin-top: var(--pf-c-table--m-tree-view-grid--c-dropdown--MarginTop);
    margin-bottom: var(--pf-c-table--m-tree-view-grid--c-dropdown--MarginBottom);
  }
  .pf-m-tree-view-grid-md.pf-c-table .pf-c-table__tree-view-main > .pf-c-table__check {
    margin-right: var(--pf-c-table--m-tree-view-mobile__tree-view-main--c-table__check--MarginRight);
    margin-left: auto;
    order: var(--pf-c-table--m-tree-view-mobile__tree-view-main--c-table__check--Order);
  }
  .pf-m-tree-view-grid-md.pf-c-table .pf-c-table__tree-view-details-toggle {
    margin-top: var(--pf-c-table__tree-view-details-toggle--MarginTop);
    margin-bottom: var(--pf-c-table__tree-view-details-toggle--MarginBottom);
  }
  .pf-m-tree-view-grid-md.pf-c-table tr[aria-level="2"] {
    --pf-c-table--m-tree-view-grid--tbody--cell--PaddingLeft: calc(var(--pf-c-table__tree-view-main--nested-indent--base) * 1 + var(--pf-c-table__tree-view-main--indent--base));
  }
  .pf-m-tree-view-grid-md.pf-c-table tr[aria-level="3"] {
    --pf-c-table--m-tree-view-grid--tbody--cell--PaddingLeft: calc(var(--pf-c-table__tree-view-main--nested-indent--base) * 2 + var(--pf-c-table__tree-view-main--indent--base));
  }
  .pf-m-tree-view-grid-md.pf-c-table tr[aria-level="4"] {
    --pf-c-table--m-tree-view-grid--tbody--cell--PaddingLeft: calc(var(--pf-c-table__tree-view-main--nested-indent--base) * 3 + var(--pf-c-table__tree-view-main--indent--base));
  }
  .pf-m-tree-view-grid-md.pf-c-table tr[aria-level="5"] {
    --pf-c-table--m-tree-view-grid--tbody--cell--PaddingLeft: calc(var(--pf-c-table__tree-view-main--nested-indent--base) * 4 + var(--pf-c-table__tree-view-main--indent--base));
  }
  .pf-m-tree-view-grid-md.pf-c-table tr[aria-level="6"] {
    --pf-c-table--m-tree-view-grid--tbody--cell--PaddingLeft: calc(var(--pf-c-table__tree-view-main--nested-indent--base) * 5 + var(--pf-c-table__tree-view-main--indent--base));
  }
  .pf-m-tree-view-grid-md.pf-c-table tr[aria-level="7"] {
    --pf-c-table--m-tree-view-grid--tbody--cell--PaddingLeft: calc(var(--pf-c-table__tree-view-main--nested-indent--base) * 6 + var(--pf-c-table__tree-view-main--indent--base));
  }
  .pf-m-tree-view-grid-md.pf-c-table tr[aria-level="8"] {
    --pf-c-table--m-tree-view-grid--tbody--cell--PaddingLeft: calc(var(--pf-c-table__tree-view-main--nested-indent--base) * 7 + var(--pf-c-table__tree-view-main--indent--base));
  }
  .pf-m-tree-view-grid-md.pf-c-table tr[aria-level="9"] {
    --pf-c-table--m-tree-view-grid--tbody--cell--PaddingLeft: calc(var(--pf-c-table__tree-view-main--nested-indent--base) * 8 + var(--pf-c-table__tree-view-main--indent--base));
  }
  .pf-m-tree-view-grid-md.pf-c-table tr[aria-level="10"] {
    --pf-c-table--m-tree-view-grid--tbody--cell--PaddingLeft: calc(var(--pf-c-table__tree-view-main--nested-indent--base) * 9 + var(--pf-c-table__tree-view-main--indent--base));
  }
}

@media screen and (max-width: 992px) {
  .pf-m-tree-view-grid-lg.pf-c-table {
    --pf-c-table--m-tree-view-grid--tr--OutlineOffset: calc(-1 * var(--pf-global--spacer--xs));
    --pf-c-table--m-tree-view-grid--tbody--cell--PaddingTop: var(--pf-global--spacer--md);
    --pf-c-table--m-tree-view-grid--tbody--cell--PaddingBottom: var(--pf-global--spacer--md);
    --pf-c-table--m-tree-view-grid--tbody--cell--PaddingLeft: var(--pf-c-table__tree-view-main--indent--base);
    --pf-c-table--m-tree-view-grid--tbody--cell--GridColumnGap: var(--pf-global--spacer--sm);
    --pf-c-table--m-tree-view-grid--c-table__action--PaddingTop: var(--pf-global--spacer--md);
    --pf-c-table--m-tree-view-grid--c-table__action--PaddingBottom: var(--pf-global--spacer--md);
    --pf-c-table--m-tree-view-grid--c-table__action--PaddingLeft: 0;
    --pf-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--action--PaddingTop: var(--pf-global--spacer--xl);
    --pf-c-table--m-tree-view-grid--m-tree-view-details-expanded--PaddingBottom: var(--pf-global--spacer--xl);
    --pf-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--PaddingTop: var(--pf-global--spacer--xl);
    --pf-c-table--m-tree-view-grid--td--data-label--GridTemplateColumns: repeat(auto-fit, minmax(150px, 1fr));
    --pf-c-table--m-tree-view-grid--td--not--c-table__tree-view-title-cell--PaddingTop: var(--pf-global--spacer--xs);
    --pf-c-table--m-tree-view-grid--td--not--c-table__tree-view-title-cell--PaddingBottom: var(--pf-global--spacer--xs);
    --pf-c-table--m-tree-view-mobile__tree-view-main--c-table__check--MarginRight: 0;
    --pf-c-table--m-tree-view-mobile__tree-view-main--c-table__check--Order: 4;
    --pf-c-table__tree-view-text--PaddingRight: var(--pf-global--spacer--sm);
    --pf-c-table--tbody--cell--PaddingTop: var(--pf-c-table--m-tree-view-grid--tbody--cell--PaddingTop);
    --pf-c-table--tbody--cell--PaddingBottom: var(--pf-c-table--m-tree-view-grid--tbody--cell--PaddingBottom);
    --pf-c-table__tree-view-details-toggle--MarginTop: calc(0.375rem * -1);
    --pf-c-table__tree-view-details-toggle--MarginBottom: calc(0.375rem * -1);
    --pf-c-table--m-tree-view-grid--c-dropdown--MarginTop: calc(0.375rem * -1);
    --pf-c-table--m-tree-view-grid--c-dropdown--MarginBottom: calc(0.375rem * -1);
  }
  .pf-m-tree-view-grid-lg.pf-c-table tbody tr {
    position: relative;
    outline-offset: var(--pf-c-table--m-tree-view-grid--tr--OutlineOffset);
    border: none;
  }
  .pf-m-tree-view-grid-lg.pf-c-table tbody tr::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: var(--pf-c-table--m-tree-view-grid--tbody--cell--PaddingLeft);
    border-bottom: var(--pf-c-table--border-width--base) solid var(--pf-c-table--BorderColor);
    content: "";
  }
  .pf-m-tree-view-grid-lg.pf-c-table tr:not([hidden]) {
    display: grid;
    grid-template-columns: 1fr max-content;
  }
  .pf-m-tree-view-grid-lg.pf-c-table tr[aria-expanded] .pf-c-table__tree-view-title-cell {
    --pf-c-table--cell--PaddingTop: var(--pf-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--PaddingTop);
  }
  .pf-m-tree-view-grid-lg.pf-c-table tr[aria-expanded] .pf-c-table__tree-view-title-cell ~ .pf-c-table__action {
    --pf-c-table--cell--PaddingTop: var(--pf-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--action--PaddingTop);
  }
  .pf-m-tree-view-grid-lg.pf-c-table td:not(.pf-c-table__tree-view-title-cell) {
    --pf-c-table--cell--PaddingTop: var(--pf-c-table--m-tree-view-grid--td--not--c-table__tree-view-title-cell--PaddingTop);
    --pf-c-table--cell--PaddingBottom: var(--pf-c-table--m-tree-view-grid--td--not--c-table__tree-view-title-cell--PaddingBottom);
    padding-left: var(--pf-c-table--m-tree-view-grid--tbody--cell--PaddingLeft);
  }
  .pf-m-tree-view-grid-lg.pf-c-table .pf-c-table__tree-view-text {
    padding-right: var(--pf-c-table__tree-view-text--PaddingRight);
  }
  .pf-m-tree-view-grid-lg.pf-c-table thead th {
    display: none;
    visibility: none;
  }
  .pf-m-tree-view-grid-lg.pf-c-table td {
    display: none;
  }
  .pf-m-tree-view-grid-lg.pf-c-table td[data-label] {
    grid-column: 1;
    grid-column-gap: var(--pf-c-table--m-tree-view-grid--tbody--cell--GridColumnGap);
    grid-template-columns: var(--pf-c-table--m-tree-view-grid--td--data-label--GridTemplateColumns);
    align-items: start;
  }
  .pf-m-tree-view-grid-lg.pf-c-table td[data-label] > * {
    grid-column: 2;
  }
  .pf-m-tree-view-grid-lg.pf-c-table td[data-label]::before {
    font-weight: bold;
    text-align: left;
    content: attr(data-label);
  }
  .pf-m-tree-view-grid-lg.pf-c-table tr.pf-m-tree-view-details-expanded {
    padding-bottom: var(--pf-c-table--m-tree-view-grid--m-tree-view-details-expanded--PaddingBottom);
  }
  .pf-m-tree-view-grid-lg.pf-c-table tr.pf-m-tree-view-details-expanded td[data-label] {
    display: grid;
  }
  .pf-m-tree-view-grid-lg.pf-c-table .pf-c-table__tree-view-title-header-cell {
    display: block;
    visibility: visible;
  }
  .pf-m-tree-view-grid-lg.pf-c-table .pf-c-table__tree-view-details-toggle,
.pf-m-tree-view-grid-lg.pf-c-table .pf-c-table__action {
    display: inline-block;
    visibility: visible;
  }
  .pf-m-tree-view-grid-lg.pf-c-table .pf-c-table__action {
    --pf-c-table--cell--Width: auto;
    --pf-c-table--m-tree-view-grid--tbody--cell--PaddingLeft: var(--pf-c-table--m-tree-view-grid--c-table__action--PaddingLeft);
    --pf-c-table--m-tree-view-grid--td--not--c-table__tree-view-title-cell--PaddingTop: var(--pf-c-table--m-tree-view-grid--c-table__action--PaddingTop);
    --pf-c-table--m-tree-view-grid--td--not--c-table__tree-view-title-cell--PaddingBottom: var(--pf-c-table--m-tree-view-grid--c-table__action--PaddingBottom);
    grid-column: 2;
    grid-row: 1;
  }
  .pf-m-tree-view-grid-lg.pf-c-table .pf-c-table__action > .pf-c-dropdown {
    margin-top: var(--pf-c-table--m-tree-view-grid--c-dropdown--MarginTop);
    margin-bottom: var(--pf-c-table--m-tree-view-grid--c-dropdown--MarginBottom);
  }
  .pf-m-tree-view-grid-lg.pf-c-table .pf-c-table__tree-view-main > .pf-c-table__check {
    margin-right: var(--pf-c-table--m-tree-view-mobile__tree-view-main--c-table__check--MarginRight);
    margin-left: auto;
    order: var(--pf-c-table--m-tree-view-mobile__tree-view-main--c-table__check--Order);
  }
  .pf-m-tree-view-grid-lg.pf-c-table .pf-c-table__tree-view-details-toggle {
    margin-top: var(--pf-c-table__tree-view-details-toggle--MarginTop);
    margin-bottom: var(--pf-c-table__tree-view-details-toggle--MarginBottom);
  }
  .pf-m-tree-view-grid-lg.pf-c-table tr[aria-level="2"] {
    --pf-c-table--m-tree-view-grid--tbody--cell--PaddingLeft: calc(var(--pf-c-table__tree-view-main--nested-indent--base) * 1 + var(--pf-c-table__tree-view-main--indent--base));
  }
  .pf-m-tree-view-grid-lg.pf-c-table tr[aria-level="3"] {
    --pf-c-table--m-tree-view-grid--tbody--cell--PaddingLeft: calc(var(--pf-c-table__tree-view-main--nested-indent--base) * 2 + var(--pf-c-table__tree-view-main--indent--base));
  }
  .pf-m-tree-view-grid-lg.pf-c-table tr[aria-level="4"] {
    --pf-c-table--m-tree-view-grid--tbody--cell--PaddingLeft: calc(var(--pf-c-table__tree-view-main--nested-indent--base) * 3 + var(--pf-c-table__tree-view-main--indent--base));
  }
  .pf-m-tree-view-grid-lg.pf-c-table tr[aria-level="5"] {
    --pf-c-table--m-tree-view-grid--tbody--cell--PaddingLeft: calc(var(--pf-c-table__tree-view-main--nested-indent--base) * 4 + var(--pf-c-table__tree-view-main--indent--base));
  }
  .pf-m-tree-view-grid-lg.pf-c-table tr[aria-level="6"] {
    --pf-c-table--m-tree-view-grid--tbody--cell--PaddingLeft: calc(var(--pf-c-table__tree-view-main--nested-indent--base) * 5 + var(--pf-c-table__tree-view-main--indent--base));
  }
  .pf-m-tree-view-grid-lg.pf-c-table tr[aria-level="7"] {
    --pf-c-table--m-tree-view-grid--tbody--cell--PaddingLeft: calc(var(--pf-c-table__tree-view-main--nested-indent--base) * 6 + var(--pf-c-table__tree-view-main--indent--base));
  }
  .pf-m-tree-view-grid-lg.pf-c-table tr[aria-level="8"] {
    --pf-c-table--m-tree-view-grid--tbody--cell--PaddingLeft: calc(var(--pf-c-table__tree-view-main--nested-indent--base) * 7 + var(--pf-c-table__tree-view-main--indent--base));
  }
  .pf-m-tree-view-grid-lg.pf-c-table tr[aria-level="9"] {
    --pf-c-table--m-tree-view-grid--tbody--cell--PaddingLeft: calc(var(--pf-c-table__tree-view-main--nested-indent--base) * 8 + var(--pf-c-table__tree-view-main--indent--base));
  }
  .pf-m-tree-view-grid-lg.pf-c-table tr[aria-level="10"] {
    --pf-c-table--m-tree-view-grid--tbody--cell--PaddingLeft: calc(var(--pf-c-table__tree-view-main--nested-indent--base) * 9 + var(--pf-c-table__tree-view-main--indent--base));
  }
}

@media screen and (max-width: 1200px) {
  .pf-m-tree-view-grid-xl.pf-c-table {
    --pf-c-table--m-tree-view-grid--tr--OutlineOffset: calc(-1 * var(--pf-global--spacer--xs));
    --pf-c-table--m-tree-view-grid--tbody--cell--PaddingTop: var(--pf-global--spacer--md);
    --pf-c-table--m-tree-view-grid--tbody--cell--PaddingBottom: var(--pf-global--spacer--md);
    --pf-c-table--m-tree-view-grid--tbody--cell--PaddingLeft: var(--pf-c-table__tree-view-main--indent--base);
    --pf-c-table--m-tree-view-grid--tbody--cell--GridColumnGap: var(--pf-global--spacer--sm);
    --pf-c-table--m-tree-view-grid--c-table__action--PaddingTop: var(--pf-global--spacer--md);
    --pf-c-table--m-tree-view-grid--c-table__action--PaddingBottom: var(--pf-global--spacer--md);
    --pf-c-table--m-tree-view-grid--c-table__action--PaddingLeft: 0;
    --pf-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--action--PaddingTop: var(--pf-global--spacer--xl);
    --pf-c-table--m-tree-view-grid--m-tree-view-details-expanded--PaddingBottom: var(--pf-global--spacer--xl);
    --pf-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--PaddingTop: var(--pf-global--spacer--xl);
    --pf-c-table--m-tree-view-grid--td--data-label--GridTemplateColumns: repeat(auto-fit, minmax(150px, 1fr));
    --pf-c-table--m-tree-view-grid--td--not--c-table__tree-view-title-cell--PaddingTop: var(--pf-global--spacer--xs);
    --pf-c-table--m-tree-view-grid--td--not--c-table__tree-view-title-cell--PaddingBottom: var(--pf-global--spacer--xs);
    --pf-c-table--m-tree-view-mobile__tree-view-main--c-table__check--MarginRight: 0;
    --pf-c-table--m-tree-view-mobile__tree-view-main--c-table__check--Order: 4;
    --pf-c-table__tree-view-text--PaddingRight: var(--pf-global--spacer--sm);
    --pf-c-table--tbody--cell--PaddingTop: var(--pf-c-table--m-tree-view-grid--tbody--cell--PaddingTop);
    --pf-c-table--tbody--cell--PaddingBottom: var(--pf-c-table--m-tree-view-grid--tbody--cell--PaddingBottom);
    --pf-c-table__tree-view-details-toggle--MarginTop: calc(0.375rem * -1);
    --pf-c-table__tree-view-details-toggle--MarginBottom: calc(0.375rem * -1);
    --pf-c-table--m-tree-view-grid--c-dropdown--MarginTop: calc(0.375rem * -1);
    --pf-c-table--m-tree-view-grid--c-dropdown--MarginBottom: calc(0.375rem * -1);
  }
  .pf-m-tree-view-grid-xl.pf-c-table tbody tr {
    position: relative;
    outline-offset: var(--pf-c-table--m-tree-view-grid--tr--OutlineOffset);
    border: none;
  }
  .pf-m-tree-view-grid-xl.pf-c-table tbody tr::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: var(--pf-c-table--m-tree-view-grid--tbody--cell--PaddingLeft);
    border-bottom: var(--pf-c-table--border-width--base) solid var(--pf-c-table--BorderColor);
    content: "";
  }
  .pf-m-tree-view-grid-xl.pf-c-table tr:not([hidden]) {
    display: grid;
    grid-template-columns: 1fr max-content;
  }
  .pf-m-tree-view-grid-xl.pf-c-table tr[aria-expanded] .pf-c-table__tree-view-title-cell {
    --pf-c-table--cell--PaddingTop: var(--pf-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--PaddingTop);
  }
  .pf-m-tree-view-grid-xl.pf-c-table tr[aria-expanded] .pf-c-table__tree-view-title-cell ~ .pf-c-table__action {
    --pf-c-table--cell--PaddingTop: var(--pf-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--action--PaddingTop);
  }
  .pf-m-tree-view-grid-xl.pf-c-table td:not(.pf-c-table__tree-view-title-cell) {
    --pf-c-table--cell--PaddingTop: var(--pf-c-table--m-tree-view-grid--td--not--c-table__tree-view-title-cell--PaddingTop);
    --pf-c-table--cell--PaddingBottom: var(--pf-c-table--m-tree-view-grid--td--not--c-table__tree-view-title-cell--PaddingBottom);
    padding-left: var(--pf-c-table--m-tree-view-grid--tbody--cell--PaddingLeft);
  }
  .pf-m-tree-view-grid-xl.pf-c-table .pf-c-table__tree-view-text {
    padding-right: var(--pf-c-table__tree-view-text--PaddingRight);
  }
  .pf-m-tree-view-grid-xl.pf-c-table thead th {
    display: none;
    visibility: none;
  }
  .pf-m-tree-view-grid-xl.pf-c-table td {
    display: none;
  }
  .pf-m-tree-view-grid-xl.pf-c-table td[data-label] {
    grid-column: 1;
    grid-column-gap: var(--pf-c-table--m-tree-view-grid--tbody--cell--GridColumnGap);
    grid-template-columns: var(--pf-c-table--m-tree-view-grid--td--data-label--GridTemplateColumns);
    align-items: start;
  }
  .pf-m-tree-view-grid-xl.pf-c-table td[data-label] > * {
    grid-column: 2;
  }
  .pf-m-tree-view-grid-xl.pf-c-table td[data-label]::before {
    font-weight: bold;
    text-align: left;
    content: attr(data-label);
  }
  .pf-m-tree-view-grid-xl.pf-c-table tr.pf-m-tree-view-details-expanded {
    padding-bottom: var(--pf-c-table--m-tree-view-grid--m-tree-view-details-expanded--PaddingBottom);
  }
  .pf-m-tree-view-grid-xl.pf-c-table tr.pf-m-tree-view-details-expanded td[data-label] {
    display: grid;
  }
  .pf-m-tree-view-grid-xl.pf-c-table .pf-c-table__tree-view-title-header-cell {
    display: block;
    visibility: visible;
  }
  .pf-m-tree-view-grid-xl.pf-c-table .pf-c-table__tree-view-details-toggle,
.pf-m-tree-view-grid-xl.pf-c-table .pf-c-table__action {
    display: inline-block;
    visibility: visible;
  }
  .pf-m-tree-view-grid-xl.pf-c-table .pf-c-table__action {
    --pf-c-table--cell--Width: auto;
    --pf-c-table--m-tree-view-grid--tbody--cell--PaddingLeft: var(--pf-c-table--m-tree-view-grid--c-table__action--PaddingLeft);
    --pf-c-table--m-tree-view-grid--td--not--c-table__tree-view-title-cell--PaddingTop: var(--pf-c-table--m-tree-view-grid--c-table__action--PaddingTop);
    --pf-c-table--m-tree-view-grid--td--not--c-table__tree-view-title-cell--PaddingBottom: var(--pf-c-table--m-tree-view-grid--c-table__action--PaddingBottom);
    grid-column: 2;
    grid-row: 1;
  }
  .pf-m-tree-view-grid-xl.pf-c-table .pf-c-table__action > .pf-c-dropdown {
    margin-top: var(--pf-c-table--m-tree-view-grid--c-dropdown--MarginTop);
    margin-bottom: var(--pf-c-table--m-tree-view-grid--c-dropdown--MarginBottom);
  }
  .pf-m-tree-view-grid-xl.pf-c-table .pf-c-table__tree-view-main > .pf-c-table__check {
    margin-right: var(--pf-c-table--m-tree-view-mobile__tree-view-main--c-table__check--MarginRight);
    margin-left: auto;
    order: var(--pf-c-table--m-tree-view-mobile__tree-view-main--c-table__check--Order);
  }
  .pf-m-tree-view-grid-xl.pf-c-table .pf-c-table__tree-view-details-toggle {
    margin-top: var(--pf-c-table__tree-view-details-toggle--MarginTop);
    margin-bottom: var(--pf-c-table__tree-view-details-toggle--MarginBottom);
  }
  .pf-m-tree-view-grid-xl.pf-c-table tr[aria-level="2"] {
    --pf-c-table--m-tree-view-grid--tbody--cell--PaddingLeft: calc(var(--pf-c-table__tree-view-main--nested-indent--base) * 1 + var(--pf-c-table__tree-view-main--indent--base));
  }
  .pf-m-tree-view-grid-xl.pf-c-table tr[aria-level="3"] {
    --pf-c-table--m-tree-view-grid--tbody--cell--PaddingLeft: calc(var(--pf-c-table__tree-view-main--nested-indent--base) * 2 + var(--pf-c-table__tree-view-main--indent--base));
  }
  .pf-m-tree-view-grid-xl.pf-c-table tr[aria-level="4"] {
    --pf-c-table--m-tree-view-grid--tbody--cell--PaddingLeft: calc(var(--pf-c-table__tree-view-main--nested-indent--base) * 3 + var(--pf-c-table__tree-view-main--indent--base));
  }
  .pf-m-tree-view-grid-xl.pf-c-table tr[aria-level="5"] {
    --pf-c-table--m-tree-view-grid--tbody--cell--PaddingLeft: calc(var(--pf-c-table__tree-view-main--nested-indent--base) * 4 + var(--pf-c-table__tree-view-main--indent--base));
  }
  .pf-m-tree-view-grid-xl.pf-c-table tr[aria-level="6"] {
    --pf-c-table--m-tree-view-grid--tbody--cell--PaddingLeft: calc(var(--pf-c-table__tree-view-main--nested-indent--base) * 5 + var(--pf-c-table__tree-view-main--indent--base));
  }
  .pf-m-tree-view-grid-xl.pf-c-table tr[aria-level="7"] {
    --pf-c-table--m-tree-view-grid--tbody--cell--PaddingLeft: calc(var(--pf-c-table__tree-view-main--nested-indent--base) * 6 + var(--pf-c-table__tree-view-main--indent--base));
  }
  .pf-m-tree-view-grid-xl.pf-c-table tr[aria-level="8"] {
    --pf-c-table--m-tree-view-grid--tbody--cell--PaddingLeft: calc(var(--pf-c-table__tree-view-main--nested-indent--base) * 7 + var(--pf-c-table__tree-view-main--indent--base));
  }
  .pf-m-tree-view-grid-xl.pf-c-table tr[aria-level="9"] {
    --pf-c-table--m-tree-view-grid--tbody--cell--PaddingLeft: calc(var(--pf-c-table__tree-view-main--nested-indent--base) * 8 + var(--pf-c-table__tree-view-main--indent--base));
  }
  .pf-m-tree-view-grid-xl.pf-c-table tr[aria-level="10"] {
    --pf-c-table--m-tree-view-grid--tbody--cell--PaddingLeft: calc(var(--pf-c-table__tree-view-main--nested-indent--base) * 9 + var(--pf-c-table__tree-view-main--indent--base));
  }
}

@media screen and (max-width: 1450px) {
  .pf-m-tree-view-grid-2xl.pf-c-table {
    --pf-c-table--m-tree-view-grid--tr--OutlineOffset: calc(-1 * var(--pf-global--spacer--xs));
    --pf-c-table--m-tree-view-grid--tbody--cell--PaddingTop: var(--pf-global--spacer--md);
    --pf-c-table--m-tree-view-grid--tbody--cell--PaddingBottom: var(--pf-global--spacer--md);
    --pf-c-table--m-tree-view-grid--tbody--cell--PaddingLeft: var(--pf-c-table__tree-view-main--indent--base);
    --pf-c-table--m-tree-view-grid--tbody--cell--GridColumnGap: var(--pf-global--spacer--sm);
    --pf-c-table--m-tree-view-grid--c-table__action--PaddingTop: var(--pf-global--spacer--md);
    --pf-c-table--m-tree-view-grid--c-table__action--PaddingBottom: var(--pf-global--spacer--md);
    --pf-c-table--m-tree-view-grid--c-table__action--PaddingLeft: 0;
    --pf-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--action--PaddingTop: var(--pf-global--spacer--xl);
    --pf-c-table--m-tree-view-grid--m-tree-view-details-expanded--PaddingBottom: var(--pf-global--spacer--xl);
    --pf-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--PaddingTop: var(--pf-global--spacer--xl);
    --pf-c-table--m-tree-view-grid--td--data-label--GridTemplateColumns: repeat(auto-fit, minmax(150px, 1fr));
    --pf-c-table--m-tree-view-grid--td--not--c-table__tree-view-title-cell--PaddingTop: var(--pf-global--spacer--xs);
    --pf-c-table--m-tree-view-grid--td--not--c-table__tree-view-title-cell--PaddingBottom: var(--pf-global--spacer--xs);
    --pf-c-table--m-tree-view-mobile__tree-view-main--c-table__check--MarginRight: 0;
    --pf-c-table--m-tree-view-mobile__tree-view-main--c-table__check--Order: 4;
    --pf-c-table__tree-view-text--PaddingRight: var(--pf-global--spacer--sm);
    --pf-c-table--tbody--cell--PaddingTop: var(--pf-c-table--m-tree-view-grid--tbody--cell--PaddingTop);
    --pf-c-table--tbody--cell--PaddingBottom: var(--pf-c-table--m-tree-view-grid--tbody--cell--PaddingBottom);
    --pf-c-table__tree-view-details-toggle--MarginTop: calc(0.375rem * -1);
    --pf-c-table__tree-view-details-toggle--MarginBottom: calc(0.375rem * -1);
    --pf-c-table--m-tree-view-grid--c-dropdown--MarginTop: calc(0.375rem * -1);
    --pf-c-table--m-tree-view-grid--c-dropdown--MarginBottom: calc(0.375rem * -1);
  }
  .pf-m-tree-view-grid-2xl.pf-c-table tbody tr {
    position: relative;
    outline-offset: var(--pf-c-table--m-tree-view-grid--tr--OutlineOffset);
    border: none;
  }
  .pf-m-tree-view-grid-2xl.pf-c-table tbody tr::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: var(--pf-c-table--m-tree-view-grid--tbody--cell--PaddingLeft);
    border-bottom: var(--pf-c-table--border-width--base) solid var(--pf-c-table--BorderColor);
    content: "";
  }
  .pf-m-tree-view-grid-2xl.pf-c-table tr:not([hidden]) {
    display: grid;
    grid-template-columns: 1fr max-content;
  }
  .pf-m-tree-view-grid-2xl.pf-c-table tr[aria-expanded] .pf-c-table__tree-view-title-cell {
    --pf-c-table--cell--PaddingTop: var(--pf-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--PaddingTop);
  }
  .pf-m-tree-view-grid-2xl.pf-c-table tr[aria-expanded] .pf-c-table__tree-view-title-cell ~ .pf-c-table__action {
    --pf-c-table--cell--PaddingTop: var(--pf-c-table--m-tree-view-grid__tr--expanded__tree-view-title-cell--action--PaddingTop);
  }
  .pf-m-tree-view-grid-2xl.pf-c-table td:not(.pf-c-table__tree-view-title-cell) {
    --pf-c-table--cell--PaddingTop: var(--pf-c-table--m-tree-view-grid--td--not--c-table__tree-view-title-cell--PaddingTop);
    --pf-c-table--cell--PaddingBottom: var(--pf-c-table--m-tree-view-grid--td--not--c-table__tree-view-title-cell--PaddingBottom);
    padding-left: var(--pf-c-table--m-tree-view-grid--tbody--cell--PaddingLeft);
  }
  .pf-m-tree-view-grid-2xl.pf-c-table .pf-c-table__tree-view-text {
    padding-right: var(--pf-c-table__tree-view-text--PaddingRight);
  }
  .pf-m-tree-view-grid-2xl.pf-c-table thead th {
    display: none;
    visibility: none;
  }
  .pf-m-tree-view-grid-2xl.pf-c-table td {
    display: none;
  }
  .pf-m-tree-view-grid-2xl.pf-c-table td[data-label] {
    grid-column: 1;
    grid-column-gap: var(--pf-c-table--m-tree-view-grid--tbody--cell--GridColumnGap);
    grid-template-columns: var(--pf-c-table--m-tree-view-grid--td--data-label--GridTemplateColumns);
    align-items: start;
  }
  .pf-m-tree-view-grid-2xl.pf-c-table td[data-label] > * {
    grid-column: 2;
  }
  .pf-m-tree-view-grid-2xl.pf-c-table td[data-label]::before {
    font-weight: bold;
    text-align: left;
    content: attr(data-label);
  }
  .pf-m-tree-view-grid-2xl.pf-c-table tr.pf-m-tree-view-details-expanded {
    padding-bottom: var(--pf-c-table--m-tree-view-grid--m-tree-view-details-expanded--PaddingBottom);
  }
  .pf-m-tree-view-grid-2xl.pf-c-table tr.pf-m-tree-view-details-expanded td[data-label] {
    display: grid;
  }
  .pf-m-tree-view-grid-2xl.pf-c-table .pf-c-table__tree-view-title-header-cell {
    display: block;
    visibility: visible;
  }
  .pf-m-tree-view-grid-2xl.pf-c-table .pf-c-table__tree-view-details-toggle,
.pf-m-tree-view-grid-2xl.pf-c-table .pf-c-table__action {
    display: inline-block;
    visibility: visible;
  }
  .pf-m-tree-view-grid-2xl.pf-c-table .pf-c-table__action {
    --pf-c-table--cell--Width: auto;
    --pf-c-table--m-tree-view-grid--tbody--cell--PaddingLeft: var(--pf-c-table--m-tree-view-grid--c-table__action--PaddingLeft);
    --pf-c-table--m-tree-view-grid--td--not--c-table__tree-view-title-cell--PaddingTop: var(--pf-c-table--m-tree-view-grid--c-table__action--PaddingTop);
    --pf-c-table--m-tree-view-grid--td--not--c-table__tree-view-title-cell--PaddingBottom: var(--pf-c-table--m-tree-view-grid--c-table__action--PaddingBottom);
    grid-column: 2;
    grid-row: 1;
  }
  .pf-m-tree-view-grid-2xl.pf-c-table .pf-c-table__action > .pf-c-dropdown {
    margin-top: var(--pf-c-table--m-tree-view-grid--c-dropdown--MarginTop);
    margin-bottom: var(--pf-c-table--m-tree-view-grid--c-dropdown--MarginBottom);
  }
  .pf-m-tree-view-grid-2xl.pf-c-table .pf-c-table__tree-view-main > .pf-c-table__check {
    margin-right: var(--pf-c-table--m-tree-view-mobile__tree-view-main--c-table__check--MarginRight);
    margin-left: auto;
    order: var(--pf-c-table--m-tree-view-mobile__tree-view-main--c-table__check--Order);
  }
  .pf-m-tree-view-grid-2xl.pf-c-table .pf-c-table__tree-view-details-toggle {
    margin-top: var(--pf-c-table__tree-view-details-toggle--MarginTop);
    margin-bottom: var(--pf-c-table__tree-view-details-toggle--MarginBottom);
  }
  .pf-m-tree-view-grid-2xl.pf-c-table tr[aria-level="2"] {
    --pf-c-table--m-tree-view-grid--tbody--cell--PaddingLeft: calc(var(--pf-c-table__tree-view-main--nested-indent--base) * 1 + var(--pf-c-table__tree-view-main--indent--base));
  }
  .pf-m-tree-view-grid-2xl.pf-c-table tr[aria-level="3"] {
    --pf-c-table--m-tree-view-grid--tbody--cell--PaddingLeft: calc(var(--pf-c-table__tree-view-main--nested-indent--base) * 2 + var(--pf-c-table__tree-view-main--indent--base));
  }
  .pf-m-tree-view-grid-2xl.pf-c-table tr[aria-level="4"] {
    --pf-c-table--m-tree-view-grid--tbody--cell--PaddingLeft: calc(var(--pf-c-table__tree-view-main--nested-indent--base) * 3 + var(--pf-c-table__tree-view-main--indent--base));
  }
  .pf-m-tree-view-grid-2xl.pf-c-table tr[aria-level="5"] {
    --pf-c-table--m-tree-view-grid--tbody--cell--PaddingLeft: calc(var(--pf-c-table__tree-view-main--nested-indent--base) * 4 + var(--pf-c-table__tree-view-main--indent--base));
  }
  .pf-m-tree-view-grid-2xl.pf-c-table tr[aria-level="6"] {
    --pf-c-table--m-tree-view-grid--tbody--cell--PaddingLeft: calc(var(--pf-c-table__tree-view-main--nested-indent--base) * 5 + var(--pf-c-table__tree-view-main--indent--base));
  }
  .pf-m-tree-view-grid-2xl.pf-c-table tr[aria-level="7"] {
    --pf-c-table--m-tree-view-grid--tbody--cell--PaddingLeft: calc(var(--pf-c-table__tree-view-main--nested-indent--base) * 6 + var(--pf-c-table__tree-view-main--indent--base));
  }
  .pf-m-tree-view-grid-2xl.pf-c-table tr[aria-level="8"] {
    --pf-c-table--m-tree-view-grid--tbody--cell--PaddingLeft: calc(var(--pf-c-table__tree-view-main--nested-indent--base) * 7 + var(--pf-c-table__tree-view-main--indent--base));
  }
  .pf-m-tree-view-grid-2xl.pf-c-table tr[aria-level="9"] {
    --pf-c-table--m-tree-view-grid--tbody--cell--PaddingLeft: calc(var(--pf-c-table__tree-view-main--nested-indent--base) * 8 + var(--pf-c-table__tree-view-main--indent--base));
  }
  .pf-m-tree-view-grid-2xl.pf-c-table tr[aria-level="10"] {
    --pf-c-table--m-tree-view-grid--tbody--cell--PaddingLeft: calc(var(--pf-c-table__tree-view-main--nested-indent--base) * 9 + var(--pf-c-table__tree-view-main--indent--base));
  }
}