.pf-c-menu {
  --pf-global--Color--100: var(--pf-global--Color--dark-100);
  --pf-global--Color--200: var(--pf-global--Color--dark-200);
  --pf-global--BorderColor--100: var(--pf-global--BorderColor--dark-100);
  --pf-global--primary-color--100: var(--pf-global--primary-color--dark-100);
  --pf-global--link--Color: var(--pf-global--link--Color--dark);
  --pf-global--link--Color--hover: var(--pf-global--link--Color--dark--hover);
  --pf-global--BackgroundColor--100: var(--pf-global--BackgroundColor--light-100);
}

.pf-c-menu__group, .pf-c-menu__list-item, .pf-c-menu__list {
  --pf-hidden-visible--visible--Visibility: visible;
  --pf-hidden-visible--hidden--Display: none;
  --pf-hidden-visible--hidden--Visibility: hidden;
  --pf-hidden-visible--Display: var(--pf-hidden-visible--visible--Display);
  --pf-hidden-visible--Visibility: var(--pf-hidden-visible--visible--Visibility);
  display: var(--pf-hidden-visible--Display);
  visibility: var(--pf-hidden-visible--Visibility);
}
.pf-m-hidden.pf-c-menu__group, .pf-m-hidden.pf-c-menu__list-item, .pf-m-hidden.pf-c-menu__list {
  --pf-hidden-visible--Display: var(--pf-hidden-visible--hidden--Display);
  --pf-hidden-visible--Visibility: var(--pf-hidden-visible--hidden--Visibility);
}
@media screen and (min-width: 576px) {
  .pf-m-hidden-on-sm.pf-c-menu__group, .pf-m-hidden-on-sm.pf-c-menu__list-item, .pf-m-hidden-on-sm.pf-c-menu__list {
    --pf-hidden-visible--Display: var(--pf-hidden-visible--hidden--Display);
    --pf-hidden-visible--Visibility: var(--pf-hidden-visible--hidden--Visibility);
  }
  .pf-m-visible-on-sm.pf-c-menu__group, .pf-m-visible-on-sm.pf-c-menu__list-item, .pf-m-visible-on-sm.pf-c-menu__list {
    --pf-hidden-visible--Display: var(--pf-hidden-visible--visible--Display);
    --pf-hidden-visible--Visibility: var(--pf-hidden-visible--visible--Visibility);
  }
}
@media screen and (min-width: 768px) {
  .pf-m-hidden-on-md.pf-c-menu__group, .pf-m-hidden-on-md.pf-c-menu__list-item, .pf-m-hidden-on-md.pf-c-menu__list {
    --pf-hidden-visible--Display: var(--pf-hidden-visible--hidden--Display);
    --pf-hidden-visible--Visibility: var(--pf-hidden-visible--hidden--Visibility);
  }
  .pf-m-visible-on-md.pf-c-menu__group, .pf-m-visible-on-md.pf-c-menu__list-item, .pf-m-visible-on-md.pf-c-menu__list {
    --pf-hidden-visible--Display: var(--pf-hidden-visible--visible--Display);
    --pf-hidden-visible--Visibility: var(--pf-hidden-visible--visible--Visibility);
  }
}
@media screen and (min-width: 992px) {
  .pf-m-hidden-on-lg.pf-c-menu__group, .pf-m-hidden-on-lg.pf-c-menu__list-item, .pf-m-hidden-on-lg.pf-c-menu__list {
    --pf-hidden-visible--Display: var(--pf-hidden-visible--hidden--Display);
    --pf-hidden-visible--Visibility: var(--pf-hidden-visible--hidden--Visibility);
  }
  .pf-m-visible-on-lg.pf-c-menu__group, .pf-m-visible-on-lg.pf-c-menu__list-item, .pf-m-visible-on-lg.pf-c-menu__list {
    --pf-hidden-visible--Display: var(--pf-hidden-visible--visible--Display);
    --pf-hidden-visible--Visibility: var(--pf-hidden-visible--visible--Visibility);
  }
}
@media screen and (min-width: 1200px) {
  .pf-m-hidden-on-xl.pf-c-menu__group, .pf-m-hidden-on-xl.pf-c-menu__list-item, .pf-m-hidden-on-xl.pf-c-menu__list {
    --pf-hidden-visible--Display: var(--pf-hidden-visible--hidden--Display);
    --pf-hidden-visible--Visibility: var(--pf-hidden-visible--hidden--Visibility);
  }
  .pf-m-visible-on-xl.pf-c-menu__group, .pf-m-visible-on-xl.pf-c-menu__list-item, .pf-m-visible-on-xl.pf-c-menu__list {
    --pf-hidden-visible--Display: var(--pf-hidden-visible--visible--Display);
    --pf-hidden-visible--Visibility: var(--pf-hidden-visible--visible--Visibility);
  }
}
@media screen and (min-width: 1450px) {
  .pf-m-hidden-on-2xl.pf-c-menu__group, .pf-m-hidden-on-2xl.pf-c-menu__list-item, .pf-m-hidden-on-2xl.pf-c-menu__list {
    --pf-hidden-visible--Display: var(--pf-hidden-visible--hidden--Display);
    --pf-hidden-visible--Visibility: var(--pf-hidden-visible--hidden--Visibility);
  }
  .pf-m-visible-on-2xl.pf-c-menu__group, .pf-m-visible-on-2xl.pf-c-menu__list-item, .pf-m-visible-on-2xl.pf-c-menu__list {
    --pf-hidden-visible--Display: var(--pf-hidden-visible--visible--Display);
    --pf-hidden-visible--Visibility: var(--pf-hidden-visible--visible--Visibility);
  }
}

.pf-c-menu {
  color: var(--pf-global--Color--100);
  --pf-c-menu--BackgroundColor: var(--pf-global--BackgroundColor--100);
  --pf-c-menu--BoxShadow: var(--pf-global--BoxShadow--md);
  --pf-c-menu--MinWidth: auto;
  --pf-c-menu--Width: auto;
  --pf-c-menu--ZIndex: var(--pf-global--ZIndex--sm);
  --pf-c-menu--Top: auto;
  --pf-c-menu--m-flyout__menu--Top: calc(var(--pf-c-menu__list--PaddingTop) * -1 + var(--pf-c-menu--m-flyout__menu--top-offset));
  --pf-c-menu--m-flyout__menu--Right: auto;
  --pf-c-menu--m-flyout__menu--Bottom: auto;
  --pf-c-menu--m-flyout__menu--Left: calc(100% + var(--pf-c-menu--m-flyout__menu--left-offset));
  --pf-c-menu--m-flyout__menu--m-top--Bottom: calc(var(--pf-c-menu__list--PaddingTop) * -1);
  --pf-c-menu--m-flyout__menu--m-left--Right: calc(100% + var(--pf-c-menu--m-flyout__menu--m-left--right-offset));
  --pf-c-menu--m-plain--BoxShadow: none;
  --pf-c-menu--m-flyout__menu--top-offset: 0px;
  --pf-c-menu--m-flyout__menu--left-offset: 0px;
  --pf-c-menu--m-flyout__menu--m-left--right-offset: 0px;
  --pf-c-menu__content--Height: auto;
  --pf-c-menu__content--MaxHeight: none;
  --pf-c-menu--m-scrollable__content--MaxHeight: 18.75rem;
  --pf-c-menu--c-divider--MarginTop: 0;
  --pf-c-menu--c-divider--MarginBottom: 0;
  --pf-c-menu__list--c-divider--MarginTop: var(--pf-global--spacer--sm);
  --pf-c-menu__list--c-divider--MarginBottom: var(--pf-global--spacer--sm);
  --pf-c-menu__header--PaddingTop: var(--pf-global--spacer--md);
  --pf-c-menu__header--PaddingRight: var(--pf-global--spacer--md);
  --pf-c-menu__header--PaddingBottom: var(--pf-global--spacer--md);
  --pf-c-menu__header--PaddingLeft: var(--pf-global--spacer--md);
  --pf-c-menu__header--c-menu__item--MarginTop: calc(var(--pf-c-menu__header--PaddingTop) * -1 / 2);
  --pf-c-menu__header--c-menu__item--MarginRight: calc(var(--pf-c-menu__header--PaddingRight) * -1 / 2);
  --pf-c-menu__header--c-menu__item--MarginBottom: calc(var(--pf-c-menu__header--PaddingBottom) * -1 / 2);
  --pf-c-menu__header--c-menu__item--MarginLeft: calc(var(--pf-c-menu__header--PaddingLeft) * -1 / 2);
  --pf-c-menu__header--c-menu__item--PaddingTop: var(--pf-global--spacer--sm);
  --pf-c-menu__header--c-menu__item--PaddingRight: var(--pf-global--spacer--md);
  --pf-c-menu__header--c-menu__item--PaddingBottom: var(--pf-global--spacer--sm);
  --pf-c-menu__header--c-menu__item--PaddingLeft: var(--pf-global--spacer--md);
  --pf-c-menu__header--c-menu__item--BackgroundColor: transparent;
  --pf-c-menu__header--c-menu__item--hover--BackgroundColor: var(--pf-global--BackgroundColor--200);
  --pf-c-menu__header--c-menu__item--focus--BackgroundColor: var(--pf-global--BackgroundColor--200);
  --pf-c-menu__search--PaddingTop: var(--pf-global--spacer--md);
  --pf-c-menu__search--PaddingRight: var(--pf-global--spacer--md);
  --pf-c-menu__search--PaddingBottom: var(--pf-global--spacer--md);
  --pf-c-menu__search--PaddingLeft: var(--pf-global--spacer--md);
  --pf-c-menu__header__search--PaddingTop: 0;
  --pf-c-menu__list--Display: block;
  --pf-c-menu__list--PaddingTop: var(--pf-global--spacer--sm);
  --pf-c-menu__list--PaddingBottom: var(--pf-global--spacer--sm);
  --pf-c-menu__list-item--Display: flex;
  --pf-c-menu__list-item--Color: var(--pf-global--Color--100);
  --pf-c-menu__list-item--BackgroundColor: transparent;
  --pf-c-menu__list-item--hover--BackgroundColor: var(--pf-global--BackgroundColor--200);
  --pf-c-menu__list-item--focus-within--BackgroundColor: var(--pf-global--BackgroundColor--200);
  --pf-c-menu__list-item--m-loading--PaddingTop: var(--pf-global--spacer--sm);
  --pf-c-menu__item--PaddingTop: var(--pf-global--spacer--sm);
  --pf-c-menu__item--PaddingRight: var(--pf-global--spacer--md);
  --pf-c-menu__item--PaddingBottom: var(--pf-global--spacer--sm);
  --pf-c-menu__item--PaddingLeft: var(--pf-global--spacer--md);
  --pf-c-menu__item--OutlineOffset: calc(0.125rem * -1);
  --pf-c-menu__item--FontSize: var(--pf-global--FontSize--md);
  --pf-c-menu__item--FontWeight: var(--pf-global--FontWeight--normal);
  --pf-c-menu__item--LineHeight: var(--pf-global--LineHeight--md);
  --pf-c-menu__list-item--m-disabled__item--Color: var(--pf-global--disabled-color--100);
  --pf-c-menu__list-item--m-danger__item--Color: var(--pf-global--danger-color--100);
  --pf-c-menu__list-item--m-load__item--Color: var(--pf-global--link--Color);
  --pf-c-menu__group--Display: block;
  --pf-c-menu__group-title--PaddingTop: var(--pf-global--spacer--md);
  --pf-c-menu__group-title--PaddingRight: var(--pf-c-menu__item--PaddingRight);
  --pf-c-menu__group-title--PaddingLeft: var(--pf-c-menu__item--PaddingLeft);
  --pf-c-menu__group-title--FontSize: var(--pf-global--FontSize--xs);
  --pf-c-menu__group-title--FontWeight: var(--pf-global--FontWeight--normal);
  --pf-c-menu__group-title--Color: var(--pf-global--Color--200);
  --pf-c-menu__item-description--FontSize: var(--pf-global--FontSize--xs);
  --pf-c-menu__item-description--Color: var(--pf-global--Color--200);
  --pf-c-menu__item-icon--MarginRight: var(--pf-global--spacer--sm);
  --pf-c-menu__item-check--MarginRight: var(--pf-global--spacer--sm);
  --pf-c-menu__item-toggle-icon--PaddingRight: var(--pf-global--spacer--sm);
  --pf-c-menu__item-toggle-icon--PaddingLeft: var(--pf-global--spacer--sm);
  --pf-c-menu__list-item--m-disabled__item-toggle-icon--Color: var(--pf-global--disabled-color--200);
  --pf-c-menu__item-text--item-toggle-icon--MarginLeft: var(--pf-global--spacer--sm);
  --pf-c-menu__item-toggle-icon--item-text--MarginLeft: var(--pf-global--spacer--sm);
  --pf-c-menu__item-select-icon--MarginLeft: var(--pf-global--spacer--sm);
  --pf-c-menu__item-select-icon--Color: var(--pf-global--active-color--100);
  --pf-c-menu__item-select-icon--FontSize: var(--pf-global--icon--FontSize--sm);
  --pf-c-menu__item-external-icon--MarginLeft: var(--pf-global--spacer--sm);
  --pf-c-menu__item-external-icon--Color: var(--pf-global--link--Color);
  --pf-c-menu__item-external-icon--FontSize: var(--pf-global--icon--FontSize--sm);
  --pf-c-menu__item-external-icon--Opacity: 0;
  --pf-c-menu__item-action--PaddingTop: var(--pf-global--spacer--sm);
  --pf-c-menu__item-action--PaddingRight: var(--pf-global--spacer--md);
  --pf-c-menu__item-action--PaddingBottom: var(--pf-global--spacer--sm);
  --pf-c-menu__item-action--PaddingLeft: var(--pf-global--spacer--md);
  --pf-c-menu__item-action--Color: var(--pf-global--Color--200);
  --pf-c-menu__item-action--hover--Color: var(--pf-global--Color--100);
  --pf-c-menu__item-action--disabled--Color: var(--pf-global--disabled-color--200);
  --pf-c-menu__item-action--m-favorited--Color: var(--pf-global--palette--gold-400);
  --pf-c-menu__item-action--m-favorited--hover--Color: var(--pf-global--palette--gold-500);
  --pf-c-menu__item-action-icon--Height: calc(var(--pf-c-menu__item--FontSize) * var(--pf-c-menu__item--LineHeight));
  --pf-c-menu__item-action--m-favorite__icon--FontSize: var(--pf-global--icon--FontSize--sm);
  --pf-c-menu__breadcrumb--PaddingTop: var(--pf-global--spacer--md);
  --pf-c-menu__breadcrumb--PaddingRight: var(--pf-global--spacer--md);
  --pf-c-menu__breadcrumb--PaddingBottom: var(--pf-global--spacer--md);
  --pf-c-menu__breadcrumb--PaddingLeft: var(--pf-global--spacer--md);
  --pf-c-menu__breadcrumb--c-breadcrumb__item--FontSize: var(--pf-global--FontSize--md);
  --pf-c-menu__breadcrumb--c-breadcrumb__heading--FontSize: var(--pf-global--FontSize--md);
  --pf-c-menu--m-drilldown--c-menu--Top: 0;
  --pf-c-menu--m-drilldown--c-menu--TransitionDuration--transform: var(--pf-global--TransitionDuration);
  --pf-c-menu--m-drilldown--c-menu--Transition: transform var(--pf-c-menu--m-drilldown--c-menu--TransitionDuration--transform);
  --pf-c-menu--m-drilldown__content--TransitionDuration--height: var(--pf-global--TransitionDuration);
  --pf-c-menu--m-drilldown__content--TransitionDuration--transform: var(--pf-global--TransitionDuration);
  --pf-c-menu--m-drilldown__content--Transition: transform var(--pf-c-menu--m-drilldown__content--TransitionDuration--transform), height var(--pf-c-menu--m-drilldown__content--TransitionDuration--height);
  --pf-c-menu--m-drilldown__list--TransitionDuration--transform: var(--pf-global--TransitionDuration);
  --pf-c-menu--m-drilldown__list--Transition: transform var(--pf-c-menu--m-drilldown__list--TransitionDuration--transform);
  --pf-c-menu--m-drilled-in--c-menu__list-item--m-current-path--c-menu--ZIndex: var(--pf-global--ZIndex--xs);
  --pf-c-menu__footer--PaddingTop: var(--pf-global--spacer--md);
  --pf-c-menu__footer--PaddingRight: var(--pf-global--spacer--md);
  --pf-c-menu__footer--PaddingBottom: var(--pf-global--spacer--md);
  --pf-c-menu__footer--PaddingLeft: var(--pf-global--spacer--md);
  --pf-c-menu__footer--BoxShadow: none;
  --pf-c-menu__footer--after--BorderTopWidth: var(--pf-global--BorderWidth--sm);
  --pf-c-menu__footer--after--BorderTopColor: var(--pf-global--BorderColor--100);
  --pf-c-menu--m-scrollable__footer--BoxShadow: var(--pf-global--BoxShadow--sm-top);
  --pf-c-menu--m-scrollable__footer--after--BorderTopWidth: 0;
  --pf-c-menu--m-scrollable__footer--after--BorderBottomWidth: var(--pf-global--BorderWidth--sm);
  top: var(--pf-c-menu--Top);
  z-index: var(--pf-c-menu--ZIndex);
  width: var(--pf-c-menu--Width);
  min-width: var(--pf-c-menu--MinWidth);
  background-color: var(--pf-c-menu--BackgroundColor);
  box-shadow: var(--pf-c-menu--BoxShadow);
  --pf-c-menu--m-nav--BoxShadow: var(--pf-global--BoxShadow--lg);
  --pf-c-menu--m-nav--BackgroundColor: var(--pf-global--BackgroundColor--dark-300);
  --pf-c-menu--m-nav__list--PaddingTop: 0;
  --pf-c-menu--m-nav__list--PaddingBottom: 0;
  --pf-c-menu--m-nav__list-item--hover--BackgroundColor: var(--pf-global--BackgroundColor--dark-200);
  --pf-c-menu--m-nav__list-item--focus-within--BackgroundColor: var(--pf-global--BackgroundColor--dark-200);
  --pf-c-menu--m-nav__list-item--active--BackgroundColor: var(--pf-global--BackgroundColor--dark-200);
  --pf-c-menu--m-nav__item--PaddingRight: var(--pf-global--spacer--lg);
  --pf-c-menu--m-nav__item--PaddingLeft: var(--pf-global--spacer--lg);
  --pf-c-menu--m-nav__item--Color: var(--pf-global--Color--light-100);
  --pf-c-menu--m-nav__item--FontSize: var(--pf-global--FontSize--sm);
  --pf-c-menu--m-nav__item--OutlineOffset: calc(var(--pf-global--spacer--xs) * -1);
  --pf-c-menu--m-nav__item--before--BorderBottomColor: var(--pf-global--BackgroundColor--dark-200);
  --pf-c-menu--m-nav__item--before--BorderBottomWidth: var(--pf-global--BorderWidth--sm);
  --pf-c-menu--m-nav__item--hover--after--BorderLeftColor: var(--pf-global--BorderColor--200);
  --pf-c-menu--m-nav__item--hover--after--BorderLeftWidth: var(--pf-global--BorderWidth--sm);
  --pf-c-menu--m-nav__item--hover--after--Top: calc(var(--pf-c-menu--m-nav__item--before--BorderBottomWidth) * -1);
  --pf-c-menu--m-nav__item__list-item--first-child__item--hover--after--Top: 0;
  --pf-c-menu--m-nav__item-description--Color: var(--pf-global--Color--light-200);
  --pf-c-menu--m-nav--c-menu--left-offset: 0.25rem;
  --pf-c-menu--m-nav--c-menu--m-top--bottom-offset: 0;
  --pf-c-menu--m-nav--c-menu--m-left--right-offset: 0.25rem;
  --pf-c-menu--m-nav--c-menu--Top: calc(var(--pf-c-menu--m-nav__item--before--BorderBottomWidth) * -1);
  --pf-c-menu--m-nav--c-menu--Left: calc(100% - var(--pf-c-menu--m-nav--c-menu--left-offset));
  --pf-c-menu--m-nav--c-menu--m-left--Right: calc(100% - var(--pf-c-menu--m-nav--c-menu--m-left--right-offset));
  --pf-c-menu--m-nav--c-menu--m-top--Bottom: calc(0 + var(--pf-c-menu--m-nav--c-menu--m-top--bottom-offset));
  --pf-c-menu--m-nav__list-item--first-child--c-menu--Top: 0;
}
.pf-c-menu .pf-c-menu__content .pf-c-menu .pf-c-menu__content {
  overflow: visible;
}
.pf-c-menu .pf-c-divider {
  margin-top: var(--pf-c-menu--c-divider--MarginTop);
  margin-bottom: var(--pf-c-menu--c-divider--MarginBottom);
}
.pf-c-menu .pf-c-menu.pf-m-flyout, .pf-c-menu.pf-m-flyout .pf-c-menu {
  position: absolute;
  top: var(--pf-c-menu--m-flyout__menu--Top);
  right: var(--pf-c-menu--m-flyout__menu--Right);
  bottom: var(--pf-c-menu--m-flyout__menu--Bottom);
  left: var(--pf-c-menu--m-flyout__menu--Left);
}
.pf-c-menu .pf-c-menu.pf-m-flyout .pf-c-menu__content, .pf-c-menu.pf-m-flyout .pf-c-menu .pf-c-menu__content {
  overflow-y: visible;
}
.pf-c-menu.pf-m-top {
  --pf-c-menu--m-flyout__menu--Top: auto;
  --pf-c-menu--m-flyout__menu--Bottom: var(--pf-c-menu--m-flyout__menu--m-top--Bottom);
}
.pf-c-menu.pf-m-left {
  --pf-c-menu--m-flyout__menu--Right: var(--pf-c-menu--m-flyout__menu--m-left--Right);
  --pf-c-menu--m-flyout__menu--Left: auto;
}
.pf-c-menu.pf-m-drilldown {
  display: flex;
  flex-direction: column;
}
.pf-c-menu.pf-m-drilldown[hidden] {
  display: none;
}
.pf-c-menu.pf-m-drilldown > .pf-c-menu__content {
  flex-grow: 1;
  overflow: hidden;
  transition: var(--pf-c-menu--m-drilldown__content--Transition);
}
.pf-c-menu.pf-m-drilldown.pf-m-drilled-in > .pf-c-menu__content > .pf-c-menu__list,
.pf-c-menu.pf-m-drilldown.pf-m-drilled-in > .pf-c-menu__list {
  transform: translateX(-100%);
}
.pf-c-menu.pf-m-drilldown > .pf-c-menu__content .pf-c-menu {
  --pf-c-menu--BoxShadow: none;
  position: absolute;
  top: var(--pf-c-menu--m-drilldown--c-menu--Top);
  left: 100%;
  width: 100%;
  transition: var(--pf-c-menu--m-drilldown--c-menu--Transition);
}
.pf-c-menu.pf-m-drilldown > .pf-c-menu__content .pf-c-menu.pf-m-drilled-in {
  transform: translateX(-100%);
}
.pf-c-menu.pf-m-drilldown .pf-c-menu__list {
  position: relative;
  overflow: hidden;
  transition: var(--pf-c-menu--m-drilldown__list--Transition);
}
.pf-c-menu.pf-m-drilldown .pf-c-menu__list .pf-c-menu__list {
  --pf-c-menu__list--PaddingTop: 0;
  --pf-c-menu__list--PaddingBottom: 0;
}
.pf-c-menu.pf-m-drilldown .pf-c-menu__list-item.pf-m-current-path .pf-c-menu {
  z-index: var(--pf-c-menu--m-drilled-in--c-menu__list-item--m-current-path--c-menu--ZIndex);
}
.pf-c-menu.pf-m-drilldown .pf-c-menu__list-item.pf-m-current-path > .pf-c-menu {
  overflow: visible;
}
.pf-c-menu.pf-m-drilldown .pf-c-menu__list-item.pf-m-static > .pf-c-menu {
  position: static;
}
.pf-c-menu.pf-m-drilldown .pf-c-menu__list-item.pf-m-static:hover {
  background-color: transparent;
}
.pf-c-menu.pf-m-drilldown .pf-c-menu__list-item:not(.pf-m-current-path) .pf-c-menu {
  display: none;
  visibility: hidden;
}
.pf-c-menu.pf-m-drilldown .pf-c-menu__item {
  outline-offset: var(--pf-c-menu__item--OutlineOffset);
}
.pf-c-menu.pf-m-drilled-in > .pf-c-menu__content > .pf-c-menu__list {
  overflow: visible;
  visibility: hidden;
}
.pf-c-menu.pf-m-drilled-in > .pf-c-menu__content > .pf-c-menu__list > .pf-c-divider,
.pf-c-menu.pf-m-drilled-in > .pf-c-menu__content > .pf-c-menu__list > .pf-c-menu__list-item:not(.pf-m-current-path) {
  display: none;
}
.pf-c-menu.pf-m-drilled-in > .pf-c-menu__content > .pf-c-menu__list > .pf-c-menu__list-item.pf-m-current-path {
  visibility: hidden;
}
.pf-c-menu.pf-m-plain {
  --pf-c-menu--BoxShadow: var(--pf-c-menu--m-plain--BoxShadow);
}
.pf-c-menu.pf-m-scrollable {
  --pf-c-menu__content--MaxHeight: var(--pf-c-menu--m-scrollable__content--MaxHeight);
  --pf-c-menu__footer--BoxShadow: var(--pf-c-menu--m-scrollable__footer--BoxShadow);
  --pf-c-menu__footer--after--BorderTopWidth: var(--pf-c-menu--m-scrollable__footer--after--BorderTopWidth);
}
.pf-c-menu.pf-m-scrollable .pf-c-menu__content {
  overflow-y: auto;
}
.pf-c-menu.pf-m-nav, .pf-c-menu.pf-m-nav .pf-c-menu {
  --pf-c-menu--BackgroundColor: var(--pf-c-menu--m-nav--BackgroundColor);
  --pf-c-menu__list--PaddingTop: var(--pf-c-menu--m-nav__list--PaddingTop);
  --pf-c-menu__list--PaddingBottom: var(--pf-c-menu--m-nav__list--PaddingBottom);
  --pf-c-menu__list-item--hover--BackgroundColor: var(--pf-c-menu--m-nav__list-item--hover--BackgroundColor);
  --pf-c-menu__list-item--focus-within--BackgroundColor: var(--pf-c-menu--m-nav__list-item--focus-within--BackgroundColor);
  --pf-c-menu__list-item--active--BackgroundColor: var(--pf-c-menu--m-nav__list-item--active--BackgroundColor);
  --pf-c-menu__item--Color: var(--pf-c-menu--m-nav__item--Color);
  --pf-c-menu__item--FontSize: var(--pf-c-menu--m-nav__item--FontSize);
  --pf-c-menu__item--OutlineOffset: var(--pf-c-menu--m-nav__item--OutlineOffset);
  --pf-c-menu__item--PaddingRight: var(--pf-c-menu--m-nav__item--PaddingRight);
  --pf-c-menu__item--PaddingLeft: var(--pf-c-menu--m-nav__item--PaddingLeft);
  --pf-c-menu__item-description--Color: var(--pf-c-menu--m-nav__item-description--Color);
  box-shadow: var(--pf-c-menu--m-nav--BoxShadow);
}
.pf-c-menu.pf-m-nav .pf-c-menu__item, .pf-c-menu.pf-m-nav .pf-c-menu .pf-c-menu__item {
  position: relative;
  outline-offset: var(--pf-c-nav__item--m-flyout--c-menu__item--OutlineOffset);
}
.pf-c-menu.pf-m-nav .pf-c-menu__item::before, .pf-c-menu.pf-m-nav .pf-c-menu .pf-c-menu__item::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  border-bottom: var(--pf-c-menu--m-nav__item--before--BorderBottomWidth) solid var(--pf-c-menu--m-nav__item--before--BorderBottomColor);
}
.pf-c-menu.pf-m-nav .pf-c-menu__item:hover::after, .pf-c-menu.pf-m-nav .pf-c-menu .pf-c-menu__item:hover::after {
  position: absolute;
  top: var(--pf-c-menu--m-nav__item--hover--after--Top);
  bottom: 0;
  left: 0;
  content: "";
  border-left: var(--pf-c-menu--m-nav__item--hover--after--BorderLeftWidth) solid var(--pf-c-menu--m-nav__item--hover--after--BorderLeftColor);
}
.pf-c-menu.pf-m-nav .pf-c-menu {
  width: 100%;
}
.pf-c-menu.pf-m-flyout.pf-m-nav, .pf-c-menu.pf-m-flyout.pf-m-nav .pf-c-menu {
  top: var(--pf-c-menu--m-nav--c-menu--Top);
  left: var(--pf-c-menu--m-nav--c-menu--Left);
}
.pf-c-menu.pf-m-flyout.pf-m-nav.pf-m-top, .pf-c-menu.pf-m-flyout.pf-m-nav .pf-c-menu.pf-m-top {
  --pf-c-menu--m-nav--c-menu--Top: auto;
  bottom: var(--pf-c-menu--m-nav--c-menu--m-top--Bottom);
}
.pf-c-menu.pf-m-flyout.pf-m-nav.pf-m-left, .pf-c-menu.pf-m-flyout.pf-m-nav .pf-c-menu.pf-m-left {
  --pf-c-menu--m-nav--c-menu--Left: auto;
  right: var(--pf-c-menu--m-nav--c-menu--m-left--Right);
}
.pf-c-menu.pf-m-flyout.pf-m-nav .pf-c-menu__list-item:first-child, .pf-c-menu.pf-m-flyout.pf-m-nav .pf-c-menu .pf-c-menu__list-item:first-child {
  --pf-c-menu--m-nav__item--hover--after--Top: var(--pf-c-menu--m-nav__item__list-item--first-child__item--hover--after--Top);
}
.pf-c-menu.pf-m-flyout.pf-m-nav .pf-c-menu__list-item:first-child .pf-c-menu, .pf-c-menu.pf-m-flyout.pf-m-nav .pf-c-menu .pf-c-menu__list-item:first-child .pf-c-menu {
  --pf-c-menu--m-nav--c-menu--Top: var(--pf-c-menu--m-nav__list-item--first-child--c-menu--Top);
}

.pf-c-menu__breadcrumb {
  display: flex;
  align-items: center;
  min-height: var(--pf-c-menu__breadcrumb--MinHeight);
  padding: var(--pf-c-menu__breadcrumb--PaddingTop) var(--pf-c-menu__breadcrumb--PaddingRight) var(--pf-c-menu__breadcrumb--PaddingBottom) var(--pf-c-menu__breadcrumb--PaddingLeft);
}
.pf-c-menu__breadcrumb .pf-c-breadcrumb {
  --pf-c-breadcrumb__item--FontSize: var(--pf-c-menu__breadcrumb--c-breadcrumb__item--FontSize);
  --pf-c-breadcrumb__heading--FontSize: var(--pf-c-menu__breadcrumb--c-breadcrumb__heading--FontSize);
}

.pf-c-menu__content {
  height: var(--pf-c-menu__content--Height);
  max-height: var(--pf-c-menu__content--MaxHeight);
}
.pf-c-menu__content .pf-c-menu__content {
  --pf-c-menu__content--Height: auto;
}

.pf-c-menu__header {
  --pf-c-menu__item--PaddingTop: var(--pf-c-menu__header--c-menu__item--PaddingTop);
  --pf-c-menu__item--PaddingRight: var(--pf-c-menu__header--c-menu__item--PaddingRight);
  --pf-c-menu__item--PaddingBottom: var(--pf-c-menu__header--c-menu__item--PaddingBottom);
  --pf-c-menu__item--PaddingLeft: var(--pf-c-menu__header--c-menu__item--PaddingLeft);
  padding-top: var(--pf-c-menu__header--PaddingTop);
  padding-right: var(--pf-c-menu__header--PaddingRight);
  padding-bottom: var(--pf-c-menu__header--PaddingBottom);
  padding-left: var(--pf-c-menu__header--PaddingLeft);
}
.pf-c-menu__header > .pf-c-menu__item {
  --pf-c-menu__item--BackgroundColor: var(--pf-c-menu__header--c-menu__item--BackgroundColor);
  margin-top: var(--pf-c-menu__header--c-menu__item--MarginTop);
  margin-right: var(--pf-c-menu__header--c-menu__item--MarginRight);
  margin-bottom: var(--pf-c-menu__header--c-menu__item--MarginBottom);
  margin-left: var(--pf-c-menu__header--c-menu__item--MarginLeft);
}
.pf-c-menu__header > .pf-c-menu__item:hover {
  --pf-c-menu__item--BackgroundColor: var(--pf-c-menu__header--c-menu__item--hover--BackgroundColor);
}
.pf-c-menu__header > .pf-c-menu__item:focus {
  --pf-c-menu__item--BackgroundColor: var(--pf-c-menu__header--c-menu__item--focus--BackgroundColor);
}
.pf-c-menu__header + .pf-c-menu__search {
  --pf-c-menu__search--PaddingTop: var(--pf-c-menu__header__search--PaddingTop);
}

.pf-c-menu__search {
  padding-top: var(--pf-c-menu__search--PaddingTop);
  padding-right: var(--pf-c-menu__search--PaddingRight);
  padding-bottom: var(--pf-c-menu__search--PaddingBottom);
  padding-left: var(--pf-c-menu__search--PaddingLeft);
}

.pf-c-menu__list {
  --pf-hidden-visible--visible--Display: var(--pf-c-menu__list--Display);
  padding-top: var(--pf-c-menu__list--PaddingTop);
  padding-bottom: var(--pf-c-menu__list--PaddingBottom);
}
.pf-c-menu__list > .pf-c-divider {
  margin-top: var(--pf-c-menu__list--c-divider--MarginTop);
  margin-bottom: var(--pf-c-menu__list--c-divider--MarginBottom);
}

.pf-c-menu__list-item {
  --pf-hidden-visible--visible--Display: var(--pf-c-menu__list-item--Display);
  position: relative;
  color: var(--pf-c-menu__list-item--Color);
  background-color: var(--pf-c-menu__list-item--BackgroundColor);
}
.pf-c-menu__list-item:hover {
  --pf-c-menu__list-item--BackgroundColor: var(--pf-c-menu__list-item--hover--BackgroundColor);
  --pf-c-menu__list-item--Color: var(--pf-c-menu__list-item--hover--Color, inherit);
}
.pf-c-menu__list-item:focus-within, .pf-c-menu__list-item.pf-m-focus {
  --pf-c-menu__list-item--BackgroundColor: var(--pf-c-menu__list-item--focus-within--BackgroundColor);
  --pf-c-menu__list-item--Color: var(--pf-c-menu__list-item--focus-within--Color, inherit);
}
.pf-c-menu__list-item.pf-m-disabled {
  --pf-c-menu__list-item--hover--BackgroundColor: transparent;
  --pf-c-menu__list-item--focus-within--BackgroundColor: transparent;
  --pf-c-menu__item--Color: var(--pf-c-menu__list-item--m-disabled__item--Color);
  --pf-c-menu__item-toggle-icon: var(--pf-c-menu__list-item--m-disabled__item-toggle-icon--Color);
  pointer-events: none;
}
.pf-c-menu__list-item.pf-m-load {
  --pf-c-menu__list-item--hover--BackgroundColor: transparent;
  --pf-c-menu__list-item--focus-within--BackgroundColor: transparent;
  --pf-c-menu__item--Color: var(--pf-c-menu__list-item--m-load__item--Color);
}
.pf-c-menu__list-item.pf-m-loading {
  --pf-c-menu__list-item--hover--BackgroundColor: transparent;
  --pf-c-menu__list-item--focus-within--BackgroundColor: transparent;
  justify-content: center;
  padding-top: var(--pf-c-menu__list-item--m-loading--PaddingTop);
  overflow: hidden;
}
.pf-c-menu__list-item.pf-m-danger {
  --pf-c-menu__item--Color: var(--pf-c-menu__list-item--m-danger__item--Color, inherit);
}

.pf-c-menu__item {
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  min-width: 0;
  padding-top: var(--pf-c-menu__item--PaddingTop);
  padding-right: var(--pf-c-menu__item--PaddingRight);
  padding-bottom: var(--pf-c-menu__item--PaddingBottom);
  padding-left: var(--pf-c-menu__item--PaddingLeft);
  font-size: var(--pf-c-menu__item--FontSize);
  font-weight: var(--pf-c-menu__item--FontWeight);
  line-height: var(--pf-c-menu__item--LineHeight);
  color: var(--pf-c-menu__item--Color);
  text-align: left;
  background-color: var(--pf-c-menu__item--BackgroundColor);
  border: none;
}
.pf-c-menu__item:hover {
  text-decoration: none;
}
.pf-c-menu__item:hover, .pf-c-menu__item:focus {
  --pf-c-menu__item-external-icon--Opacity: 1;
}
.pf-c-menu__item.pf-m-selected .pf-c-menu__item-select-icon {
  opacity: 1;
}
label.pf-c-menu__item:where(:not([disabled], .pf-m-disabled)) {
  cursor: pointer;
}

.pf-c-menu__item-main {
  display: flex;
  align-items: center;
  width: 100%;
}

.pf-c-menu__item-external-icon {
  margin-left: var(--pf-c-menu__item-external-icon--MarginLeft);
  font-size: var(--pf-c-menu__item-external-icon--FontSize);
  color: var(--pf-c-menu__item-external-icon--Color);
  opacity: var(--pf-c-menu__item-external-icon--Opacity);
}

.pf-c-menu__item-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-grow: 1;
}

.pf-c-menu__group {
  --pf-hidden-visible--visible--Display: var(--pf-c-menu__group--Display);
}

.pf-c-menu__group-title {
  padding-top: var(--pf-c-menu__group-title--PaddingTop);
  padding-right: var(--pf-c-menu__group-title--PaddingRight);
  padding-left: var(--pf-c-menu__group-title--PaddingLeft);
  font-size: var(--pf-c-menu__group-title--FontSize);
  font-weight: var(--pf-c-menu__group-title--FontWeight);
  color: var(--pf-c-menu__group-title--Color);
}

.pf-c-menu__item-description {
  font-size: var(--pf-c-menu__item-description--FontSize);
  color: var(--pf-c-menu__item-description--Color);
  word-break: break-all;
}

.pf-c-menu__item-icon {
  margin-right: var(--pf-c-menu__item-icon--MarginRight);
}

.pf-c-menu__item-check {
  display: flex;
  align-items: center;
  margin-right: var(--pf-c-menu__item-check--MarginRight);
}

.pf-c-menu__item-toggle-icon {
  padding-right: var(--pf-c-menu__item-toggle-icon--PaddingRight);
  padding-left: var(--pf-c-menu__item-toggle-icon--PaddingLeft);
  color: var(--pf-c-menu__item-toggle-icon, inherit);
}

.pf-c-menu__item-text + .pf-c-menu__item-toggle-icon {
  margin-left: var(--pf-c-menu__item-text--item-toggle-icon--MarginLeft);
}

.pf-c-menu__item-toggle-icon + .pf-c-menu__item-text {
  margin-left: var(--pf-c-menu__item-toggle-icon--item-text--MarginLeft);
}

.pf-c-menu__item-select-icon {
  margin-left: var(--pf-c-menu__item-select-icon--MarginLeft);
  font-size: var(--pf-c-menu__item-select-icon--FontSize);
  color: var(--pf-c-menu__item-select-icon--Color);
  opacity: 0;
}

.pf-c-menu__item-action {
  display: flex;
  padding-top: var(--pf-c-menu__item-action--PaddingTop);
  padding-right: var(--pf-c-menu__item-action--PaddingRight);
  padding-bottom: var(--pf-c-menu__item-action--PaddingBottom);
  padding-left: var(--pf-c-menu__item-action--PaddingLeft);
  color: var(--pf-c-menu__item-action--Color);
  border: none;
}
.pf-c-menu__item-action.pf-m-favorite .pf-c-menu__item-action-icon {
  font-size: var(--pf-c-menu__item-action--m-favorite__icon--FontSize);
}
.pf-c-menu__item-action.pf-m-favorited {
  --pf-c-menu__item-action--Color: var(--pf-c-menu__item-action--m-favorited--Color);
  --pf-c-menu__item-action--hover--Color: var(--pf-c-menu__item-action--m-favorited--hover--Color);
}
.pf-c-menu__item-action:hover, .pf-c-menu__item-action:focus {
  --pf-c-menu__item-action--Color: var(--pf-c-menu__item-action--hover--Color);
}
.pf-c-menu__item-action:disabled {
  --pf-c-menu__item-action--Color: var(--pf-c-menu__item-action--disabled--Color);
}

.pf-c-menu__item-action-icon {
  display: flex;
  align-items: center;
  height: var(--pf-c-menu__item-action-icon--Height);
}

.pf-c-menu__footer {
  position: relative;
  padding: var(--pf-c-menu__footer--PaddingTop) var(--pf-c-menu__footer--PaddingRight) var(--pf-c-menu__footer--PaddingBottom) var(--pf-c-menu__footer--PaddingLeft);
  box-shadow: var(--pf-c-menu__footer--BoxShadow);
}
.pf-c-menu__footer::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  content: "";
  border-top: var(--pf-c-menu__footer--after--BorderTopWidth) solid var(--pf-c-menu__footer--after--BorderTopColor);
}

:where(.pf-theme-dark) .pf-c-menu {
  --pf-c-menu--BackgroundColor: var(--pf-global--BackgroundColor--300);
  --pf-c-menu__list-item--hover--BackgroundColor: var(--pf-global--BackgroundColor--400);
  --pf-c-menu__list-item--focus-within--BackgroundColor: var(--pf-global--BackgroundColor--400);
  --pf-c-menu__list-item--m-disabled__item--Color: var(--pf-global--Color--200);
}